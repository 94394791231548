/**
 * @name pagination
 * @ngdoc module
 * @description
 * Pagination service being used in Messaging Module
 */


angular.module('paginate', [])

    .service('Pagination', function (Messages, $rootScope, PatientAlerts, Utils) {

        this.details = {
            advanceFetch: 2,
            pageSize: 10,
            currentPage: 0,
            fetchedPages: -1,
            maxPage: 0,
            currentView: 'all'
        };

        this.notes = [];
        this.currentNotes = [];

        this.reset = function () {
            angular.copy([], this.notes);

            this.details.currentPage = 0;
            this.details.fetchedPages = -1;
            this.details.maxPage = 0;
            this.details.currentView = 'all';

            angular.copy([], this.currentNotes);

        };

        this.fetchSinglePage = function (channel, view, page) {

            var self = this;
            var params = {
                count: self.details.pageSize,
                offset: page * self.details.pageSize
            };

            if (view !== 'all') {
                params.type = Messages.filters[view];
            }

            Messages.load(channel).then(Messages => {
                Messages.query(params, function (messages) {
                    messages.forEach(message => {
                        message.date = Utils.noteDate(message.time);
                    })
                    Object.assign(self.currentNotes, messages, {length: messages.length});
                }, err => {
                });
            });

        };

        this.fetchNotes = function (channel, view, clearUnreadStatus) {

            var self = this;

            var params = {
                count: self.details.advanceFetch * self.details.pageSize,
                offset: (self.details.fetchedPages + 1) * self.details.pageSize
            };

            if (clearUnreadStatus) {
                params.read = true;
            }

            if (view !== 'all') {
                params.type = Messages.filters[view];
            }

            Messages.load(channel).then(Messages => {
                Messages.query(params, function (messages) {
                    messages.forEach(message => {
                        message.date = Utils.noteDate(message.time);
                    })
                    self.notes = self.notes.concat(messages);
                    self.details.fetchedPages = Math.ceil(self.notes.length /
                        self.details.pageSize) - 1;
                    if (clearUnreadStatus) {
                        $rootScope.unreadStatus[channel] = false;
                    }

                    var filteredNotes = self.notes.slice((self.details.currentPage * self.details.pageSize),
                        ((self.details.currentPage + 1) * self.details.pageSize));

                    Object.assign(self.currentNotes, filteredNotes, {length: filteredNotes.length});

                }, err => {
                });
            });
        };

        this.addLatestNote = function (latestNoteChannel) {
            var self = this;
            var params = {
                count: 1,
                offset: 0
            };
            Messages.load(latestNoteChannel).then(MessageResource => {
                MessageResource.query(params, function (messageList) {
                    let latestNote = messageList[0];
                    latestNote.channel = latestNoteChannel;
                    if ($rootScope.username != latestNote.username) {
                        $rootScope.unreadStatus[latestNote.channel] = true;
                    }
                    var toBeShown = true;
                    latestNote.date = Utils.noteDate(latestNote.time);

                    if (latestNote.channel !== self.details.currentChannel) {
                        return;
                    }

                    if (latestNote.message.countersign) {
                        if (['ACCEPTED', 'REJECTED'].includes(latestNote.message.countersign.state)) {
                            toBeShown = false;
                        }
                    }


                    if (self.details.currentView &&
                        Messages.filters[self.details.currentView].includes(latestNote.message.type)) {

                        if (!toBeShown) {
                            self.notes.some(message => {
                                if (latestNote.id == message.id) {
                                    message.message.countersign.state = latestNote.message.countersign.state;
                                    if (message.message.countersign.state == 'REJECTED') {
                                        message.message.countersign._user_ = latestNote.message.countersign._user_;
                                        message.message.countersign.reason = latestNote.message.countersign.reason;
                                    }
                                    return true;
                                }
                            });
                            return;
                        }

                        self.notes.unshift(latestNote);
                        if (latestNote.message.type == 'task_completed') {
                            self.notes.some(message => {
                                if (latestNote.message.task.id == message.id) {
                                    if (latestNote.message.countersign && latestNote.message.countersign.state == 'REJECTED_COPY') {
                                        message.message.complete = false;
                                    } else {
                                        message.message.complete = true;
                                    }
                                    return true;
                                }
                            });
                        }

                        if (latestNote.message.type === 'task' ||
                            latestNote.message.type === 'task_completed') {

                            if (self.details.currentChannel && self.details.currentChannel !== 'ward') {
                                PatientAlerts.refreshFromSummary(self.details.currentChannel);
                            }
                        }

                        if (self.details.currentPage === 0) {
                            self.currentNotes.unshift(latestNote);
                        }
                    }
                }, err => {
                });
            });


        };

        this.updateMaxPages = function (channel, view) {

            var self = this;
            var params = {};

            if (view !== 'all') {
                params.type = Messages.filters[view];
            }

            Messages.load(channel).then(Messages => {
                Messages.count(params, function (result) {
                    self.details.maxPage = Math.max(Math.floor((result.count - 1) / self.details.pageSize), 0);
                }, err => {
                });
            });

        };

        this.forcedChannelUpdate = function (channel) {
            var self = this;
            self.reset();
            self.updateMaxPages(channel, 'all');
            self.fetchNotes(channel, 'all', false);

        }

        this.updateNotes = function (channel, view, clearUnreadStatus) {

            var self = this;

            if (self.details.currentView !== view) {
                self.reset();
                self.details.currentView = view;
            }

            self.details.currentChannel = channel;

            if (self.details.fetchedPages == -1) {
                self.updateMaxPages(channel, view);
                self.fetchNotes(channel, view, clearUnreadStatus);
                return;
            }

            if (self.details.currentPage === self.details.fetchedPages) {
                self.fetchNotes(channel, view, clearUnreadStatus);
                return;
            }

            if (self.details.currentPage > self.details.fetchedPages) {
                self.fetchSinglePage(channel, view, self.details.currentPage);

            } else {
                var filteredNotes = self.notes.slice((self.details.currentPage * self.details.pageSize),
                    ((self.details.currentPage + 1) * self.details.pageSize));

                Object.assign(self.currentNotes, filteredNotes, {length: filteredNotes.length});
            }

        };

    });
