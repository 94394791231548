/**
 * @name reject
 * @ngdoc module
 * @description
 * Collection of reject fields
 */

angular.module('reject', [])

    .service('Reject', function () {

        this.collection = {};

        this.add = function (form_name, card_name, field_name) {
            let key = form_name + '_' + card_name
            if (!this.collection[key]) {
                this.collection[key] = new Set();
            }
            this.collection[key].add(field_name);
        };

        this.remove = function (form_name, card_name, field_name) {
            let key = form_name + '_' + card_name
            if (!this.collection[key]) {
                return;
            }
            this.collection[key].delete(field_name);
        };

        this.isEmpty = function (form_name, card_name) {
            let key = form_name + '_' + card_name
            if (!this.collection[key]) {
                return true;
            } else {
                return this.collection[key].size == 0;
            }
        };

        this.isRepeatingEmpty = function (form) {
            let formKeys = Object.keys(this.collection).filter(key => {
                return key.startsWith(form.name)
            }).map(key => {
                return this.collection[key].size;
            })
            if (formKeys.length == 0) {
                return true;
            } else {
                let formValues = formKeys.reduce((a, b) => a + b, 0)
                return formValues == 0 ? true : false;
            }
        };

    });
