/**
 * @name accessCode
 * @ngdoc module
 * @description
 * Access Code Services and Directives
 */
angular.module('accessCode', [])

    /**
     * @name accessCode
     * @ngdoc service
     * @description
     * A service for handling and managing access codes
     */

    .service('AccessCodeStore', function (Server) {
        this.load = function () {
            return Server.createResource('/patient/:id/share/create', {
                post: {
                    method: 'POST',
                    url: '/patient/:id/share/create'
                },
                update: {
                    method: 'POST',
                    url: '/patient/:id/share/:shareId/update'
                },
                get: {
                    method: 'GET',
                    url: '/patient/:id/shares'
                },
                newauth: {
                    method: 'POST',
                    url: '/patient/:id/share/:shareId/newauth'
                },
                cancelshare: {
                    method: 'POST',
                    url: '/patient/:id/share/:shareId/expire'
                }
            });
        };
    })

    /**
     * @name generateAccessCode
     * @ngdoc directive
     * @restrict E
     * @description
     *
     */
    .directive('generateAccessCode', function () {

        return {
            restrict: 'E',
            scope: {
                patient: '='
            },
            controller: function ($scope, $rootScope, $state, Cards, AccessCodeStore) {

                $scope.accessCodes = [[''], [''], ['']];
                $scope.limit = '';
                $scope.options = {};
                $scope.systemID = $rootScope.systemID;

                Cards.get('access_code').then(form => {
                    $scope.accessCodeForm = form;
                    $scope.options = form.cards[form.cards.length - 1].fields[0].options;

                    $scope.formModel = {};
                    $scope.metaFormModel = {};
                    $scope.completed = [false, false, false, false, false];
                    $scope.selected = 0;

                    $scope.accessCodeForm.cards.forEach(card => {
                        $scope.formModel[card.name] = {};
                        $scope.metaFormModel[card.name] = {};
                    });
                    $scope.formModel.limits.downloads = 100;
                    $scope.formModel.limits.expiry_date = moment().add(1,
                                                                    'years').format('DD/MM/YYYY');
                });

                $scope.accessCodeForm = {cards: []};

                $scope.cancel = function () {
                    $state.go('patient.assessment', {'card': ''});
                };

                $scope.next = function (index) {
                    if (index === 3) {
                        $scope.getAccessCodes();
                    }
                    $scope.completed[index] = true;
                    $scope.selected = index + 1;
                };

                $scope.back = function (index) {
                    $scope.selected = index - 1;
                };

                $scope.submit = function() {

                    AccessCodeStore.load().then(AccessCode => {
                        let data = {
                            shareCount: $scope.downloads,
                            sharePurpose: $scope.formModel,
                            challenge: $scope.options[$scope.formModel.security_code.verification_question.value],
                            response: $scope.formModel.security_code.verification_answer,
                            shareExpire: $scope.date,
                            formNames: $scope.formNames,
                            filters: $scope.filters
                        };
                        let params = {
                            id: $scope.patient.id,
                            shareId: $scope.dataShareID
                        };
                        AccessCode.update(params, data,  function() {
                            $state.go('patient.managecodes', {});
                        });
                    }, err => {
                        console.log(err);
                    });
                };

                $scope.getAccessCodes = function() {
                    AccessCodeStore.load().then(AccessCode => {
                        let data = {
                            shareCount: $scope.downloads,
                            sharePurpose: $scope.formModel,
                            challenge: '',
                            response: '',
                            shareExpire: $scope.date,
                            formNames: $scope.formNames,
                            filters: $scope.filters
                        };
                        let params = {
                            id: $scope.patient.id
                        };
                        AccessCode.post(params, data,  function(resp) {
                            $scope.accessCodes[0] = [resp.auth.word_one];
                            $scope.accessCodes[1] = [resp.auth.word_two];
                            $scope.accessCodes[2] = [resp.auth.word_three];
                            $scope.dataShareID = resp.dataShareID;
                        });
                    }, err => {
                        console.log(err);
                    });
                };

                $scope.refreshAccessCodes = function() {
                    AccessCodeStore.load().then(AccessCode => {
                        let params = {
                            id: $scope.patient.id,
                            shareId: $scope.dataShareID
                        };
                        AccessCode.newauth(params, {},  function(resp) {
                            $scope.accessCodes[0] = [resp.word_one];
                            $scope.accessCodes[1] = [resp.word_two];
                            $scope.accessCodes[2] = [resp.word_three];
                        });
                    }, err => {
                        console.log(err);
                    });
                };

                $scope.cardFilled = function (index) {
                    let cardName = $scope.accessCodeForm.cards[index].name;
                    if (cardName === 'get_started') {
                        let value1 = $scope.formModel[cardName].confirm_information_sharing;
                        let value2 = $scope.formModel[cardName].explanation;
                        return value1 && Boolean(value2);
                    }

                    if (cardName === 'recipient') {
                        let value = $scope.formModel[cardName].third_party_recipient;
                        return Boolean(value);
                    }

                    if (cardName === 'select') {
                        let filteredData = $scope.formModel[cardName].disease;
                        if (filteredData) {
                            $scope.formNames = Object.keys(filteredData).
                                filter(key => filteredData[key]).
                                map(key => {return {name: key};});
                            $scope.filters = Object.fromEntries($scope.formNames.
                                map(form => [Object.values(form)[0], '']));
                            return Object.values(filteredData).some(value => value);
                        }
                    }

                    if (cardName === 'limits') {
                        let date = $scope.formModel[cardName].expiry_date;
                        let downloads = $scope.formModel[cardName].downloads;

                        if (Boolean(date) && Boolean(downloads)) {
                            $scope.downloads = downloads;
                            const interim_datetime = moment(date, 'DD/MM/YYYY');
                            interim_datetime.hours(23);
                            interim_datetime.minutes(59);
                            interim_datetime.seconds(59);
                            $scope.date = interim_datetime.unix() * 1000;
                            return true;
                        }
                    }

                    if (cardName === 'security_code') {
                        if ($scope.formModel[cardName]) {
                            let question = $scope.formModel[cardName].verification_question || {};
                            let answer = Boolean($scope.formModel[cardName].verification_answer);
                            return question.value && answer;
                        }
                    }
                };

            },
            template: `
<div>

<md-steppers md-stretch-steppers="always" md-selected="selected">
    <md-step label="{{card.label}}" md-complete="completed[$index]" 
             ng-repeat="card in accessCodeForm.cards">
        <md-content style="padding: 1em">
            <div ng-if="$first">
                <h3>Sharing Arrangements</h3>
            </div>
            <h3>{{card.label}}</h3>
            <div ng-if="$last">
                <p>
                <h4>System ID</h4>
                <div>
    To access the data, the recipient needs to visit our website
    <a target="_blank" href="https://www.downloadmydata.co.uk">www.DownloadMyData.co.uk</a>,
    the first thing they will need to do is select the correct System ID (SID) and click continue.
                </div>
               <div layout="row" layout-margin="2em">
                   <div style="line-height: 3em">System ID (SID)</div>
                   <md-chips md-removable="false" readonly="true"><md-chip>{{systemID}}</md-chip></md-chips>
               </div>
                </p>
                <p class="top-padding"> <h4>Three Word Passphrase</h4> <div>
    You must provide this 3 word Passphrase to the intended data recipient.
    Change it first if you would prefer different words by pressing 
    <i ng-click="refreshAccessCodes()" style="margin: 0 .25em; cursor: pointer"
         class='fas fa-sync theme-color'></i>.
     Write it down (in order) and keep it safe because it will not be displayed again. 
     If you lose it before giving it to the intended recipient then you will need to
     create a new Passphrase via the Manage Information Sharing page
                </div> </p>
               <div layout="column">
                   <div layout="row" layout-margin="2em">
                       <div style="line-height: 3em">Word 1</div>
                       <md-chips ng-model="accessCodes[0]" md-removable="false" readonly="true"></md-chips>
                   </div>
                   <div layout="row" layout-margin="2em">
                       <div style="line-height: 3em">Word 2</div>
                       <md-chips ng-model="accessCodes[1]" md-removable="false" readonly="true"></md-chips>
                   </div>
                   <div layout="row" layout-margin="2em">
                       <div style="line-height: 3em">Word 3</div>
                       <md-chips ng-model="accessCodes[2]" md-removable="false" readonly="true"></md-chips>
                   </div>
               </div>
               <div class="top-padding" style="margin-bottom: 1em">
                   <span> Change Words </span>
                   <i ng-click="refreshAccessCodes()" style="margin: 0 .25em; cursor: pointer"
                                class="fas fa-sync theme-color"></i>
               </div>
           </div>
          <card-field
             ng-model='formModel[card.name]'
             meta-model='metaFormModel[card.name]'
             field='field'
             patient='{}'             
             form-name='accessCodeForm.name'
             card-name='card.name'
             ng-repeat='field in card.fields'>
          </card-field>
          <div layout="row" layout-align="space-between">
            <md-button ng-if="!$first" ng-click="back($index)" class="md-raised">Back</md-button>
            <md-button ng-click="cancel()" ng-if="$first" class="md-raised">Cancel</md-button>
            <md-button ng-if="!$last" ng-disabled="!cardFilled($index)" ng-click="next($index)"
                       class="md-primary md-raised">
                Next
            </md-button>
            <md-button ng-if="$last" ng-disabled="!cardFilled($index)"
                       ng-click="submit()" class="md-primary md-raised">
            Submit
            </md-button>
          </div>
        </md-content>
    </md-step>
</md-steppers> 
</div>
      
                    `
        };
    })


    .directive('manageAccessCodes', function () {

        return {
            restrict: 'E',
            scope: {
                patient: '='
            },
            controller: function ($scope, $rootScope, $state, Cards, AccessCodeStore, $mdDialog) {

                $scope.switches = {};

                AccessCodeStore.load().then(AccessCode => {
                    let params = {
                        id: $scope.patient.id
                    };
                    AccessCode.get(params, function(resp) {
                        $scope.shares = resp;
                        Object.keys($scope.shares).map(key => {
                            $scope.switches[key] = true;
                            let share = $scope.shares[key];
                            if (share.info) {
                                share.info.shareExpire = moment(share.info.shareExpire).format('DD/MM/YYYY');
                                share.info.lastAccessed = '-';
                                if (share.access.length > 0) {
                                    share.info.lastAccessed = moment(share.access[share.access.length - 1].dateAccessed).format('DD/MM/YYYY');
                                    share.access.forEach(history => {
                                        history.dateAccessed = moment(history.dateAccessed).format('HH:mm DD/MM/YYYY');
                                    });
                                }
                            }
                        });
                    });
                }, err => {
                    console.log(err);
                });

                $scope.systemID = $rootScope.systemID;

                $scope.goToCodeGeneration = function () {
                    $state.go('patient.dmd', {});
                };

                $scope.goToHome = function () {
                    $state.go('patient.dash', {'id': $scope.patient.id});
                };

                $scope.showEditShareDialog = function (shareId, share) {
                    $mdDialog.show({
                        parent: angular.element(document.body),
                        clickOutsideToClose: false,
                        locals: {share: share, shareId: shareId, patient: $scope.patient},
                        template: `
<md-dialog aria-label='Edit Sharing Arrangement Dialog'>
  <md-dialog-content>
  
    <div style='margin:.75em' layout-margin>
      <div>
        <h4> 
        
        <i class="fas fa-pencil-alt"></i> 
        Edit Sharing Arrangement </h4>
      </div>
      
      <div layout="column">
        <div class="bold"> Recipient </div>
        <div> {{ share.info.sharePurpose.recipient.third_party_recipient }} </div>
      </div>
      
      <hr>
      
    <div class="teal-velvet bold"> Limits </div>
        <div> <span class="grey-body bold"> Expiry Date: </span>
            {{ share.info.shareExpire }} </div>
            
            
            <div>
          <card-field
             ng-model='model'
             meta-model='{}'
             field='dateField'
             patient='{}'             
             form-name='"long_covid"'
             card-name='"long_covid"'>
          </card-field>
            </div>
            
        <div> <span class="grey-body bold"> Downloads: </span> 
            {{ share.access.length }} of {{ share.info.shareCount }}
        </div>
        
        <div>
          <card-field
             ng-model='model'
             meta-model='{}'
             field='downloadField'
             patient='{}'             
             form-name='"long_covid"'
             card-name='"long_covid"'>
          </card-field>
        </div>
      
      <hr>
      <div class="teal-velvet bold">Access Codes</div>

      <h5>System ID</h5>
      <div>
          To access the data, the recipient needs to visit our website
          <a target="_blank" href="https://www.downloadmydata.co.uk">www.DownloadMyData.co.uk</a>,
          the first thing they will need to do is select the correct System ID (SID) and click continue.
      </div>
            <div layout="row" layout-margin="2em">
            <div style="line-height: 3em">System ID (SID)</div>
      <md-chips md-removable="false" readonly="true"><md-chip>{{systemID}}</md-chip></md-chips>
  </div>
      <div>
          <div class="grey-body bold"> Three word passphrase </div>
        <p>
            The recipient should enter these on
            <a target="_blank" href="https://www.downloadmydata.com">www.downloadmydata.com</a>
        </p>
      </div>
      
      
      <div layout-align="center" layout="row">
           <md-chips md-removable="false" readonly="true">
             <md-chip ng-click="refreshAuthCodes()" style="cursor: pointer" class="theme-bg white-bg">
             Create a New Passphrase
                  <i class='fas fa-sync'></i>
             </md-chip>
           </md-chips>
    </div>
    
    
   <div layout="column" ng-if="accessCodes.length > 0">
       <div layout="row" layout-margin="2em">
           <div style="line-height: 3em">Word 1</div>
           <md-chips md-removable="false" readonly="true">
             <md-chip class="theme-bg white-bg pointer">{{ accessCodes[0] }} </md-chip>
           </md-chips>
       </div>
       <div layout="row" layout-margin="2em">
           <div style="line-height: 3em">Word 2</div>
           <md-chips md-removable="false" readonly="true">
             <md-chip class="theme-bg white-bg pointer">{{ accessCodes[1]}} </md-chip>
           </md-chips>
       </div>
       <div layout="row" layout-margin="2em">
           <div style="line-height: 3em">Word 3</div>
           <md-chips md-removable="false" readonly="true">
             <md-chip class="theme-bg white-bg pointer">{{ accessCodes[2] }}</md-chip>
           </md-chips>
       </div>
   </div>
      
      <div>
          <div class="grey-body bold"> Secret Information </div>

        <p>
        To change the Identity Verification question or the sharing limits above click on the Edit button below.
        </p>
      </div>
      
        <div>
          <card-field
             ng-model='model'
             meta-model='{}'
             field='questionField'
             patient='{}'             
             form-name='"long_covid"'
             card-name='"long_covid"'>
          </card-field>
          
          <card-field
             ng-model='model'
             meta-model='{}'
             field='answerField'
             patient='{}'             
             form-name='"long_covid"'
             card-name='"long_covid"'>
          </card-field>
        </div>
      
        <div layout='row' layout-xs='column'
             layout-margin layout-fill layout-align='center'>
          <md-button style='margin-right: 0' class="md-raised md-primary"
                  ng-click='update()'>
                  
               <i class="fas fa-pencil-alt"></i> 
                  Submit</md-button>
          <md-button style='margin-right: 0' class="md-raised"
                  ng-click='closeDialog()'>
                  
               <i class="fas fa-times"></i> 
                  Cancel
          </md-button>
        </div>
      
    </div>
  </md-dialog-content>
</md-dialog>
            `,
    controller: function DialogController($scope, $state, $mdDialog, AccessCodeStore, shareId, share, patient, $rootScope) {

        $scope.share = share;
        $scope.shareId = shareId;
        $scope.patient = patient;

        Cards.get('access_code').then(form => {
            form.cards.forEach(card => {
                if (card.name === 'security_code') {
                    card.fields.forEach(field => {
                        if (field.name === 'verification_question') {
                            $scope.questionField = field;
                        }

                        if (field.name === 'verification_answer') {
                            $scope.answerField = field;
                        }
                    });
                }

                if (card.name === 'limits') {
                    card.fields.forEach(field => {
                        if (field.name === 'expiry_date') {
                            $scope.dateField = field;
                        }

                        if (field.name === 'downloads') {
                            $scope.downloadField = field;
                        }
                    });
                }
            });

        });

        $scope.model = {expiry_date: share.info.shareExpire,
                        downloads: share.info.shareCount,
                        verification_question: share.info.sharePurpose.security_code.verification_question,
                        verification_answer: share.info.sharePurpose.security_code.verification_answer};

        $scope.dateField = {};
        $scope.downloadField = {};
        $scope.questionField = {};
        $scope.answerField = {};

        $scope.accessCodes = [];

        $scope.systemID = $rootScope.systemID;

        $scope.refreshAuthCodes = function() {
            AccessCodeStore.load().then(AccessCode => {
                let params = {
                    id: $scope.patient.id,
                    shareId: $scope.shareId
                };
                AccessCode.newauth(params, {},  function(resp) {
                    $scope.accessCodes = [resp.word_one, resp.word_two, resp.word_three];
                });
            }, err => {
                console.log(err);
            });
        };

        $scope.update = function() {

            AccessCodeStore.load().then(AccessCode => {

                const interim_datetime = moment($scope.model.expiry_date, 'DD/MM/YYYY');
                interim_datetime.hours(23);
                interim_datetime.minutes(59);
                interim_datetime.seconds(59);
                const date = interim_datetime.unix() * 1000;

                share.info.sharePurpose.security_code.verification_answer = $scope.model.verification_answer;
                share.info.sharePurpose.security_code.verification_question = $scope.model.verification_question;

                let data = {
                    sharePurpose: share.info.sharePurpose,
                    challenge: $scope.questionField.options[$scope.model.verification_question.value],
                    response: share.info.sharePurpose.security_code.verification_answer,
                    shareCount: $scope.model.downloads,
                    shareExpire: date,
                    formNames: share.info.formNames,
                    filters: share.info.filters
                };
                let params = {
                    id: $scope.patient.id,
                    shareId: $scope.shareId
                };
                AccessCode.update(params, data,  function() {
                    $scope.closeDialog();
                    $state.reload();
                });
            }, err => {
                console.log(err);
            });
        };

        $scope.closeDialog = function () {
            $mdDialog.hide();
        };
    }
                    });


                };


                $scope.showViewShareDialog = function (shareId, share) {
                    $mdDialog.show({
                        parent: angular.element(document.body),
                        clickOutsideToClose: false,
                        locals: {share: share, shareId: shareId, openEditDialog: $scope.showEditShareDialog},
                        template: `
<md-dialog aria-label='View Sharing Arrangement Dialog'>
  <md-dialog-content>
  
    <div style='margin:.75em' layout-margin>
      <div>
        <h4> View Sharing Arrangement</h4>
      </div>
      
      <div layout="row">
      <div flex="50">
        <div class="bold"> Recipient </div>
        <div> {{ share.info.sharePurpose.recipient.third_party_recipient }} </div>
      </div>
      
      <div flex="50">
        <div class="bold"> Limits </div>
        <div> <span class="grey-body bold"> Expiry Date: </span>
            {{ share.info.shareExpire }} </div>
        <div> <span class="grey-body bold"> Downloads: </span> 
            {{ share.access.length }} of {{ share.info.shareCount }} </div>
      </div>
      </div>
      
      <div class="bold">Access Codes</div>

      <div>
          <div class="grey-body bold"> Three Word Passphrase </div>
        <p>
        You won't be able to view the passphrase, To generate a new passphrase click on the edit button below.
        </p>
      </div>
      <div>
          <div class="grey-body bold"> Identity Verification </div>

        <p>
        The recipient will not enter this on the website, but they will see the answer on the screen to confirm that
        the correct account has been accessed.
        </p>
      </div>
      
       <div layout="row" layout-margin="2em">
           <div style="line-height: 3em">Question </div>
           <md-chips md-removable="false" readonly="true">
             <md-chip> {{ share.info.challenge }}</md-chip>
           </md-chips>
       </div>
       
       <div layout="row" layout-margin="2em">
           <div style="line-height: 3em">Answer&nbsp;&nbsp;</div>
           <md-chips md-removable="false" readonly="true">
             <md-chip> {{ share.info.sharePurpose.security_code.verification_answer }}</md-chip>
           </md-chips>
       </div>
       
        <div layout='row' layout-xs='column'
             layout-margin layout-fill layout-align='center'>
          <md-button style='margin-right: 0' class="md-raised md-primary"
                  ng-click='openEditDialog()'>
                  
               <i class="fas fa-pencil-alt"></i> 
                  Edit</md-button>
          <md-button style='margin-right: 0' class="md-raised"
                  ng-click='closeDialog()'>
                  
               <i class="fas fa-times"></i> 
                  Close
          </md-button>
        </div>
      
      <hr>
      <div class="teal-velvet bold">Access History</div>
      
      
    <div layout="row">
        <div flex="33"> Access </div>
        <div flex="33"> Name </div>
        <div flex="34"> Organization </div>
    </div>
    
    
    <div ng-repeat="history in share.access" layout="row">
        <div flex="33"> {{ history.dateAccessed }} </div>
        <div flex="33"> {{ history.reasonForAccess.data.details.name || '-' }} </div>
        <div flex="34"> {{ history.reasonForAccess.data.details.organization || '-' }} </div>
    </div>
      
      <div layout="row" layout-align="center">
          <md-button class="md-raised"
                  ng-click='closeDialog()'>
                   <i class="fas fa-times"></i> 
                  Close
          </md-button>
      </div>
      

    </div>
  </md-dialog-content>
</md-dialog>
            `,
                        controller: function DialogController($scope, $mdDialog, share, shareId, openEditDialog) {

                            $scope.share = share;
                            $scope.shareId = shareId;
                            $scope.openEditDialog = function () {
                                $scope.closeDialog();
                                openEditDialog(shareId, share);
                            };

                            $scope.closeDialog = function () {
                                $mdDialog.hide();
                            };
                        }
                    });


                };


                $scope.cancelSharingArragement = function (shareId) {


                    $mdDialog.show({
                        parent: angular.element(document.body),
                        clickOutsideToClose: false,
                        locals: {id: $scope.patient.id, shareId: shareId, shares: $scope.shares,
                                 switches: $scope.switches},
                        template: `
<md-dialog aria-label='Cancel Sharing Arrangement Confirmation Dialog'>
  <md-dialog-content>
  
    <div style='margin:.75em' layout-margin>
      <div>
        <h4> Cancel Sharing Arrangement </h4>
      </div>

      <div>
        <p>
       This will deactivate this sharing arrangement. Doing this will stop any further access. After turning off a
       record the 3 words will be lost and a new set of words must be generated.
        </p>
      </div>
      
      
        <div layout='row' layout-xs='column'
             layout-margin layout-fill layout-align='end'>
          <md-button style='margin-right: 0' class="md-raised md-primary"
                  ng-click='cancelArragement()'>OK</md-button>
          <md-button style='margin-right: 0' class="md-raised md-primary"
                  ng-click='closeDialog()'>Cancel</md-button>
        </div>

    </div>
  </md-dialog-content>
</md-dialog>
            `,
                        controller: function DialogController($scope, $mdDialog, id, shareId, shares, switches) {
                            $scope.closeDialog = function () {
                                switches[shareId] = !switches[shareId];
                                $mdDialog.hide();
                            };

                            $scope.cancelArragement = function () {
                                AccessCodeStore.load().then(AccessCode => {
                                    let data = {};
                                    let params = {
                                        id: id,
                                        shareId: shareId
                                    };
                                    AccessCode.cancelshare(params, data,  function() {
                                        shares[shareId].info.active = false;
                                        $mdDialog.hide();
                                    });
                                }, err => {
                                    console.log(err);
                                });
                            };
                        }
                    });


                };


            },
            template: `
            <md-content class="padding-halfem">
                <h4>Manage Information Sharing</h4>
                <p>
  <span class="bold right-padding">Create a new sharing arrangement:</span>
  <span>Click the button below(further instructions are provided).</span><br>
  <span class="bold right-padding">Edit an active sharing arrangement</span>
  <span>Select it from the list and a new window will open.</span><br>
  <span class="bold right-padding">Stop Sharing with a person</span>
  <span>Toggle the On/Off button. This will stop the recipient from being able to access data.
  You will need to create a new sharing arrangement (with 3 new words) if you want them to access again.</span>
                </p>
                <p class="top-padding">
                    <span class="bold right-padding">System ID (SID):</span><span>{{systemID}}</span><br>
                    <span>Give the SID to the data recipient.</span>
                </p>
            </md-content>
            
            <div class="center">
                <md-button ng-click="goToCodeGeneration()" class="md-primary md-raised">
                    Create New Sharing Arrangement
                </md-button>
                
                <md-button ng-click="goToHome()" class="md-primary md-raised">
                    Home
                </md-button>
            </div>
            
            <md-content class="padding-halfem">
            
                <h4>  
                    <i class="far fa-file-export"></i>
                    <span>Active Sharing Arrangements</span>
                </h4>
                <div layout="row">
                    <div flex="20"> Turn On/Off </div>
                    <div flex="20"> Expires </div>
                    <div flex="20"> Downloads </div>
                    <div flex="20"> Last Access </div>
                    <div flex="20"> Recipient </div>
                </div>
            
                <div ng-repeat="(id, share) in shares" layout="row" ng-if="share.info.active" class="pointer">
                    <div flex="20">
                      <md-switch md-no-ink
                                 class='md-primary no-top-margin no-bottom-margin'
                                 aria-label='Sharing Arrangement Toggle Switch'
                                 ng-change='cancelSharingArragement(id)'
                                 ng-model='switches[id]'>
                      </md-switch>
                    </div>
                    <div flex="20" ng-click="showViewShareDialog(id, share)"> {{ share.info.shareExpire }} </div>
                    <div flex="20" ng-click="showViewShareDialog(id, share)">
                        {{ share.access.length }} / {{ share.info.shareCount }}
                    </div>
                    <div flex="20" ng-click="showViewShareDialog(id, share)" > {{ share.info.lastAccessed }} </div>
                    <div flex="20" ng-click="showViewShareDialog(id, share)">
                    {{ share.info.sharePurpose.recipient.third_party_recipient }}
                    </div>
                </div>
                <h4>
                    <i class="fad fa-file-times"></i>
                    <span> Past Sharing Arrangements </span>
                </h4>
                
                <div ng-repeat="(id, share) in shares" layout="row" ng-if="!share.info.active">
                    <div flex="20">
                      <md-switch md-no-ink ng-disabled='true'
                                 class='md-primary no-top-margin no-bottom-margin'
                                 aria-label='Sharing Arrangement Toggle Switch'
                                 ng-change='cancelSharingArragement(id)'
                                 ng-model='falseSwitch'>
                      </md-switch>
                    </div>
                    <div flex="20"> {{ share.info.shareExpire }} </div>
                    <div flex="20"> {{ share.access.length }} / {{ share.info.shareCount }} </div>
                    <div flex="20"> {{ share.info.lastAccessed }} </div>
                    <div flex="20"> {{ share.info.sharePurpose.recipient.third_party_recipient }} </div>
                </div>
            </md-content>
            
                <div class="center">
                    <md-button ng-click="goToCodeGeneration()" class="md-primary md-raised">
                        Create New Sharing Arrangement
                    </md-button>
                </div>
                    `
        };
    });
