module.exports = {
    url: '/tasks',
    controller: function ($scope, $rootScope, $window, Pagination) {
        'ngInject';

        $scope.trainee = $rootScope.trainee;
        $window.document.title = 'CHAI Assigned Tasks';

        Pagination.reset();

    },
    template: `
  <navigation-bar></navigation-bar>

  <title-bar>
    <center>
      Assigned Tasks
    </center>
  </title-bar>


  <main>
    <div layout='row'>
      <user-task-view flex></user-task-view>
    </div>
  </main>

  `
};
