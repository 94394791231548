/**
 * @name utils
 * @ngdoc module
 * @description
 * Set of basic utilities
 */

angular.module('utils', [])

    .value('expandCards', {})

    .value('expandedEntities', {static: {}, repeating: {}})

    .value('problemIdentified', {})

    .value('CachedForms', {})
    .value('CachedPatientForms', {})

    .value('CurrentValueStore', {})

    .value('FormLiveModels', {})

    .value('FormPreviousModels', {})

    .value('PastPatientObject', {value: ''})

    .value('PreviousRecordInfo', {time: 0, readmit: undefined})

    .value('Exceptions', {value: []})

    .value('CardsToReset', {value: {}})

    .constant('supportedBrowsers', {'Chrome': '66', 'Safari': '11', 'Microsoft Edge': '79'})

    .filter('trustUrl', function ($sce) {
        return function (url) {
            return $sce.trustAsResourceUrl(url);
        };
    })


    .filter('dateFormatter', function () {
        return function (date) {
            if (date) {
                return date.replace(/\//g, ' / ');
            } else {
                return 'DD / MM / YYYY';
            }
        };
    })

    .service('Utils', function () {
        this.pastDateTime = function (hours) {
            return moment().subtract(hours, 'hours').unix() * 1000;
        };

        this.getGraphXParameters = function (mintime, starttime, maxtime, endtime) {

            var numTicks = 6;

            var minX = moment(mintime);
            var maxX = moment(maxtime);
            var start = moment(starttime);
            var end = moment(endtime);

            var hours = moment.duration(end.diff(start)).asHours();
            var days = moment.duration(end.diff(start)).asDays();

            if (days > 3) {

                var xTicks = [];

                var intervalSize = Math.round(days / numTicks);
                if (intervalSize == 0) {
                    intervalSize = 1;
                }

                var tick = minX.clone().startOf('day').add(1, 'd');
                while (tick.isBefore(maxX)) {
                    xTicks.push(tick.valueOf());
                    tick = tick.clone().add(intervalSize, 'd');
                }
                return {
                    xDomain: [start.toDate(), end.startOf('day').add(1, 'd').toDate()],
                    xTicks: xTicks
                };

            } else if (hours > 1) {
                var xTicks = [];
                var intervalSize = Math.round(hours / numTicks);

                if (intervalSize == 0) {
                    intervalSize = 1;
                }

                var tick = minX.clone().startOf('hour').add(1, 'h');
                while (tick.isBefore(maxX)) {
                    xTicks.push(tick.valueOf());
                    tick = tick.clone().add(intervalSize, 'h');
                }

                return {
                    xDomain: [start.toDate(), end.startOf('hour').add(1, 'h').toDate()],
                    xTicks: xTicks
                };
            } else {
                //we need to do minutes
                var xTicks = [];

                var tick = minX.clone().startOf('hour').add(10, 'm');
                while (tick.isBefore(maxX)) {
                    xTicks.push(tick.valueOf());
                    tick = tick.clone().add(10, 'm');
                }

                return {
                    xDomain: [start.toDate(), end.startOf('hour').add(1, 'h').toDate()],
                    xTicks: xTicks
                };
            }

        };

        this.formatHistoryMeta = function (timestamp, formId, meta, user, veryFirstRecord, showDivider) {
            meta = meta || {};
            var time = moment(timestamp);
            return {
                month: time.format('MMM'),
                date: time.format('DD'),
                time: time.format('HH:mm'),
                day: time.format('dd'),
                timestamp: timestamp,
                id: formId,
                approved: meta._approved_,
                originalTimestamp: meta._originalTimestamp,
                timestampAmendedReason: meta._timestampAmendedReason,
                readmit: meta.previousAdmission,
                user: user.substring(0,10),
                veryFirstRecord: veryFirstRecord,
                showDivider: showDivider
            };
        };

        this.formatBatchTime = function (timestamp) {
            var time = moment(timestamp);
            return {
                date: time.format('DD/MM/YY'),
                time: time.format('HH:mm')
            };
        };


        this.ageCalc = function (start_date) {

            var startDate = moment(start_date, 'DD/MM/YYYY');
            var endDate = moment().startOf('day');
            return endDate.diff(startDate, 'years', true);
        };

        this.timeElapsed = function (timestamp) {
            if (timestamp === undefined || timestamp === 0) {
                return '-';
            }
            return moment(timestamp).fromNow();
        };

        this.noteTimeFormatting = function (timestamp) {

            let result = {'timestamp': '-', 'date': '-', 'time': '-', 'showTooltip': false};

            if (timestamp === undefined || timestamp === 0) {
                return result;
            }

            let moment_ts = moment(timestamp);

            if (moment().diff(moment_ts, 'hours') >= 12) {
                result.timestamp = moment_ts.format('HH:mm, MMM DD');
                result.showTooltip = false;
            } else {
                result.timestamp = moment_ts.fromNow();
                result.showTooltip = true;
            }

            result.date = moment_ts.format('DD MMMM YYYY');
            result.time = moment_ts.format('HH:mm');
            return result;
        };

        this.noteDate = function (timestamp) {
            return moment(timestamp).calendar(null, {
                    sameDay: '[Today, ]D MMMM YYYY',
                    lastDay: '[Yesterday, ]D MMMM YYYY',
                    lastWeek: 'dddd, D MMMM YYYY',
                    sameElse: 'dddd, D MMMM YYYY'
                }
            );
        };

        this.standardFormatting = function (timestamp) {
            return moment(timestamp).format('dddd, Do MMMM YYYY');
        };

        this.taskDueFormat = function (timestamp) {
            return moment(timestamp).format('MMM D, HH:mm');
        };

        this.convertToMinutes = function (totalSeconds) {
            var integralSeconds = Math.floor(totalSeconds);
            var minutes = ('0' + Math.floor((integralSeconds / 60))).slice(-2);
            var seconds = ('0' + (integralSeconds % 60)).slice(-2);

            return minutes + ':' + seconds;
        };


        this.dataURItoPNGblob = function (dataURI) {
            if (!dataURI) {
                return;
            }
            var binaryData = atob(dataURI.split(',')[1]);
            var dataArray = [];
            for (var i = 0; i < binaryData.length; i++) {
                dataArray.push(binaryData.charCodeAt(i));
            }
            return new Blob([new Uint8Array(dataArray)], {type: 'image/png'});
        };

        this.formatMulCheckboxes = function (value, field) {
            var options = [];
            for (var option in value) {
                if (value[option]) {
                    options.push(field.options[option]);
                }
            }
            return options.join(', ');
        };

        this.convertToArray = function (dict) {
            var items = [];
            for (var key in dict) {
                dict[key].key = key;
                items.push(dict[key]);
            }
            return items;
        };

        function isNonRepCardEmpty(card) {
            for (let field in card) {
                if (!field.startsWith('$$')) {
                    if (this.isFieldEmpty(card[field])) {
                        return false;
                    }
                }
            }
            return true;
        }

        this.isFieldEmpty = function (value) {
            return ((value == undefined) ||
                (value === '') ||
                (typeof (value) == 'object' && Object.keys(value).length === 0)) ||
                (typeof (value) == 'object' && 'hour' in value && 'minute' in value &&
                    value.hour === '' && value.minute === '') ||
                (typeof (value) == 'object' && 'value' in value && value.value === undefined);
        };

        this.isCardEmpty = function (card) {
            if ('cards' in card) {
                for (let data of card.cards) {
                    if (!isNonRepCardEmpty(data)) {
                        return false;
                    }
                }
                return true;
            } else {
                return isNonRepCardEmpty(card);
            }
        };

        this.removeDerivedKeys = function (oldData, newData, form) {
            for (let card of form.cards) {
                for (let field of card.fields) {
                    if ('value' in field) {
                        if (card.name in oldData) {
                            delete oldData[card.name][field.name];
                        }
                        if (card.name in newData) {
                            delete newData[card.name][field.name];
                        }
                    }
                }
            }
        };

        this.addFieldTextToMeta = function (fieldModel, metaModel, fieldDef) {
            // radio buttons is handled in input module because otherwise
            // it would not have been to possible to get text when
            // `other` is selected

            if (fieldModel == undefined) {
                return;
            }

            if (fieldDef.type == 'text_input') {
                metaModel.text = fieldModel;
                return;
            }

            if (fieldDef.type == 'password') {
                metaModel.text = fieldModel;
                return;
            }

            if (fieldDef.type == 'number_field') {
                metaModel.text = fieldModel.toString();
                return;
            }

            if (fieldDef.type == 'blood_pressure') {
                metaModel.text = (fieldModel.sys || '-') + '/' + (fieldModel.dia || '-');
                return;
            }

            if (fieldDef.type == 'phone_number_field') {
                metaModel.text = fieldModel.toString();
                return;
            }

            if (fieldDef.type == 'sign') {
                metaModel.text = 'Signature';
                return;
            }

            if (fieldDef.type == 'audio_field') {
                metaModel.text = 'Audio';
                return;
            }

            if (fieldDef.type == 'check_box') {
                metaModel.text = fieldModel ? 'Yes' : 'No';
                return;
            }

            if (fieldDef.type == 'multiple_checkboxes') {
                metaModel.text = this.formatMulCheckboxes(fieldModel, fieldDef);
                return;
            }

            if (fieldDef.type == 'image_input') {
                metaModel.text = 'Image';
                return;
            }

            if (fieldDef.type == 'date_field') {
                metaModel.text = fieldModel;
                return;
            }

            if (fieldDef.type == 'time_field') {
                metaModel.text = fieldModel.hour !== undefined ? fieldModel.hour + ':' + fieldModel.minute : '';
                return;
            }

            if (fieldDef.type == 'repeat_period') {
                let str = '';

                if (fieldModel.due.days > 0) {
                    str = str + fieldModel.due.days + 'd ';
                }

                if (fieldModel.due.hours > 0) {
                    str = str + fieldModel.due.hours + 'h ';
                }

                if (fieldModel.due.minutes > 0) {
                    str = str + fieldModel.due.minutes + 'm ';
                }

                metaModel.text = str;
                return;

            }

            if (fieldDef.type == 'nhs_number_field') {
                metaModel.text = fieldModel.toString();
                return;

            }

            if (fieldDef.type == 'text_area') {
                metaModel.text = fieldModel;
                return;
            }

            if (fieldDef.type == 'range_field') {
                metaModel.text = fieldModel.toString();
                return;
            }

            if (fieldDef.type == 'radio_buttons') {
                return;
            }

            metaModel.text = fieldModel.toString();

        };

        this.accordion = class Accordion {
            constructor(el) {
                this.el = el;
                this.summary = el.querySelector('summary');
                this.content = el.querySelector('.content');

                this.animation = null;
                this.isClosing = false;
                this.isExpanding = false;
                this.summary.addEventListener('click', (e) => this.onClick(e));
            }

            onClick(e) {
                e.preventDefault();
                this.el.style.overflow = 'hidden';
                if (this.isClosing || !this.el.open) {
                    this.open();
                } else if (this.isExpanding || this.el.open) {
                    this.shrink();
                }
            }

            shrink() {
                this.isClosing = true;

                const startHeight = `${this.el.offsetHeight}px`;
                const endHeight = `${this.summary.offsetHeight}px`;

                if (this.animation) {
                    this.animation.cancel();
                }

                this.animation = this.el.animate({
                    height: [startHeight, endHeight]
                }, {
                    duration: 500,
                    easing: 'ease-out'
                });

                this.animation.onfinish = () => this.onAnimationFinish(false);
                this.animation.oncancel = () => this.isClosing = false;
            }

            open() {
                this.el.style.height = `${this.el.offsetHeight}px`;
                this.el.open = true;
                window.requestAnimationFrame(() => this.expand());
            }

            expand() {
                this.isExpanding = true;
                const startHeight = `${this.el.offsetHeight}px`;
                const endHeight = `${this.summary.offsetHeight + this.content.offsetHeight}px`;

                if (this.animation) {
                    this.animation.cancel();
                }

                this.animation = this.el.animate({
                    height: [startHeight, endHeight]
                }, {
                    duration: 500,
                    easing: 'ease-out'
                });
                this.animation.onfinish = () => this.onAnimationFinish(true);
                this.animation.oncancel = () => this.isExpanding = false;
            }

            onAnimationFinish(open) {
                this.el.open = open;
                this.animation = null;
                this.isClosing = false;
                this.isExpanding = false;
                this.el.style.height = this.el.style.overflow = '';
            }
        };

    });
