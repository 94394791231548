module.exports = {
    url: '/exception',
    params: {
        noConnection: false
    },
    controller($scope, Exceptions, Server, ChaiWebSocket, $transitions,
               aboutStore, $state) {
        'ngInject';

        $scope.noConnection = $state.params.noConnection

        Server.deleteAuthToken();
        ChaiWebSocket.stop();
        $scope.exceptions = Exceptions.value;
        $scope.time = Date().toString();
        $scope.supportSite = "https://docs.google.com/forms/d/e/1FAIpQLSet3Fril0zOBjbQrk_mfNXYfvUcYBuH0a1pmGtWwXHowOX6hg/viewform?";
        
        $scope.supportSite += "entry.686015725=" + navigator.userAgent + "&" +
        //"entry.418491884=" + $rootScope.systemID + "&" +
        "entry.1111174575=" + "Stack: ";

        $scope.exceptions.forEach(function (item, index) {
            console.log(item, index);
            $scope.supportSite += " Exception: " + index + " ";
            $scope.supportSite += item.exception.message;
            $scope.supportSite += " Stack: " + index + " ";
            $scope.supportSite += item.exception.stack;
          });
          

        Server.ping().then(() => {
            $scope.connectionAvailable = 'Yes';
        }).catch(() => {
            $scope.connectionAvailable = 'No';
        })

        aboutStore.compose().then(info => {
            if (info.build) {
                $scope.composeVersion = info.build.git;
                $scope.supportSite += "cards build: " + info.build.git;
            }
        }).catch(err => {
            $scope.composeVersion = 'Not Known';
        });

        aboutStore.card().then(info => {
            if (info.build) {
                $scope.cardVersion = info.build.git;
            }
        }).catch(err => {
            $scope.cardVersion = 'Not Known';
        });

        $transitions.onBefore({}, function () {
            return false;
        });

    },
    template: `
    <main>


    <div class="exception">
      <h3>Sorry, an unexpected error has occurred on this device.</h3>
      <p>Please close the tab, disable any browser extensions including add blockers and re try the action. If the problem persists send a screenshot of this page to support      </p>
      <p>Please include details about what you were
      doing before the error occurred. Thanks!</p>

      <div class='version-info' md-colors="{background: 'primary-A100'}">
            Username: {{ username }}<br>
            Time: {{ time }}<br>
            CHAI Version: {{ composeVersion }}<br>
            Card Version: {{ cardVersion }} <br>
            <div ng-if='!noConnection'>Able to Connect to Backend? {{ connectionAvailable }}</div>
      </div>

      <div class='trace' ng-if="(exceptions.length > 0) && !noConnection">
         <div style='overflow-x: scroll;' >
          <div ng-repeat='exception in exceptions'>
            {{ exception.exception.stack }}<br>
          </div>
        </div>
        <div class='support-button' style='font-size: 0.9em'>
                <md-button class='md-raised'>
                    <a ng-href="{{supportSite}}" target="_blank">
                        <span>Report your problem</span>
                    </a>
                </md-button>
            </div>
       </div>

       

      <div class='trace' ng-if="(exceptions.length ===  0) && !noConnection">No error message was captured.</div>

      <div class='trace' ng-if="noConnection">
        Sorry, this device can not connect to the server, please check with your local network administrator.
      </div>

       <p>After reporting the error message, please restart your browser or device,
          log into CHAI and check that your last activity was successful.</p>
      </div>
    </main>
  `
};
