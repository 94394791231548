/*
 * @name search
 * @ngdoc module
 * @description
 * Patient Search Components
 */

angular.module('search', [])
    .service('searchStore', function (Server) {
        this.load = function () {
            return Server.createResource('/search', {
                search: {
                    method: 'POST',
                    url: '/search',
                    isArray: true
                }
            });
        };
    })

    .service('viewStore', function (Server) {
        this.load = function () {
            return Server.createResource('/patient/:id/views/:view', {});
        };
    })

    .controller('SearchController', function ($scope, searchStore, matchmedia, $rootScope) {

        $scope.searchFields = [{
            'type': 'text_input',
            'name': 'first_name',
            'label': 'First Name'
        },
            {
                'type': 'text_input',
                'name': 'last_name',
                'label': 'Last Name'
            },
            {
                'type': 'text_input',
                'name': 'preferred_name',
                'label': 'Preferred Name'
            },
            {
                'type': 'date_field',
                'name': 'date_of_birth',
                'label': 'Date of Birth',
                'placeholder': 'DD/MM/YYYY'
            },
            {
                'type': 'range_field',
                'name': 'age',
                'label': 'Age Range (Years)'
            },
            {
                'type': 'text_input',
                'name': 'address',
                'label': 'Address (Partial)'
            },
            {
                'type': 'text_input',
                'name': 'post_code',
                'label': 'Post Code'
            },
            {
                'type': 'radio_buttons',
                'name': 'gender',
                'label': 'Gender',
                'other': true,
                'options': {
                    'm': 'Male',
                    'f': 'Female'
                }
            }
        ];

        if ($rootScope.isHospitalNumberPresent) {
            let hospital_number_field = {
                'type': 'text_input',
                'name': 'hospital_number',
                'label': 'Hospital Number'
            }

            let nhs_number_field = {
                'type': 'nhs_number_field',
                'name': 'nhs_number',
                'label': 'NHS Number'
            }

            $scope.searchFields.splice(2, 0, hospital_number_field, nhs_number_field);
        }


        $scope.trainee = $rootScope.trainee;

        $scope.searchCriteria = {};


        $scope.wasShowingPortraitResults = false;
        $scope.message = ['This is where your results will appear,',
            'there are no results to show right now'].join(' ');
        $scope.advice = '';
        $scope.patientSelected = {'value': false, 'showPortraitResults': false};


        matchmedia.on('(max-width: 800px)', function (mql) {
            $scope.portrait = mql.matches;
            if (!$scope.portrait) {
                $scope.wasShowingPortraitResults = $scope.patientSelected.showPortraitResults;
                $scope.patientSelected.showPortraitResults = false;
            } else {
                $scope.patientSelected.showPortraitResults = $scope.wasShowingPortraitResults;
            }
        });


        $scope.$watch('patientSelected.id', function () {
            if ($scope.patientSelected.id) {
                $scope.patientsFound.some(function (patient) {
                    if ($scope.patientSelected.id == patient.id) {
                        $scope.patient = patient;

                        return true;
                    }
                });
            }
        });

        $scope.searchPatients = function () {

            $scope.patientsFound = [];

            for (var field in $scope.searchCriteria) {
                if (!$scope.searchCriteria[field]) {
                    delete ($scope.searchCriteria[field]);
                }
                if (field == 'gender' &&
                    !$scope.searchCriteria[field].value) {
                    delete ($scope.searchCriteria[field]);
                }
                if (field == 'age' &&
                    !$scope.searchCriteria[field].min && !$scope.searchCriteria[field].max) {
                    delete ($scope.searchCriteria[field]);
                }
            }


            var totalSearchCriterias = Object.keys($scope.searchCriteria).length;

            if (totalSearchCriterias === 0) {
                $scope.message = 'Cannot search';
                $scope.advice = 'Please enter at least one search criteria';
                return;
            }

            if (totalSearchCriterias == 1) {
                if ($scope.searchCriteria.gender) {
                    $scope.message = 'Cannot search';
                    $scope.advice = "Gender can't be the only search criteria";
                    return;
                }
            }

            if ($scope.searchCriteria.age) {
                //can't just use if !$scope.searchCriteria.age.min because returns false for 0s
                if ($scope.searchCriteria.age.min === undefined || $scope.searchCriteria.age.max === undefined ||
                    $scope.searchCriteria.age.min === null || $scope.searchCriteria.age.max === null) {
                    $scope.message = 'Cannot search';
                    $scope.advice = 'Please enter both min and max age';
                    return;
                }
            }


            var searchCriteriaFormatted = angular.copy($scope.searchCriteria);

            searchStore.load()
                .then(searchResource => {
                    searchResource.search({'admissions': {'patient_details': searchCriteriaFormatted}},
                        function (patients, headers, code, reason) {
                            $scope.message = '';
                            $scope.advice = '';
                            if (code !== 200) {
                                $scope.message = reason;
                                $scope.advice = 'Please add more details and try again.';
                            } else {
                                if (patients.length > 0) {
                                    if (patients[patients.length - 1].id == 'REMOVED RESULTS') {
                                        console.log('search results removed');
                                        $scope.patientsFound = patients.slice(0, patients.length - 1);
                                    } else {
                                        $scope.patientsFound = patients;
                                    }
                                }
                                if ($scope.patientsFound.length === 0) {
                                    $scope.message = 'No clients found.';
                                    $scope.advice = 'Please check the details and try again.';
                                } else {
                                    if ($scope.portrait) {
                                        $scope.patientSelected.showPortraitResults = true;
                                    }
                                }
                            }
                        }, err => {
                        });
                })
                .catch(err => {
                    console.error('Could not load clients:', err);
                });
        };

        $scope.createPatient = function () {
            Patient.save({}, function (patient) {
                $state.go('patient.dash', {'id': patient.id});
            });
        };

    })

    .directive('search', function () {
        return {
            restrict: 'E',
            scope: {},
            controller: 'SearchController',
            template: `
    <div>
    <div ng-if= '!patientSelected.value' class='row scrollable-container'>
      <div ng-if='!patientSelected.showPortraitResults' ng-class='[{"small-8": !portrait},
                      {"small-12": portrait},
                      "columns scrollable white"]'>
        <div class='search-form'>
          <form ng-submit="searchPatients()">
          <card-field
             ng-model='searchCriteria'
             field='field'
             ng-repeat='field in searchFields'>
          </card-field>

          <div class='search-form__button'>
            <md-button type="submit"
                       md-colors="{background: 'primary-600', color: 'primary-50'}">
              Search
            </md-button>
          </div>
          </form>
        </div>

        <div ng-if='portrait' class='row'>
          <div md-colors="{color: 'primary-100'}"
               class='small-12 columns search-results__title'>
            Results
          </div>
        </div>

        <mini-results-panel message='message'
                            ng-if='portrait'
                            class='"mini-results-panel"'
                            advice='advice'>
        </mini-results-panel>

      </div>


      <div ng-if='(!portrait || (portrait && patientSelected.showPortraitResults))'
           ng-class='[{"small-4" : !portrait},
                      {"small-12": (portrait && patientSelected.showPortraitResults)},
                      "columns scrollable"]'>

          <div ng-if='portrait'>
            <search-overview search-criteria='searchCriteria' show='patientSelected.showPortraitResults'>
            </search-overview>
          </div>

          <results-panel advice='advice'
                         message='message'
                         portrait='portrait'
                         patient-selected='patientSelected'
                         results='patientsFound'>
          </results-panel>

        </div>
    </div>

    <div ng-if='patientSelected.value' class='row scrollable-container'>
      <div ng-if='!portrait' class='small-4 columns'>
          <results-panel results='patientsFound'
                         portrait='portrait'
                         patient-selected='patientSelected'>
          </results-panel>
      </div>
      <div md-colors="{'border-color': 'primary-1200'}"
           class="result-info" ng-class='[{"small-8": !portrait},
                      {"small-12": portrait},
                      "columns white top-padding"]'>

        <div ng-if='portrait'>
          <search-overview search-criteria='searchCriteria' show='patientSelected.value'>
          </search-overview>
        </div>

         <div ng-class="row">
      <div class='small-12 columns align-right right-padding top-padding'>
           <a class='grey'
              ui-sref="home">Cancel <i class="fas fa-times"></i>
           </a>
      </div>
    </div>


        <div class='selected-patient-preview no-clicks'>

          <patient-preview patient='patient'
                           type='"normal"'>
          </patient-preview>
        </div>
        <view-panel patient='patient'></view-panel>
        <searched-patient-info patient='patient'></searched-patient-info>
      </div>
    </div>
    </div>
    `
        };
    })

    .directive('searchOverview', function () {
        return {
            restrict: 'E',
            scope: {
                searchCriteria: '=',
                show: '='
            },
            controller: function ($scope) {

                var searchStringKeys = ['first_name',
                    'last_name',
                    'preferred_name',
                    'date_of_birth',
                    'nhs_number',
                    'hospital_number'];

                var searchStringValues = searchStringKeys.map(key => {
                    return $scope.searchCriteria[key];
                });

                if (searchStringValues[0] && searchStringValues[1]) {
                    var name = searchStringValues[0] + ' ' + searchStringValues[1];
                    searchStringValues = [name].concat(searchStringValues.slice(2));
                }

                $scope.searchString = searchStringValues.filter(element => {
                    return element !== undefined;
                }).join(';');

                $scope.hide = function () {
                    console.log('hiding');
                    $scope.show = false;
                };

            },
            template: `
      <div class='row search-overview' ng-click="hide()">
        <div class='small-1 columns'>
          <i class='fas fa-search'></i>
        </div>
        <div class='small-11 columns'>
          <input type='text' disabled='true' ng-model='searchString' />
        </div>
    `
        };
    })

    .directive('viewPanel', function () {
        return {
            restrict: 'E',
            scope: {
                patient: '='
            },
            controller: function ($scope, $rootScope, viewStore, $state, Readmissions) {

                $scope.trainee = $rootScope.trainee;
                $scope.canSubmitForms = $rootScope.canSubmitForms;
                $scope.patientLabel = $rootScope.patientLabel;

                $scope.$watch('patient.id', function (patientId) {
                    $scope.views = getFilteredViews();
                });

                function getFilteredViews() {
                    return $rootScope.views.filter(function (list) {
                        if ($scope.patient.views.hasOwnProperty(list.id)) {
                            return false;
                        }
                        return true;
                    });
                }

                $scope.addPatientToView = function (patientId, viewId, navigateToDash) {
                    viewStore.load().then(viewResource => {
                        viewResource.save({id: patientId, view: viewId}, {}, function (resp) {
                            var listName = '';
                            var listType = '';
                            $scope.views.forEach(function (list) {
                                if (list.id === viewId) {
                                    listName = list.data.name;
                                    listType = list.data.type;
                                }
                            });

                            $scope.patient.state = resp.state;
                            $scope.patient.views[viewId] = {
                                'name': listName,
                                'type': listType,
                                'added_by': $rootScope.username
                            };
                            $scope.views = getFilteredViews();

                            if (navigateToDash) {
                                if ($scope.patient.state === 'READMIT') {
                                    Readmissions.readmit($scope.patient.id);
                                } else {
                                    $state.go('patient.dash', {id: $scope.patient.id});
                                }
                            }
                        }, err => {
                        });
                    });

                };

                /** cannot remove from community. can remove from ward if user is a community manager
                 * or ward manager of that ward  */
                $scope.canDelete = function (listName, details) {
                    if (details.type == 'community') {
                        return false;
                    }
                    if ($rootScope.wardManagerOf.includes(listName) || $rootScope.isCommunityManager) {
                        return true;
                    }
                    return false;
                    // ($scope.patient.views[listName].added_by !== 'PAS' && $rootScope.canAddPatients);
                };

                $scope.removePatientFromList = function (listName, patientId) {
                    if (listName !== '') {
                        viewStore.load().then(patientViewResource => {
                            patientViewResource.delete({id: patientId, view: listName}, {}, function () {
                                delete $scope.patient.views[listName];
                                $scope.views = getFilteredViews();
                            }, err => {
                            });
                        });
                    }
                };

                $scope.addToView = function () {
                    var viewId = document.getElementById('selectedView').value;
                    $scope.addPatientToView($scope.patient.id, viewId);
                };

                $scope.addToViewAndView = function () {
                    var viewId = document.getElementById('selectedView').value;
                    $scope.addPatientToView($scope.patient.id, viewId, true);
                };

            },
            template: `
 <div>
  <h3 md-colors="{color: 'primary-1200'}">Currently a member of Teams</h3>
  <div class='list-name' ng-repeat='(key, val) in patient.views'>
    {{val.type === "ward" ? "Team: " : val.type === "consultant" ? "Consultant: " :
    ""}} {{val.name}}
    <span class='added-by'>(added by: {{val.added_by}})</span>
    <i class='fas fa-times' ng-if='canDelete(key, val)'
    ng-click='removePatientFromList(key, patient.id)'></i>
  </div>
</div>
<div ng-if="canSubmitForms">
  <div class='view-header'>
    Make this {{ patientLabel }} visible by member of Team
  </div>
  <div class='row'>
    <div class='small-12 columns view-container'>
      <select id='selectedView'>
        <option ng-repeat='view in views' value='{{ view.id }}'>
          {{ view.data.name }}
        </option>
      </select>
    </div>
  </div>
  <div class='row'>
    <div class='small-8 right columns'>


      <md-button md-colors="{background: 'primary-600', color: 'primary-50'}"
                 class='search-button no-bottom-margin add-view'
      ng-click='addToViewAndView()' >
        <i class='fas fa-user-plus'></i>
        Add and View Summary
      </md-button>
      <md-button md-colors="{background: 'primary-600', color: 'primary-50'}"
                 class='search-button no-bottom-margin add-view'
      ng-click='addToView()' >
        <i class='fas fa-user-plus'></i>
        Add
      </md-button>
    </div>
  </div>
</div>
    `
        };
    })

    .directive('searchedPatientInfo', function () {
        return {
            restrict: 'E',
            scope: {
                patient: '='
            },
            controller: function ($scope, $rootScope, Readmissions, $state, Pagination) {
                $scope.trainee = $rootScope.trainee;
                $scope.patientLabel = $rootScope.patientLabel;

                $scope.isHospitalNumberPresent = $rootScope.isHospitalNumberPresent;

                $scope.$watch('patient.id', function (channel) {
                    Pagination.forcedChannelUpdate(channel);
                });

                $scope.viewDashboard = function () {
                    if ($scope.patient.state === 'READMIT') {
                        Readmissions.readmit($scope.patient.id);
                    } else {
                        $state.go('patient.dash', {id: $scope.patient.id});
                    }
                };

            },
            template: `
      <div>

      <h3 md-colors="{color: 'primary-1200'}">{{ patientLabel }} Info
      <a md-colors="{color: 'primary-1200'}"
         class='result-info__dashlink' ng-click='viewDashboard()'
         title="View without adding to group">
           <i class="far fa-address-card"></i> View Summary
         </a>
      </h3>


      </div>


      <searched-patient-field question='"Preferred Name"'
       answer='patient.data.admissions.patient_details.preferred_name'>
      </searched-patient-field>

      <div class='row'>
      <div class='small-6 large-3 columns'>
        <searched-patient-field question='"Date Of Birth"'
          answer='patient.data.admissions.patient_details.date_of_birth'>
        </searched-patient-field>
      </div>
      <div ng-show='isHospitalNumberPresent' class='small-6 large-9 columns'>
        <searched-patient-field question='"NHS Number"'
            answer='patient.data.admissions.patient_details.nhs_number'
        </searched-patient-field>
      </div>
      </div>

      <searched-patient-field question='"Phone Number"'
       answer='patient.data.admissions.patient_details.phone_mobile'>
      </searched-patient-field>

      <searched-patient-field question='"Address"'
       answer='patient.data.admissions.patient_details.address'>
      </searched-patient-field>

      <searched-patient-field question='"Post Code"'
       answer='patient.data.admissions.patient_details.post_code'>
      </searched-patient-field>

      <div>
         <h3 md-colors="{color: 'primary-1200'}">Notes Preview</h3>
      </div>

      <div>
        <channel-notes-overview channel='patient.id' username='username'>
        </channel-notes-overview>
      </div>
    `
        };
    })

    .directive('searchedPatientField', function () {
        return {
            restrict: 'E',
            scope: {
                question: '=',
                answer: '='
            },
            link: function (scope) {
                scope.$watch('answer', function () {
                    if (scope.answer === undefined || scope.answer === '') {
                        scope.answer = 'Not Entered';
                    }
                });

            },
            template: `
      <div class='result-info__question'>{{ question }}</div>
      <div class='result-info__answer'
           ng-class='[{"result-info__answer--disabled": answer === "Not Entered"}]'>
            {{ answer }}
      </div>
    `
        };
    })

    .directive('miniResultsPanel', function () {
        return {
            restrict: 'E',
            scope: {
                message: '=',
                advice: '=',
                class: '='
            },
            template: `
      <div class='row'>
        <div ng-class='["small-12 columns search-results__advice {{ class }}",
                        {"search-results__warning": advice}]'>
            <i ng-if='advice'
               class="fas fa-exclamation-triangle">
            </i>
            {{message}}<br/>
            {{advice}}
        </div>
      </div>
    `
        };
    })

    .directive('resultsPanel', function () {
        return {
            restrict: 'E',
            scope: {
                results: '=',
                message: '=',
                advice: '=',
                portrait: '=',
                patientSelected: '='
            },
            controller: function ($scope, PatientStore, $state, $rootScope) {

                $scope.trainee = $rootScope.trainee;
                $scope.canAddPatients = $rootScope.canAddPatients;


                $scope.selectPatient = function (patient) {
                    $scope.patientSelected.value = true;
                    $scope.patientSelected.id = patient;
                };

                $scope.back = function () {
                    $scope.patientSelected.value = false;
                    $scope.patientSelected.id = undefined;
                };

            },
            template: `

    <div id='results-content'>
    <div class='row'>
      <div class='small-12 columns align-right right-padding top-padding'>
           <a class='grey' ng-if='!patientSelected.value'
              ui-sref="home">Cancel <i class="fas fa-times"></i>
           </a>
           <a class='grey' ng-if='patientSelected.value'
              ng-click="back()">Back <i class="fas fa-arrow-left"></i>
           </a>
      </div>
    </div>

    <div class='row'>
      <div md-colors="{color: 'primary-100'}"
           class='small-12 columns search-results__title'>
        Results
      </div>
    </div>

    <mini-results-panel ng-if='!patientSelected.value && message'
                          message='message'
                          advice='advice'>
      </mini-results-panel>

      <div ng-repeat='patient in results'
           md-colors="{'border-color': 'primary-1200'}"
           ng-class='["row patient-preview-large",
                     {"selected-patient": patientSelected.value && (patientSelected.id == patient.id)}]'>
        <div ng-class='["small-12 columns left-padding"]'>
          <patient-preview patient='patient'
                           ng-click='selectPatient(patient.id)'
                           type='"search"'>
          </patient-preview>
        <hr ng-if="!patientSelected.value || patientSelected.id !== patient.id">
        </div>

      </div>
      </div>

    `
        };
    });
