angular.module('nav', [])
    /**
     * @name navigationBar
     * @ngdoc directive
     * @restrict E
     * @description
     * The navigation bar at the top of the screen.
     *
     * If $rootScope.signoff is defined, it will not show any navigation options
     */
    .directive('navigationBar', function () {
        return {
            restrict: 'E',

            controller: function ($scope, $rootScope, $state, PatientStore, Server) {
                $scope.locale = $rootScope.locale;


                Server.getConfig().then(function(config) {

                    $scope.managementUrl = config.userManagementUrl + $scope.locale + '/';
                    $scope.accountManagementUrl = config.accountManagementUrl;
                }, 500);

                $scope.trainee = $rootScope.trainee;
                $scope.isAManager = $rootScope.isAManager;
                $scope.canSubmitForms = $rootScope.canSubmitForms;
                $scope.fullName = $rootScope.fullName;
                $scope.canSearchPatients = $rootScope.canSearchPatients;
                $scope.canAddPatients = $rootScope.canAddPatients;

                $scope.signoff = $rootScope.signoff;

                $scope.createPatient = function () {
                    PatientStore.load().then(Patient => {
                        Patient.save({}, function (patient) {
                            $state.go('patient.dash', {'id': patient.id});
                        }, err => {
                        });
                    });
                };

             $scope.refresh = function () {
                $state.go('home', {}, {reload: true});
             };

            },
            template: `
      <nav ng-if="!signoff" class='global'
           md-colors="{color: 'primary-600'}">
         <div class='left'>

       <a ng-click='refresh()'>
         <i class='fas fa-th-list'></i>
       </a>

        <a ui-sref='messages'>
          <i ng-hide='unreadStatus.ward' class='far fa-comment silver-grey'></i>
          <i ng-show='unreadStatus.ward' class='fas fa-comment-lines'></i>
          <i ng-show='unreadStatus.ward' class='fas fa-circle notification-dot'></i>
        </a>

        <a ui-sref='search' ng-if='canSearchPatients'>
          <i class='fas fa-search'></i>
        </a>

        <a ui-sref='import'>
          <i class='fas icon1-user-arrow-in-right'></i>
        </a>

        <a ng-if='canSubmitForms && !trainee' ui-sref='batchsignoff'>
          <i class='icon2-countersign'></i>
        </a>
         <a ui-sref='tasks'>
         <md-tooltip md-delay="500" md-direction="bottom">Assigned Tasks</md-tooltip>
         <i class="fa-solid fa-ballot-check"></i>
        </a>

      </div>
      <div class='right'>
        <div class='mini-user'><span translate>header_1</span><br/> {{fullName}}</div>
        <i ng-if='trainee' class='icon2-countersign'></i>
        <a ng-href='{{accountManagementUrl}}' alt="Change Password">
          <i class='fas fa-lock'></i>
        </a>

        <a ng-if="isAManager" ng-href='{{managementUrl}}' alt='Visit User Management App'>
          <i class='fas fa-user-cog'></i>
        </a>

        <a ui-sref='logout'>
          <i class='fas fa-sign-out-alt'></i>
        </a>
      </div>
      </nav>

      <nav ng-if="signoff" class='global signoff-nav'>
         <center>
        {{ fullName }} <i class='icon2-countersign'></i> Countersigning Mode
        </center>
      </nav>

    `
        };
    });
