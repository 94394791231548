module.exports = {
    url: '/messages',
    controller: function ($scope, $rootScope, $window, Pagination) {
        'ngInject';

        $scope.trainee = $rootScope.trainee;
        $window.document.title = 'CHAI Messages';

        Pagination.reset();

    },
    template: `
  <navigation-bar></navigation-bar>

  <title-bar>
    <center>
      Group Chat
    </center>
  </title-bar>


  <main>
    <div layout='row'>
       <channel-notes flex channel='"ward"' username=username>
       </channel-notes>
    </div>
  </main>

  `
};
