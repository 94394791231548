angular.module('animate', [])

    .constant('EASING_FUNCTIONS', {
        linear: t => t,
        // accelerating from zero velocity
        easeInQuad: t => t * t,
        // decelerating to zero velocity
        easeOutQuad: t => t * (2 - t),
        // acceleration until halfway, then deceleration
        easeInOutQuad: t => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t,
        // accelerating from zero velocity
        easeInCubic: t => t * t * t,
        // decelerating to zero velocity
        easeOutCubic: t => (--t) * t * t + 1
    })

    .factory('Animate', function (EASING_FUNCTIONS) {
        function animatableProperties(start, end) {
            return Object.keys(start)
                .filter(prop => prop in start && prop in end)
                .map(prop => ({
                    name: prop,
                    start: start[prop],
                    end: end[prop],
                    difference: end[prop] - [start.prop]
                }));
        }

        function animationChain(element) {
            let config = {
                fn: EASING_FUNCTIONS.easeInQuad,
                progress: 0,
                steps: 100,
                start: {},
                end: {}
            };

            function chain(progress = 0) {
                if (progress < config.steps) {
                    requestAnimationFrame(chain.bind(null, progress + 1));
                }

                let properties = animatableProperties(config.start, config.end);
                let ratio = progress / config.steps;

                properties.map(function (prop) {
                    let value = prop.start + (prop.difference * config.fn(ratio));
                    element[prop.name] = value;
                });
                return chain;
            }

            chain.play = chain;

            chain.between = function (startStyles = {}) {
                config.start = startStyles;
                return chain;
            };

            chain.and = function (endStyles = {}) {
                config.end = endStyles;
                return chain;
            };

            chain.steps = function (steps = 100) {
                config.steps = steps;
                return chain;
            };

            chain.ease = function (fn) {
                if (typeof fn === 'string') {
                    config.fn = EASING_FUNCTIONS[fn];
                } else {
                    config.fn = fn;
                }
                return chain;
            };

            return chain;
        }

        return animationChain;
    });
