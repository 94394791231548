/* CSS */
require('angular-material/angular-material.css');
require('foundation-essentials/css/foundation.css');
require('md-steppers/dist/md-steppers.css');
require('nvd3/build/nv.d3.css');

/* FONTS */
require("../../static/assets/fontawesome-pro-6.1.1-web/css/all.min.css");
require("../../static/assets/font/patient_glyphs.css");
require("../../static/assets/font/other_glyphs.css");
require("../../static/assets/font/supervision.css");
require("../../static/assets/font/chai_logo.css");

/* JS */
require('angular');
require('keycloak-js');
require('angular-ui-router');
require('angular-sanitize');
require('chai-markdown');
require('teljs');
require('angular-resource');
require('ng-file-upload');
require('angular-moment');
require('angular-nvd3');
require('angular-websocket');
require('matchmedia-ng');
require('angular-signature');
require("signature_pad");
require('angular-cookies');
require('angular-material');
require('angular-img-http-src');
require('ng-idle');
require('angular_page_visibility');
require('angular-translate');
require('md-steppers');
