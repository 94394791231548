module.exports = {
    url: '/batchsignoff',
    controller: function ($scope, PatientStore, CountersignStatus, $timeout,
                          supervisionStore, $state, matchmedia, $q, $mdDialog, $window) {
        'ngInject';

        $scope.patients = [];
        $scope.details = {};
        $scope.showDetails = {};
        $scope.allSelected = {value: 0};
        $window.document.title = 'CHAI Countersign';

        var promiseChain = $q.when();

        PatientStore.load()
            .then(Patient => {
                Patient.all(patients => {

                    var status = {};

                    patients.forEach(patient => {
                        status[patient.id] = {};
                        status[patient.id].overall = false;
                        CountersignStatus.update(status[patient.id],
                            patient.approvals,
                            true);

                        if (status[patient.id].overall) {

                            promiseChain = promiseChain.then(() => {
                                return CountersignStatus.update(status[patient.id], patient.approvals).then(() => {

                                    $scope.patients.push(patient);
                                    $scope.showDetails[patient.id] = false;
                                    $scope.details[patient.id] = {
                                        selected: 0,
                                        total: 0,
                                        status: status[patient.id],
                                        values: {repeating: {}, static: {}, notes: {}}
                                    };
                                });

                            });
                        }

                    });
                }, error => {
                });
            })
            .catch(err => {
                console.error('Could not load patients:', err);
            });


        var repeatingSelectedForms = {};
        var staticSelectedCards = {};
        var selectedNotes = {};

        $scope.prepareFormData = function () {
            $scope.confirmCountersign();

            for (let patient in $scope.details) {
                repeatingSelectedForms[patient] = {};
                staticSelectedCards[patient] = {};
                selectedNotes[patient] = [];
                for (let repeatingSelectedForm in $scope.details[patient].values.repeating) {
                    if ($scope.details[patient].values.repeating[repeatingSelectedForm]) {
                        let [formName, repId] = repeatingSelectedForm.split(':');
                        if (!(formName in repeatingSelectedForms[patient])) {
                            repeatingSelectedForms[patient][formName] = [];
                        }
                        repeatingSelectedForms[patient][formName].push(repId);
                    }
                }
                for (let staticSelectedCard in $scope.details[patient].values.static) {
                    if ($scope.details[patient].values.static[staticSelectedCard]) {
                        let [formName, cardName] = staticSelectedCard.split(':');
                        if (!(formName in staticSelectedCards[patient])) {
                            staticSelectedCards[patient][formName] = [];
                        }
                        staticSelectedCards[patient][formName].push(cardName);
                    }
                }
                for (let selectedNote in $scope.details[patient].values.notes) {
                    if ($scope.details[patient].values.notes[selectedNote]) {
                        selectedNotes[patient].push(selectedNote);
                    }
                }
            }
        };

        $scope.countersignForms = function () {
            supervisionStore.batchRepeating().then(selectedForms => {
                selectedForms.save(repeatingSelectedForms, function () {
                    supervisionStore.batchStatic().then(selectedCards => {
                        selectedCards.save(staticSelectedCards, function () {
                            supervisionStore.batchMessage().then(notes => {
                                notes.save(selectedNotes, function () {
                                    $mdDialog.hide();
                                    $state.go('home', {}, {reload: true, inherit: false});
                                });
                            });
                        });
                    });
                });
            });
        };

        $scope.confirmCountersign = function () {
            $mdDialog.show({
                parent: angular.element(document.body),
                locals: {
                    allSelected: $scope.allSelected,
                    countersignForms: $scope.countersignForms
                },
                template: `

<div class='row batch-signoff-confirmation'>
  <div ng-class='["large-4 small-12 columns brilliant-sky", {center: mobile}]'>
    <i style='font-size: 9em' class='icon2-countersign'></i>
  </div>
  <div class='large-8 small-12 columns'>
    <div ng-class='["row batch-signoff-confirmation__heading", {"batch-signoff-confirmation__heading--mobile": mobile}]'>
      <ng-pluralize count="allSelected.value"
         when="{'1': 'Countersigning 1 item',
             'other': 'Countersigning {{ allSelected.value }} items'}">
      </ng-pluralize>
      
    </div>
    <div class='row batch-signoff-confirmation__content'>
      <ng-pluralize count="allSelected.value"
         when="{'1': 'Click OK to confirm that the selected form has been completed correctly',
             'other': 'Click OK to confirm that the selected forms have all been completed correctly'}">
      </ng-pluralize>              
    </div>
    <div ng-class='["row batch-signoff-confirmation__buttons",
                    {"batch-signoff-confirmation__button--mobile": mobile}]'>
      <button class='small-12 large-4 column small batch-signoff-confirmation__button--ok'
              ng-click='countersignForms()'> OK
      </button>
      <button class='small-12 large-4 column end small batch-signoff-confirmation__button--cancel'
              ng-click='closeDialog()'> Cancel
      </button>
    </div>
  </div>
</div>

        `,
                controller: function DialogController($scope, $mdDialog, allSelected,
                                                      countersignForms, matchmedia) {

                    $scope.allSelected = allSelected;
                    $scope.countersignForms = countersignForms;

                    matchmedia.onPhone(function (mql) {
                        $scope.mobile = mql.matches;
                    });

                    $scope.closeDialog = function () {
                        $mdDialog.hide();
                    };
                }

            });

        };

    },
    template: `
    <navigation-bar></navigation-bar>

    <title-bar>
      <center>
        Batch Countersign
      </center>
    </title-bar>

    <main>
      <div class='center'>
        <button class='small countersign-button'
                ng-disabled='allSelected.value == 0'
                ng-click='prepareFormData()'>
          <i class='icon2-countersign'></i> Countersign Forms
        </button>
      </div>


      <div ng-repeat='patient in patients'>
        <div ng-click='showDetails[patient.id] = !showDetails[patient.id]'>
          <div ng-class='["batch-patient", {"batch-patient--closed": !showDetails[patient.id],
                                        "batch-patient--open": showDetails[patient.id]}]'>
            
            <div class="row" style="position: relative">
                <div class="small-12 columns noleftpadding">
                    <patient-preview patient='patient'
                             details='details[patient.id]'
                             type='"batchsignoff"'>
                    </patient-preview>
                </div>
                 <div ng-class='["batch-patient__selection-count",
                           {"batch-patient__selection-count--mobile": phone}]'>
                  <span class='trainee-colored bold'
                        ng-bind='details[patient.id].selected + "/" + details[patient.id].total'>
                  </span>
                </div>
            </div>
            
          </div>
          <div ng-if='!showDetails[patient.id]'
               style='text-align:center; height:0.3em;'>
              <span class="icon1-expand-arrow-down-default batch-down-arrow">
                <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
              </span>
          </div>
        </div>
        <div>
          <forms-overview ng-show='showDetails[patient.id]'
                          details='details[patient.id]'
                          show-details='showDetails'
                          all-selected='allSelected'
                          patient='patient'>
          </forms-overview>
        </div>
      </div>
      <div class='center'>
        <button class='small countersign-button'
                ng-disabled='allSelected.value == 0'
                ng-click='prepareFormData()'>
          <i class='icon2-countersign'></i> Countersign Forms
        </button>
      </div>
    </main>
  `
};
