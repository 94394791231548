module.exports = {
    url: '/import',
    controller: function ($scope, $state, PatientStore, $mdDialog, Utils) {
        'ngInject';

        var vm = this;

        PatientStore.load().then(Patient => {
            $scope.pendingPatients = [];
            Patient.pending({}, function (patients) {
                $scope.pendingPatients = patients;
            }, err => {
            });

            $scope.Utils = Utils;

            $scope.importPatient = function () {
                Patient.import({id: vm.patientIdToImport}, function () {
                    $mdDialog.hide();
                    $state.go('home');
                });
            };


        });


        $scope.confirmImport = function (patient) {

            vm.patientIdToImport = patient.id;
            vm.patientDetails = patient.data.admissions.patient_details;

            $mdDialog.show({
                parent: angular.element(document.body),
                locals: {patientDetails: vm.patientDetails, importPatient: $scope.importPatient},
                template: `
        <div style='font-size: .75em; padding: 1em; width: 32em'>
            <h5>Confirm Import</h5>
             <div class='fill'>
            <div class='patient preview'>

              <glyph
                seed='patientDetails.first_name +
                      patientDetails.last_name'
                gender='patientDetails.gender.value'
                age='Utils.ageCalc(patientDetails.date_of_birth)'
                ews='undefined'
                no-ews="true">
              </glyph>
              <section class='info'>

                <div>
                  <span>
                  {{patientDetails.first_name}}
                  </span>
                  <span>
                  {{patientDetails.last_name}}
                  </span>
                </div>
                <div>
                  <span>Age: </span>
                  <patient-age dob="patientDetails.date_of_birth"/>
                </div>
                <div>
                  <span>ID: </span>
                  <span ng-bind=
                  'patientDetails.hospital_number
                   || "No Hospital number"'>
                  </span>
                </div>

              </section>

              </div>
            </div>
          <center>
            <button ng-click='closeDialog()'>CANCEL</button>
            <button ng-click="importPatient()">OK</button>
          </center>
        </div>

        `,
                controller: function DialogController($scope, $mdDialog, Utils,
                                                      patientDetails, importPatient) {

                    $scope.patientDetails = patientDetails;
                    $scope.importPatient = importPatient;

                    $scope.Utils = Utils;

                    $scope.closeDialog = function () {
                        $mdDialog.hide();
                    };
                }

            });
        };

    },
    controllerAs: 'vm',
    template: `
    <navigation-bar></navigation-bar>
    <title-bar>
      <div class='left'>
      </div>
      <div class='right'>
      </div>
      <center>
        Import or Create Patient
      </center>
    </title-bar>
    <main>
      <div class='panel external'>
        <div class='patient list'>
        <div
            class='panel floating blank' ng-show="!pendingPatients.length">
            <center>No individuals in pending list</center>
          </div>
          <div
            class='newpanel floating blank'
            ng-repeat='patient in pendingPatients'>
            <div class='patient preview'>
              <a ng-click='confirmImport(patient)'>
  <glyph
    no-ews="true"
    seed='patient.data.admissions.patient_details.first_name +
          patient.data.admissions.patient_details.last_name'
    gender='patient.data.admissions.patient_details.gender.value'
    age='Utils.ageCalc(patient.data.admissions.patient_details.date_of_birth)'
    frailty='patient.data.admissions.patient_details.frailty_score'
    ews='undefined'>
  </glyph>
              </a>

          <section class='info'>
          <a ng-click='confirmImport(patient)'>

           <div class='row'>

        <div class='small-12 columns patientname'>
            <span class='name'
      ng-bind="patient.data.admissions.patient_details.first_name || 'New'">
            </span>
            <span class='name'
      ng-bind="patient.data.admissions.patient_details.last_name || 'Patient'">
            </span>
        </div>

      </div>

           <div class='row nowrap'>

            <div class='small-2 large-1 columns'>
              <patient-age
              dob="patient.data.admissions.patient_details.date_of_birth">
              </patient-age>
            </div>


              <div class='small-2 large-1 columns'>
                <span class='dno uppercase'
            ng-bind='patient.data.admissions.patient_details.gender.value
            || "-"'>
                </span>
              </div>

              <div class='small-2 large-1 columns'>
                <span class='dno' ng-bind=
                'patient.data.admissions.patient_details.hospital_number
                 || "-"'>
                </span>
              </div>

              <div class='small-6 large-1 columns text-right'>
                <span class='dno doctor' ng-bind=
'patient.data.admissions.patient_details.preferred_name || "-"'>
                  </span>
              </div>

        </div>

          </a>
          </section>
        </div>
      </div>
    </div>
    </div>


     <div class="button-area">
            <button ui-sref='search'>
            <i class='fas fa-user'></i>
            Search for Individuals not in pending list
          </button>
    </div>
    </main>
  `
};
