module.exports = {
    url: '/search',
    controller: function ($window, $rootScope, $scope) {
        'ngInject';

        $window.document.title = 'CHAI Search';
        $scope.label = $rootScope.patientLabel + ' Search';


    },
    template: `
    <navigation-bar></navigation-bar>

    <title-bar>
      <center>
        {{ label }}
      </center>
    </title-bar>

    <search></search>

  `
};
