module.exports = {
    url: '/signofflogin',
    controller($scope, $rootScope, supervisionStore, $state, signOffInfo,
               matchmedia) {
        'ngInject';

        var body = document.getElementsByTagName('body')[0];
        body.style.background = 'linear-gradient(to right, #56a1b0, #65ccdb)';

        $scope.fields = [
            {
                'type': 'text_input',
                'name': 'username',
                'label': 'Username'
            },
            {
                'type': 'password',
                'name': 'password',
                'label': 'Password'
            }
        ];

        $scope.message = '';

        matchmedia.onPhone(function (mql) {
            $scope.mobile = mql.matches;
        });

        $scope.loginDetails = {};

        $scope.login = function () {

            var data = {
                'username': $scope.loginDetails.username,
                'password': $scope.loginDetails.password
            };

            supervisionStore.load().then(Details => {
                Details.save(data, function (resp) {
                    if (resp.permissions.includes('patient_approve')) {
                        signOffInfo.secret = resp.secret;
                        $rootScope.fullName = resp.fullName;
                        $rootScope.trainee = false;
                        $rootScope.traineeUsername = $rootScope.username;
                        $rootScope.signoff = true;

                        body.style.background = '#f1f2f2';

                        if (signOffInfo.assessment) {
                            $state.go('patient.assessment', {
                                card: signOffInfo.form,
                                id: signOffInfo.patientId
                            });
                        }

                        if (signOffInfo.static) {
                            $state.go('patient.card', {
                                form: signOffInfo.form,
                                card: signOffInfo.card,
                                id: signOffInfo.patientId
                            });
                        }

                        if (signOffInfo.careplan) {
                            $state.go('patient.careplans', {
                                card: signOffInfo.form,
                                id: signOffInfo.patientId
                            });
                        }

                        if (signOffInfo.notes) {
                            $state.go('countersignnote');
                        }
                    } else {
                        $scope.message = 'Not a supervisor login';
                    }
                }, function (resp) {
                    if (resp.status == 401) {
                        $scope.message = 'Wrong username or password';
                    }
                }, err => {
                });
            });
        };

        $scope.back = function () {
            $state.go('patient.dash', {id: signOffInfo.patientId});
            // if (!signOffInfo.notes) {
            //   $state.go('patient.dash', {id: signOffInfo.patientId});
            // }
            // else {
            //   $state.go('home');
            // }
            body.style.background = '#f1f2f2';
            $rootScope.trainee = true;
            $rootScope.signoff = false;
            $rootScope.username = $rootScope.traineeUsername;
            signOffInfo.assessment = false;
            signOffInfo.static = false;
            signOffInfo.careplan = false;
            signOffInfo.notes = false;
        };
    },
    template: `
    <main>
      <div ng-class='["row supervisor-login",
                     {"mobile": mobile}]'>

        <div ng-class='["large-4 small-4 columns large-offset-0 small-offset-4",
                       {"center": mobile}]'>
          <img src='assets/images/chai-logo-blue.svg'>
        </div>

        <div class='large-8 small-12 columns'>

          <div class='heading'>
            Handover to Supervisor
          </div>

          <div class='subtext'>
            Please hand this device over to the
            supervising colleague for approval now
          </div>

          <div class='heading'>
            Supervisor Log In
          </div>

          <div>
            <card-field
               ng-model='loginDetails'
               field='field'
               ng-repeat='field in fields'>
            </card-field>
          </div>

          <div class='row'>
            <button class='login-button large-5 small-5 columns' ng-click='login()'>
              OK
            </button>
            <div class='large-2 small-1 columns'></div>
            <button class='cancel large-5 small-6 columns' ng-click='back()'>
              Countersign Later
            </button>
          </div>

          <div class='row subtext' ng-if='message'>
            {{ message }}
          </div>

        </div>

      </div>
    </main>
  `
};
