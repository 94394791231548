module.exports = {
    url: '/form/:form/:card',
    views: {
        main: {
            controller: function ($scope, $state, Cards) {
                'ngInject';
                $scope.formName = $state.params.form;
                $scope.cardName = $state.params.card;

                Cards
                    .get($scope.formName)
                    .then(form => {
                        var totalGroups = form.groups.length;
                        groupLoop:
                            for (var i = 0; i < totalGroups; i++) {
                                var cards = form.groups[i].cards;
                                for (let card of cards) {
                                    if (card.name == $scope.cardName) {
                                        $scope.groupIndex = i;
                                        break groupLoop;
                                    }
                                }
                            }
                        $state.go('patient.group', {
                            form: $scope.formName,
                            group: $scope.groupIndex,
                            card: $scope.cardName
                        });
                    });
            }
        }
    }
};
