angular.module('login', ['server', 'ui.router', 'responseInterceptor'])

    .service('TokenHandler', function () {
        const tokenName = 'CHAI_AUTH_TOKEN';
        this.get = function () {
            return localStorage[tokenName];
        };

        this.set = function (token) {
            localStorage[tokenName] = token;
        };

        this.hasToken = function () {
            return Boolean(this.get());
        };
    })


    .directive('login', function ($state, Server, ResponseInterceptor, $rootScope,
                                  $location, ChaiWebSocket, FormStore, $transitions,
                                  $urlRouter, signOffInfo, $mdDialog, supportedBrowsers, $mdMedia) {
        return {
            restrict: 'E',
            scope: {
                failure: '&'
            },
            controller($scope, $window) {
                $scope.username = '';
                $scope.password = '';
                $scope.error = ResponseInterceptor.err;
                $scope.syncError = '';
                $scope.isServerAndClientClockInSync = true;

                $scope.$watch(function () {
                    return $mdMedia('gt-sm');
                }, function (big) {
                    $scope.bigScreen = big;
                });

                Server.getTime().then(function (serverTime) {
                    let currentTime = Date.now();
                    let diff = Math.abs(serverTime - currentTime);

                    if (diff > 30000 && diff < 1800000) {
                        $scope.warn = true;
                        $scope.syncError = 'Your device clock is ' + Math.ceil(diff / 60000) + ' minutes different to the server clock. Please be aware that this may affect functionality within CHAI. You should correct your device clock as soon as possible.';
                    }

                    if (diff >= 1800000) {
                        $scope.isServerAndClientClockInSync = false;
                        $scope.syncError = 'Your device clock is over 30 minutes different from the server clock. This can seriously impact functionality within CHAI so login has been disabled.';
                    }
                }).catch(() => {
                })

                const Bowser = require('bowser')
                const browserName = Bowser.getParser(window.navigator.userAgent).parsedResult.browser.name;
                const browserVersion = parseInt(Bowser.getParser(window.navigator.userAgent).parsedResult.browser.version);

                if (Object.keys(supportedBrowsers).includes(browserName) && (browserVersion >= supportedBrowsers[browserName])) {
                    $scope.isSupportedBrowser = true;
                } else {
                    let supportedBrowserVersions = '';
                    Object.keys(supportedBrowsers).forEach(browser => {
                        supportedBrowserVersions += '  \n* ' + browser;
                        supportedBrowserVersions += ' (version ' + supportedBrowsers[browser] + ' and above)';
                    });
                    $scope.error = 'This browser is not supported. Supported browsers are currently:  \n' + supportedBrowserVersions;
                    console.log('Unsupported Browser: ' + browserName + ' version ' + browserVersion);
                }

                $window.document.title = 'CHAI Login';

                function initApp() {
                    moment.locale($rootScope.locale);

                    Server.getConfig().then(config => {
                        $rootScope.serverUrl = Server.createUrl(config);
                        $rootScope.supportSite = config.supportSite;
                        $rootScope.faqSite = config.faqSite;
                        $rootScope.suggestionSite = config.suggestionSite;
                        $rootScope.productName = config.productName;

                        $rootScope.origin = config.origin;
                        $rootScope.systemID = config.systemID;
                        $rootScope.guide = config.guide;
                        if (config.type == 'community') {
                            $rootScope.patientLabel = 'Client';
                            $rootScope.type = 'community';
                        } else {
                            $rootScope.patientLabel = 'Patient';
                            $rootScope.type = 'pro';
                        }
                    });

                    function disableBack(transition) {
                        let from = transition.$from().name;
                        let to = transition.$to().name;

                        if ((from === 'patient.dash') && (to === 'signofflogout')) {
                            $location.path($urlRouter.location);
                            return false;
                        }

                        if ((from === 'patient.dash') && (to === 'signofflogin')) {
                            if (signOffInfo.notes) {
                                return true;
                            }
                            $location.path($urlRouter.location);
                            return false;
                        }

                        if ((from === 'signofflogout') && (to !== 'patient.dash')) {
                            $location.path($urlRouter.location);
                            return false;
                        }

                        if ((to === 'signofflogin') && $rootScope.trainee === false) {
                            $location.path($urlRouter.location);
                            return false;
                        }

                        if ((from === 'signofflogin') && $rootScope.trainee === true && (to !== 'patient.dash')) {
                            $location.path($urlRouter.location);
                            return false;
                        }
                    }

                    if ($rootScope.trainee) {
                        let filteredHook = $transitions.getHooks('onBefore').filter(hook => {
                            return hook.callback.name === disableBack.name && hook.callback.length === disableBack.length;
                        });

                        if (filteredHook.length === 0) {
                            $transitions.onBefore({}, disableBack);
                        }
                    }
                }

                $scope.go = function () {
                    initApp();
                    function redirect() {
                        if ($rootScope.pastUrl) {
                            $location.url($rootScope.pastUrl);
                            $rootScope.pastUrl = undefined;
                        } else {
                            $state.go('home');
                        }
                    }
                    let diff = Math.abs($rootScope.clockSkew || 0 );

                    if (diff > 30 ) {
                        console.warn("The clock is different to the server with a skew of "+ $rootScope.clockSkew + " seconds");
                    }

                    if (diff > 300 && diff < 1800) {

                        var warningAlert = $mdDialog.alert({
                            title: 'Warning',
                            textContent: 'Your device clock is ' + Math.ceil(diff / 60) + ' minutes different to the server clock. Please be aware that this may affect functionality within CHAI. You should correct your device clock as soon as possible.',
                            ok: 'Close'
                        });


                        setTimeout(function (){
                            $mdDialog
                                .show( warningAlert )
                                .finally(function() {
                                    redirect();
                                });
                        },0);

                    }else if (diff >= 1800) {
                        var errorAlert = $mdDialog.alert({
                            title: 'Unable to login to CHAI',
                            textContent: 'Your device clock is over 30 minutes different from the server clock. This can seriously impact functionality within CHAI so login has been disabled. ',
                            ok: 'Close'
                        });

                        $mdDialog
                            .show( errorAlert )
                            .finally(function() {
                                $rootScope.logout();
                            });
                    }else{
                        redirect();
                    }

                    //$state.go('home');

                };

                ResponseInterceptor.err = '';

                $scope.submit = function () {

                    $scope.warn = false;

                    const login = {
                        username: $scope.username,
                        password: $scope.password
                    };

                    $scope.showSpinner();

                    Server.createAuthToken(login)
                        .then($rootScope.initCards)
                        .then(function (){
                            return FormStore.loadForms();
                        })
                        .then(function () {
                            $scope.hideSpinner();
                            $scope.error = '';
                            $scope.syncError = '';

                            Server.getWebSocketUrl().then(url => {
                                ChaiWebSocket.start(url);
                            });

                            $scope.go();
                        })
                        .catch(function (err) {
                            $scope.hideSpinner();
                            $scope.error = err;
                            $scope.failure({$state});
                        });
                };

                $scope.resetError = function () {
                    $scope.error = null;
                };

                $scope.spinnerVisible = false;

                $scope.showSpinner = function () {
                    $scope.spinnerVisible = true;
                };

                $scope.hideSpinner = function () {
                    $scope.spinnerVisible = false;
                };

                $scope.submit();
            },
            template: `
      <main>

        <!-- <div class='login-container'>
        <div layout-gt-sm='row' layout='column'
             layout-align='space-between'>

          <div flex='25' style='text-align: center'>
            <img class='login-container__logo'
                 src='assets/images/chai-logo-blue.svg'>
          </div>

          <form ng-submit='submit()' flex='65' layout='column'>
            <div class='login-container__heading'>Welcome to CHAI<sup>&reg;</sup></div>


            <div>

              <div class='login-container__label'>Username</div>
              <input type='text'
                ng-change='resetError()'
                ng-disabled='!isSupportedBrowser || !isServerAndClientClockInSync'
                ng-model='username'/>

              <div class='login-container__label'>Password</div>
              <input type='password'
                ng-change='resetError()'
                ng-disabled='!isSupportedBrowser || !isServerAndClientClockInSync'
                ng-model='password'/>

            </div>


          <div class='login-container__notify login-container__notify--error'
               ng-if='error && !bigScreen' chai-markdown='error'>
          </div>


            <button class='login-container__submit'
                    ng-if='isSupportedBrowser && isServerAndClientClockInSync'
                    ng-disabled='spinnerVisible'>Login
            </button>

          </form>

        </div>

          <div ng-show='spinnerVisible' class='login-container__spinner'>
            <i class='fas fa-spinner fa-spin'></i>
          </div>

          <div class='login-container__notify login-container__notify--error'
               ng-if='error && bigScreen' chai-markdown='error'>
          </div>

          <div style='margin-top: 1.5em;' ng-class='["login-container__notify",
                          {"login-container__notify--error": !warn,
                          "login-container__notify--warn": warn}]'
               ng-if='syncError' chai-markdown='syncError'>
          </div>

          <div ng-include="'config/disclaimer.html'"></div>
        </div> -->

      </main>
    `
        };
    });
