module.exports = {
    url: '/dash',
    views: {
        main: {
            controller: function ($scope, $rootScope, FormStore,
                                  Utils, $window, PatientAlerts, $translate,
                                  CountersignStatus, PreviousRecordInfo, Pagination, signOffInfo) {
                'ngInject';
                $scope.patient = $scope.$parent.patient;
                $scope.trainee = $rootScope.trainee;
                $rootScope.signoffLater.value = false;

                if ($rootScope.type == 'pro') {
                    $translate('dash_7').then(function (translation) {
                        $scope.dailyAssistanceLabel = translation;
                    });
                    $scope.dailyAssistanceIcon = 'fa-stethoscope';
                    $translate('careplan_7').then(function (translation) {
                        $scope.carePlansLabel = translation;
                    });
                } else {
                    $translate('dash_8').then(function (translation) {
                        $scope.dailyAssistanceLabel = translation;
                    });
                    $scope.dailyAssistanceIcon = 'fa-user-friends';
                    $translate('careplan_8').then(function (translation) {
                        $scope.carePlansLabel = translation;
                    });
                }

                //in case we clicked home after entering a signoff form
                signOffInfo.assessment = false;
                signOffInfo.careplan = false;

                PreviousRecordInfo.time = 0;
                PreviousRecordInfo.readmit = undefined;
                Pagination.reset();

                $translate('header_4').then(function (translation) {
                    $window.document.title = translation;
                });

                $scope.forms = [];
                $scope.obsForm = {};
                $scope.specialStaticForms = [];

                function getSpecialStaticForms(forms) {
                    return forms.filter(form => {
                        if (form.name === 'clinical_obs') {
                            $scope.obsForm = form;
                        }
                        if (form.name === 'admissions') {
                            return true;
                        }
                        if (form.name === 'discharge') {
                            return true;
                        }
                        return false;
                    }).sort(function (a, b) {
                        return a.order > b.order ? 1 : -1;
                    });
                }

                function getObsForm(forms) {
                    var obsForm = {};
                    angular.forEach(forms, function (form) {
                        if (form.name === 'clinical_obs') {
                            obsForm = form;
                        }
                    });
                    return obsForm;
                }

                $scope.alerts = PatientAlerts.getAlerts();
                $scope.countersignStatus = CountersignStatus.getStatus();

                $scope.specialStaticForms = getSpecialStaticForms($rootScope.staticForms);
                $scope.obsForm = getObsForm($rootScope.repeatingForms);
                $scope.forms = $rootScope.staticForms;

                $scope.formatTime = Utils.timeElapsed;

                if ($scope.patient.data.admissions.allergies) {
                    $scope.allergies = $scope.patient.data.admissions.allergies.cards || [];
                    if ($scope.allergies.length > 0) {
                        $scope.anyAllergies = $scope.patient.data.admissions.allergies.cards.some(card => {
                            return card.any_allergies;
                        });
                    }
                }

            },
            template: `
      <div class='patient-dashboard'>

          <div class='row'>

          <div class='small-3 columns patient-dashboard__forms'>

          <div class='row' ng-repeat='form in specialStaticForms'>
            <a ui-sref='patient.form({id: patient.id, name: form.name})'>
                <radial-progress progress-id=form.name>
                  <span>
                    <i md-colors="{color: 'primary-600'}"
                       ng-class='[form.icon, "fa-2x adjustright patient-dashboard__icon"]'>
                    </i>
                    <i class='icon2-unapproved fa-stack-1x trainee-light patient-dashboard__countersign'
                       ng-show='countersignStatus.static[form.name]'>
                    </i>
                  </span>
                </radial-progress>
                  <h6 md-colors="{color: 'primary-900'}">{{form.label}}</h6>
            </a>
          </div>

            <div class='row'>
              <a id="nav-obs" ui-sref='patient.assessment({card: obsForm.name})'>
                  <span class="fa-stack">
                    <i md-colors="{color: 'primary-600'}"
                       ng-class='["fas fa-3x fa-stack-1x patient-dashboard__icon", obsForm.icon,
                                 ]'>
                    </i>
                    <i class='fas fa-bell fa-stack-1x patient-dashboard__alert patient-dashboard__alert--warn'
                       style='bottom:.75em'
                       ng-show='alerts.obsWarn'>
                    </i>
                    <i class='fas icon3-alarm-clock fa-stack-1x patient-dashboard__alert patient-dashboard__alert--overdue'
                       ng-show='alerts.obsOverdue'>
                    </i>
                    <i class='icon2-unapproved fa-stack-1x trainee-light patient-dashboard__countersign'
                       ng-show='countersignStatus.assessment.clinical_obs'>
                    </i>
                  </span>

                <h6>
                  <span md-colors="{color: 'primary-900'}">{{obsForm.label}}</span>
                  <br>
                  <span class="patient-dashboard__obs-time" md-colors="{color: 'primary-400'}"
                        ng-bind='formatTime(patient.data.obs.clinical_obs.last_update)'>
                  </span>
                </h6>
              </a>
            </div>

          <div class='row'>
            <a id="nav-assess" ui-sref='patient.assessment({card: ""})'>
                <span class="fa-stack">
                  <i md-colors="{color: 'primary-600'}"
                     class='fas fa-3x {{ dailyAssistanceIcon }} fa-stack-1x patient-dashboard__icon'>
                  </i>
                  <i class='fas fa-bell fa-stack-1x patient-dashboard__alert patient-dashboard__alert--warn'
                     style='bottom:.75em'
                     ng-show='alerts.assessWarn'></i>
                  <i class='fas icon3-alarm-clock fa-stack-1x patient-dashboard__alert patient-dashboard__alert--overdue'
                     ng-show='alerts.assessOverdue'></i>
                  <i class='icon2-unapproved fa-stack-1x trainee-light patient-dashboard__countersign'
                     ng-show='countersignStatus.assessment.overall'>
                  </i>

                </span>
                <h6 md-colors="{color: 'primary-900'}">{{ dailyAssistanceLabel }}</h6>
              </a>
            </div>

            <div class='row'>
              <a id="nav-careplans" ui-sref='patient.careplans({card: ""})'>
              <span class='fa-stack'>
                <i md-colors="{color: 'primary-600'}"
                   class='fas fa-3x fa-book-medical fa-stack-1x patient-dashboard__icon'>
                </i>
                <i class='fas fa-bell fa-stack-1x patient-dashboard__alert patient-dashboard__alert--warn'
                     style='bottom:.75em'
                     ng-show='alerts.careplansWarn'></i>
                <i class='fas icon3-alarm-clock fa-stack-1x patient-dashboard__alert patient-dashboard__alert--overdue'
                     ng-show='alerts.careplansOverdue'></i>
                <i class='icon2-unapproved fa-stack-1x trainee-light patient-dashboard__countersign'
                   ng-show='countersignStatus.careplans.overall'>
                </i>
              </span>
                <h6 md-colors="{color: 'primary-900'}">{{ carePlansLabel }}</h6>
               </a>
            </div>


          <div class='row'>
            <a ui-sref='patient.card({form: "covid", card: "covid_status"})'>
              <span class='fa-stack'>
                <i md-colors="{color: 'primary-600'}"
                   class='fas fa-3x fa-virus patient-dashboard__icon'>
                </i>
              </span>
                <h6 translate md-colors="{color: 'primary-900'}">dash_5</h6>
            </a>
          </div>


          <div class='row' ng-if='forms.length > 1'>
              <a ui-sref='patient.staticforms'>
                <span class='fa-stack'>
                  <i md-colors="{color: 'primary-600'}"
                     class='fas fa-file-alt fa-3x patient-dashboard__icon'>
                  </i>
                  <i class='icon2-unapproved fa-stack-1x trainee-light patient-dashboard__countersign'
                     ng-show='countersignStatus.static.overall'>
                  </i>
                </span>
                  <h6 translate md-colors="{color: 'primary-900'}">dash_4</h6>
              </a>
          </div>
          
            <div class='row'>
              <a href=https://chaicommunity.com/concerns/ target="_blank">
                <span class='fa-stack'>
                  <i md-colors="{color: 'primary-600'}"
                     class='fas fa-3x fa-exclamation-circle patient-dashboard__icon'>
                  </i>
                </span>
                  <h6 translate md-colors="{color: 'primary-900'}">dash_3</h6>
              </a>
            </div>

          </div>

          <div class='small-9 columns'>

          <div ng-if='anyAllergies' class='row patient-dashboard__panel'>

            <a ui-sref='patient.group({form: "admissions", group: 1, card: "allergies"})'>

            <div class='patient-dashboard__panel-title patient-dashboard__panel-title--allergy'>
              <span class='fas icon3-allergies-active topmargin'>
<span class="path1"></span><span class="path2"></span><span class="path3"></span>
              </span>
              <span translate>dash_2</span>
            </div>

            <div class='patient-dashboard__allergy'
                 ng-repeat='allergy in allergies'
                 ng-if='allergy.any_allergies'>
              <div class='patient-dashboard__allergy__title small-6 columns'>
                <span ng-bind='allergy.allergen'></span>
              </div>
              <div class='patient-dashboard__allergy__symptom small-6 columns'>
                <span ng-bind='allergy.reaction'></span>
              </div>
              <hr ng-if='!$last'>
            </div>
            </a>

          </div>

          <div class='row patient-dashboard__panel'>
            <div md-colors="{background: 'primary-300', color: 'primary-50'}"
                 class='patient-dashboard__panel-title'>
              <i class='fas fa-circle'></i>
              <span translate>dash_1</span>
            </div>

          <div ng-repeat='problem in patient.concerns'
               class='patient-dashboard__concern'>

            <span> {{problem.message}} </span>

          <a ng-if='problem.type === "static"'
             ui-sref='patient.group({form: problem.info.form,
                                     group: problem.info.group,
                                     card: problem.info.card})'>

              <span translate>dash_6</span>
              <i class='fas fa-caret-right'></i>
          </a>

          <a ng-if='problem.type === "repeating"'
             ui-sref='patient.formhistory({name: problem.info.form})'>
            <span> Details </span>
            <i class='fas fa-caret-right'></i>
          </a>

          <a ng-if='problem.type === "careplan"'
             ui-sref='patient.formhistory({name: problem.info.form})'>
            <span> Details </span>
            <i class='fas fa-caret-right'></i>
          </a>

          <hr ng-if='!$last'>

          </div>
          </div>

          <div class='row patient-dashboard__panel'>
            <channel-notes-overview channel='patientId'
                                    username='username'
                                    show-filter='true'
                                    show-note-page-link='true'
                                    show-create-message='true'>
            </channel-notes-overview>
          </div>

          </div>

          </div>

        </div>

      `
        },
        title: {
            template: `
            <span translate>
             header_3
            </span>
    `
        }
    }
};
