angular.module('responseInterceptor', [])

    .service('ResponseInterceptor', function ($injector, $q, $rootScope) {
        var service = this;

        service.request = function (config) {
            let cookies = $injector.get('$cookies');
            let server = $injector.get('Server');
            if (server.__authToken__) {
                if ($rootScope.cookieCheck && server.__authToken__ !== cookies.get('session-token')) {
                    service.err = 'User session has expired or been invalidated';
                    $injector.get('$state').transitionTo('login');
                }
            }
            return config;
        };

        service.responseError = function (rejection) {

            $rootScope.noExceptionHandler = true;

            if (rejection.status == -1) {
                $injector.get('$mdDialog').hide();
                $injector.get('$state').transitionTo('exception', {noConnection: true});
                return $q.reject(rejection);
            } else if (rejection.status == 401) {
                service.err = 'User session has expired or been invalidated';
            } else if (rejection.status == 413) {
                service.err = 'Uploaded file was too large';
            } else if (rejection.config.url !== 'config/config.json' && rejection.config.url !== 'config/disclaimer.html') {
                service.err = 'The server has reported an error';
            }

            if ((rejection.config.url !== 'config/config.json') && !rejection.config.url.endsWith('config/disclaimer.html') &&
                (!rejection.config.url.endsWith('auth/secret'))) {
                $injector.get('ChaiWebSocket').stop();
                $injector.get('$mdDialog').hide();
                $injector.get('$state').transitionTo('login');
            }

            return $q.reject(rejection);

        };

    });
