/**
 * @name media
 * @ngdoc module
 * @description
 * Components handling audio/image/document message/field types.
 */

angular.module('media', [])

    .service('MediaStore', function (Server, $rootScope) {
        this.postMedia = function (mimeType, patientSpecific) {
            var endpoint = patientSpecific ? ('/media/' + $rootScope.patientId) : '/media';
            return Server.createResource('/media', {
                send: {
                    url: endpoint,
                    method: 'POST',
                    transformRequest: [],
                    headers: {
                        'Content-Type': mimeType
                    }
                }
            });
        };

    })
