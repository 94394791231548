let seedrandom = require('seedrandom');

/**
 * @name random
 * @ngdoc module
 * @description
 * A wrapper module for https://github.com/davidbau/seedrandom.
 */
angular.module('random', [])

    /**
     * @name Radnom
     * @ngdoc factory
     * @description
     * A factory which wraps the main random method from the seedrandom
     * library.
     */
    .factory('Random', function () {
        return seedrandom;
    });

