/**
 * @name repeatingcards
 * @ngdoc module
 * @description
 * A collection of fields that make up the cards for admissions forms.
 */

angular.module('repeatingForms', ['fields'])

    .service('RepeatingFormStore', function (Server) {
        this.load = function () {
            return Server.createResource('/patient/:id/repeating', {
                valuesCount: {
                    url: '/patient/:id/assessments/:card',
                    isArray: true
                },
                first: {
                    url: '/patient/:id/repeating/first',
                    isArray: false
                },
                template: {
                    url: '/patient/:id/repeating/:form/new'
                },
                postOld: {
                    method: 'POST',
                    url: '/patient/:id/repeating/:repId'
                },
                post: {
                    method: 'POST',
                    url: '/patient/:id/assessments/:form/:repId'
                },
                updateTS: {
                    method: 'POST',
                    url: '/patient/:id/repeating/:repId/timestamp'
                },
                setDue: {
                    method: 'POST',
                    url: '/patient/:id/assessments/:form/due'
                },
                setRepeat: {
                    method: 'POST',
                    url: '/patient/:id/assessments/:form/repeat'
                }
            });
        };
    })

    .service('CarePlansStore', function (Server) {
        this.load = function () {
            return Server.createResource('/patient/:id/careplans/:form', {
                update: {
                    method: 'PUT',
                    url: '/patient/:id/careplans/:form'
                }
            });
        };
    })

    .service('RepeatingFormActivityService', RepeatingFormActivityService)

    .controller('RepeatingFormController', RepeatingFormController)

    /**
     * heading shows form name, due/overdue colours.
     * form is form definition plus state: { state: completed, date: 123454546 }
     */
    .directive('repeatingFormHeader', function (matchmedia) {
        return {
            restrict: 'E',
            scope: {
                form: '<',
                needsCountersign: '<',
                isSignOff: '<',
                isInactive: '=?',
                onClick: '&'
            },
            link: function (scope, element) {
                const domElement = element[0];

                if (scope.isInactive) {
                    scope.closedIcon = scope.form.iconclosed || 'fas fa-folder';
                }

                matchmedia.onDesktop(function (mql) {
                    scope.phone = !mql.matches;
                });

                scope.formatTime = function (timestamp) {
                    return timestamp !== undefined ?
                        moment(timestamp).fromNow(true) :
                        '-';
                };
            },
            template: `
      <header class='nopadding' ng-click='onClick()'>
        <div ng-class='[{"rep-heading-container": !phone},
                        {"rep-heading-container-phone": phone},
                        row]'>
          <div ng-class='[{"small-8 columns": !phone},
                          {"small-12 columns": phone},
                          "padding-1em"]'>
            <i ng-if='!isInactive' ng-class='[form.icon, "fa-lg"]'></i>
            <i ng-if='isInactive'  ng-class='[closedIcon, "fa-lg"]'></i>

            <span class='repeating-heading'
                  ng-if='isSignOff'
                  ng-bind='"Countersigning: "'></span>
            <span class='repeating-heading' ng-bind='form.label'></span>


            <i ng-if='needsCountersign'
               class='icon2-unapproved trainee-light'>
            </i>
          </div>

         <div ng-if='!phone && !isSignOff'
              class='small-4 columns due padding-halfem'>

            <div class='rep-overdue' ng-if='form.state.state == "overdue"'>
              <span class='va-super'
                    ng-bind='"Overdue by " + formatTime(form.state.date)'>
              </span>
              <i class='fa-2x icon3-alarm-clock'></i>
            </div>

            <div class='rep-warn' ng-if='form.state.state == "warn"'>
              <span class='va-super'
                    ng-bind='"Due in " + formatTime(form.state.date)'>
              <i class='fas fa-2x fa-bell'></i>
            </div>

            <div class='rep-due' ng-if='form.state.state == "due"'>
              <span class='va-sub'
                    ng-bind='"Due in " + formatTime(form.state.date)'>
              </span>
            </div>

            <div class='rep-completed' ng-if='form.state.state == "completed"'>
              <span class='va-sub'
                    ng-bind='"Completed " + formatTime(form.state.date) + " ago"'>
              </span>
            </div>

          </div>
        </div>

      <div ng-if='!isSignOff' class='strip' ng-if='form.state.state'>
        <div class='overdue-strip' ng-if='form.state.state == "overdue"'></div>
        <div class='warn-strip' ng-if='form.state.state == "warn"'></div>
        <div class='due-strip' ng-if='form.state.state == "due"'></div>
        <div class='completed-strip' ng-if='form.state.state == "completed"'></div>
      </div>
    </header>
    `
        };
    })

    /**
     * Use when form not enabled. Just show the header and link to history
     */
    .directive('repeatingFormInactive', function ($rootScope, $state) {
        return {
            restrict: 'E',
            scope: {
                patient: '=',
                form: '='
            },
            link: function (scope) {
                scope.trainee = $rootScope.trainee;

                scope.viewHistory = function () {
                    $rootScope.inactiveCareplan = true;
                    $state.go('patient.formhistory', {
                        id: scope.patient.id,
                        name: scope.form.name
                    });
                };
            },
            template: `
     <div class='card repeating'>
         <repeating-form-header form="form"
                                on-click="viewHistory()"
                                is-sign-off="false"
                                is-inactive="true"
                                needs-countersign="false">
         </repeating-form-header>
     </div>
    `
        };
    })


    /**
     * Use for active forms.
     */
    .directive('repeatingForm', function (Animate, $rootScope) {

        return {
            restrict: 'E',
            scope: {},
            bindToController: {
                patient: '=',
                form: '='
            },
            link: function (scope, element) {

                scope.trainee = $rootScope.trainee;
                const domElement = element[0];

                scope.scrollToMe = function () {
                    const navs = document.getElementsByClassName('global');
                    const titleBar = document.getElementsByClassName('title');

                    let target = domElement.offsetTop;

                    if (navs.length > 0) {
                        const nav = navs[0];
                        target -= nav.offsetHeight;
                    }

                    if (titleBar.length > 0) {
                        const title = titleBar[0];
                        target -= title.offsetHeight;
                    }

                    Animate(document.documentElement)
                        .steps(10)
                        .between({
                            scrollTop: document.body.scrollTop
                        })
                        .and({
                            scrollTop: target
                        })
                        .play();
                };
            },
            controllerAs: 'rfc',
            controller: 'RepeatingFormController',
            template: `
    <div class='card repeating'>
      <repeating-form-header form="rfc.form" on-click="rfc.toggle()"
                             needs-countersign="rfc.needsCountersign"
                             is-sign-off="rfc.isSignOff()">
      </repeating-form-header>

      <div ng-if='!rfc.isSignOff()' class='history' ng-show='rfc.expanded'>
        <a ui-sref='patient.formhistory({name: rfc.form.name})'>
          <i class='fas fa-hourglass-end'></i>
          <span translate>history</span>
        </a>
        <print type='rfc.form.type'
	             name='rfc.form.name'
               patient-id='rfc.patient.id'>
            <i class='fas fa-print'></i>
            <span translate>print</span>
        </print>
      </div>

      <div ng-if='rfc.signoff' class='history signoff' ng-show='rfc.expanded'>
        Form submitted by {{ rfc.username }}
      </div>

      <div ng-if='rfc.signoffLater.value' class='history signoff' ng-show='rfc.expanded'>
        Form submitted by {{ rfc.username }} on {{ rfc.submissionDateTime.date }} at {{ rfc.submissionDateTime.time }}
      </div>


      <div class='instructions' ng-show='rfc.expanded && rfc.form.instructions !== undefined'>
        <label chai-markdown='rfc.form.instructions'></label>
      </div>

      <section ng-if='rfc.expanded'>
        <div ng-repeat='card in rfc.form.cards' ng-hide='rfc.evalDisable(card.disable) == true'>
          <div ng-if="!$first" class='separator-bg'></div>
          <div ng-if="rfc.cardLabels[card.name]" md-colors="{color: 'primary-100'}" class='subhead'  ng-bind="rfc.cardLabels[card.name]"></div>
          <div ng-if="!rfc.cardLabels[card.name]" md-colors="{color: 'primary-100'}" class='subhead' ng-bind="card.label"></div>
          <p chai-markdown='card.instructions'></p>
          <div ng-if='!card.repeatable'>
              <card-field
                 ng-model='rfc.ngModel[card.name]'
                 meta-model='rfc.metaModel[card.name]'
                 field='field'
                 patient='rfc.patient.data'
                 form-name='rfc.form.name'
                 card-name='card.name'
                 ng-repeat='field in card.fields'>
              </card-field>
          </div>
          <div ng-if='card.repeatable'>
            <div class='card' ng-show='!repCard.archive' ng-repeat="repCard in rfc.ngModel[card.name].cards">
               <card-field
                 ng-model='repCard'
                 meta-model='rfc.metaModel[card.name].cards[$parent.$index]'
                 field='field'
                 patient='rfc.patient.data'
                 card-name='card.name'
                 form-name='rfc.form.name'
                 ng-repeat='field in card.fields'>
              </card-field>
              <hr />
            </div>
             <div layout='row'>
              <md-button md-colors="{background: 'primary-600', color: 'primary-50'}"
                         class='md-fab md-primary'
                      aria-label='Remove Form'
                      ng-click='rfc.confirmDeletion(card.name)'
                      ng-show='rfc.repeatsAdded[card.name]>0'>
                <i class='fas fa-trash'></i>
              </md-button>
              <md-button md-colors="{background: 'primary-600', color: 'primary-50'}"
                         class='md-fab md-primary'
                      aria-label='Repeat Form' ng-click='rfc.repeatCard(card.name)'>
                  <i class='fas fa-plus'></i>
              </md-button>
          </div>
        </div>
        </div>
      </section>
      <div ng-if='rfc.expanded' translate>form_1</div>


      <div layout="row" layout-align="center">
      <div ng-if='rfc.canApprove() && !rfc.isSignOff()' ng-show='rfc.expanded' style="text-align: center;">
        <md-button md-colors="{background: 'primary-50', color: 'primary-600'}"
                   ng-disabled='rfc.disableSubmit()'
                   ng-click='rfc.send()'>
                   <span translate>submit</span>
        </md-button>
      </div>

      <div ng-if='rfc.canApprove() && !rfc.isSignOff()' ng-show='rfc.expanded' style="text-align: center;">
        <md-button md-colors="{background: 'primary-50', color: 'primary-600'}"
                   ng-if="!rfc.disableSubmit()"
                   ng-click='rfc.goToDMD()'>
                   <span translate>form_2</span>
        </md-button>
        
        
        <md-button md-colors="{background: 'primary-50', color: 'primary-600'}"
                   ng-if="rfc.disableSubmit()"
                   ng-click='rfc.goToMC()'>
                   <span translate>form_3</span>
        </md-button>
      </div>



       <div ng-if='rfc.canApprove() && rfc.isSignOffNow()' class='row' ng-show='rfc.expanded'>
          <div class='large-6 small-12 columns'>
            <button class='full-width'
                    ng-click='rfc.send()'>
              <i class='icon2-countersign'></i><span> Countersign Form</span>
            </button>
          </div>
          <div class='large-6 small-12 columns'>
            <button class='full-width'
                    ui-sref='signofflogout'>
              <i class='fas fa-times'></i><span> Cancel </span>
            </button>
          </div>
       </div>

      <center ng-if='rfc.canApprove() && rfc.isSignOffLater()' ng-show='rfc.expanded'>
        <button class='tiny'
                ng-click='rfc.send()'>
          <i class='icon2-countersign'></i><span> Countersign Form</span>
        </button>
       </center>


       <div ng-if='!rfc.canApprove()' class='row' ng-show='rfc.expanded'>
          <div class='large-6 small-12 columns'>
            <button class='full-width trainee-colored no-border'
                    ng-disabled='rfc.disableSubmit()'
                    ng-click='rfc.send()'>
              <i class='far fa-handshake'></i><span> Countersign Now</span>
            </button>
          </div>
          <div class='large-6 small-12 columns'>
            <button class='full-width trainee-colored no-border'
                    ng-disabled='rfc.disableSubmit()'
                    ng-click='rfc.send(true)'>
              <i class='fas fa-paper-plane'></i><span> Countersign Later</span>
            </button>
          </div>
       </div>

      </div>

    </div>
    `
        };
    });


/**
 * Provides functions to determine if form data has changed
 * @constructor
 */
function RepeatingFormActivityService(Utils, CachedPatientForms, FormPreviousModels, FormLiveModels,
                                      CurrentValueStore, PatientStore) {
    var cs = this;

    /** has the supplied form been changed since last save */
    cs.formHasUnsavedChanges = function (form, patientId) {
        let oldData = angular.copy(CurrentValueStore[form]);
        let newData = angular.copy(FormLiveModels[form]);
        Utils.removeDerivedKeys(oldData, newData, CachedPatientForms[patientId][form]);
        return !angular.equals(oldData, newData);
    };

    /**
     * Check if data has changed since last check. If it has, send a GET to server to extend timeout.
     * @param activeForms
     */
    cs.checkActivity = function (activeForms, patientId) {
        if (Object.keys(activeForms).length > 0) {
            for (let form in activeForms) {
                let oldData = angular.copy(FormPreviousModels[form]);
                let newData = angular.copy(FormLiveModels[form]);
                Utils.removeDerivedKeys(oldData, newData, CachedPatientForms[patientId][form]);
                let modelChanged = !angular.equals(oldData, newData);
                if (modelChanged) {
                    FormPreviousModels[form] = angular.copy(FormLiveModels[form]);
                    PatientStore.load().then(Patient => {
                        Patient.summary({id: patientId}, function () {
                        }, err => {
                        });
                    });
                    break;
                }
            }
        }
    };

    cs.storeModelState = function (formName, model) {
        CurrentValueStore[formName] = angular.copy(model);
        FormPreviousModels[formName] = angular.copy(model);
        FormLiveModels[formName] = model;
    };
}

/**
 * Handles loading, validating and submitting data
 * @param $rootScope
 * @constructor
 */
function RepeatingFormController($rootScope, expandedEntities, RepeatingFormActivityService, Reject, CountersignStatus,
                                 signOffInfo, $mdDialog, $transitions, $q, expandCards,
                                 Cards, RepeatingFormStore, Actions, PatientAlerts,
                                 $document, $scope, cleverCards, $timeout, $state, $parse,
                                 PastPatientObject, Utils, $mdToast, $translate) {

    var rfc = this;

    /*
      bound from directive:
      rfc.form
      rfc.patient
    */
    rfc.cardLabelsToEval = [];

    this.$onInit = function () {
        rfc.ngModel = {};
        rfc.metaModel = {};
        rfc.expanded = false;
        rfc.cardLabels = {};

        rfc.trainee = $rootScope.trainee;
        rfc.signoff = $rootScope.signoff;
        rfc.signoffLater = $rootScope.signoffLater;
        rfc.username = $rootScope.username;

        Reject.add(rfc.form.name, 'self', 'self');

        rfc.needsCountersign = CountersignStatus.getStatusForForm(rfc.form.type, rfc.form.name);

        if (expandCards[rfc.form.name]) {
            delete expandCards[rfc.form.name];
            rfc.toggle();
        }

        if (signOffInfo.assessment || signOffInfo.careplan) {
            rfc.signoffForm = signOffInfo.form;
            rfc.toggle();
        }

        if (rfc.signoffLater.value) {
            rfc.username = signOffInfo.username;
            rfc.submissionDateTime = signOffInfo.submissionDateTime;
            rfc.toggle();

        }
    };

    rfc.evaluateLabels = function(){
        for (let card of rfc.cardLabelsToEval) {
            const newLabel = rfc.evalPreview(card.name, card.preview);
            if (newLabel && newLabel.length > 0){
                rfc.cardLabels[card.name] = newLabel;
            }
        }
    };

    rfc.evalPreview = function (index, previewExpresison) {
        if (rfc.ngModel) {
            const preview = $parse(previewExpresison);
            return preview(rfc.ngModel[index]);
        }
    };

    /* used in UI to hide stuff */
    rfc.evalDisable = function (expressions) {
        var disable = cleverCards.evalDisable(expressions, rfc.patient.data, rfc.ngModel);
        return disable;
    };

    rfc.disableSubmit = function () {
        return (!Reject.isRepeatingEmpty(rfc.form) || !$rootScope.canSubmitForms);
    };

    rfc.isSignOff = function () {
        return rfc.signoff || rfc.signoffLater.value
    };

    rfc.isSignOffNow = function () {
        return rfc.signoff;
    };

    rfc.isSignOffLater = function () {
        return rfc.signoffLater.value;

    };

    rfc.canApprove = function () {
        //TODO this should be specific patient_approve
        return !$rootScope.trainee;
    };


    var confirmNavigateAway = $mdDialog.confirm()
        .title($translate.instant('form_4'))
        .ariaLabel($translate.instant('form_5'))
        .ok($translate.instant('no'))
        .cancel($translate.instant('yes'));

    function navigateAwayRep(transition) {
        let deferred = $q.defer();
        let toState = transition.to().name;
        let anyModelChanged = false;

        if ((toState === 'signofflogin') || (toState === 'signofflogout')) {
            deferred.resolve(true);
        }
        if (Object.keys(expandedEntities.repeating).length > 0) {
            for (let form in expandedEntities.repeating) {
                if (RepeatingFormActivityService.formHasUnsavedChanges(form, rfc.patient.id)) {
                    anyModelChanged = true;
                    $mdDialog.show(confirmNavigateAway).then(function () {
                        deferred.resolve(false);
                    }, function () {
                        deferred.resolve(true);
                    });
                    break;
                }
            }

            if (!anyModelChanged) {
                deferred.resolve(true);
            }

        } else {
            deferred.resolve(true);
        }
        return deferred.promise;
    }


    function confirmCloseForm(form) {
        let deferred = $q.defer();

        if (RepeatingFormActivityService.formHasUnsavedChanges(form, rfc.patient.id)) {
            $mdDialog.show(confirmNavigateAway).then(function () {
                deferred.resolve(false);
            }, function () {
                deferred.resolve(true);
            });
        } else {
            deferred.resolve(true);
        }
        return deferred.promise;
    }


    var filteredHook = $transitions.getHooks('onBefore').filter(hook => {
        return hook.callback.name === navigateAwayRep.name && hook.callback.length === navigateAwayRep.length;
    });

    if (filteredHook.length === 0) {

        $transitions.onBefore({
            from: 'patient.assessment', to: function (state) {
                return state.name != 'exception' && state.name != 'login' &&
                    (state.name != 'signofflogin') && (state.name != 'signofflogout');
            }
        }, navigateAwayRep);
        $transitions.onBefore({
            from: 'patient.careplans', to: function (state) {
                return state.name != 'exception' && state.name != 'login' &&
                    (state.name != 'signofflogin') && (state.name != 'signofflogout');
            }
        }, navigateAwayRep);

    }

    rfc.toggle = function () {
        if (rfc.isSignOff() && rfc.expanded) {
            return;
        }

        if (!rfc.expanded) {
            expandedEntities.repeating[rfc.form.name] = true;

            Cards.get(rfc.form.name, rfc.patient.id)
                .then(form => {
                    rfc.form.cards = form.cards;
                    rfc.form.instructions = form.instructions;

                    // determine which cards to watch and evaluate labels
                    rfc.cardLabelsToEval = rfc.form.cards ? rfc.form.cards.filter(card => !card.repeatable && card.preview)
                        .map(function (card) {
                                return {
                                    name: card.name,
                                    preview: card.preview
                                };
                            }
                        ) : [];

                    if (rfc.cardLabelsToEval.length > 0) {
                            $scope.$watch('rfc.ngModel', function (_) {
                                rfc.evaluateLabels();
                            }, true);
                    }


                    // create model place for each card
                    rfc.prepareModel();

                    if (rfc.isSignOff() && rfc.form.name == signOffInfo.form) {
                        //use angular extend so we keep the same ngModel object.
                        angular.extend(rfc.ngModel, signOffInfo.formVals);
                        setTimeout($scope.scrollToMe, 0);
                        rfc.expanded = true;
                        $document.ready(function () {
                            RepeatingFormActivityService.storeModelState(rfc.form.name, rfc.ngModel);
                        });

                    } else {
                        RepeatingFormStore.load().then(HistoryData => {
                            HistoryData.template({id: rfc.patient.id, form: rfc.form.name}, function (data) {
                                rfc.setPrefilledValues(data, true);
                            }, err => {
                            });
                        });
                    }
                });
        } else {
            confirmCloseForm(rfc.form.name).then(function (result) {
                if (result) {
                    delete expandedEntities.repeating[rfc.form.name];
                    rfc.expanded = false;
                }
            });
        }
    };

    /**
     * make an empty object for each card
     */
    rfc.prepareModel = function () {

        Reject.add(rfc.form.name, 'self', 'self');

        rfc.ngModel = {};
        rfc.metaModel = {};
        rfc.repeatsAdded = {};

        for (var cardIndex in rfc.form.cards) {
            if (rfc.form.cards[cardIndex].repeatable) {
                rfc.ngModel[rfc.form.cards[cardIndex].name] = {cards: []};
                rfc.metaModel[rfc.form.cards[cardIndex].name] = {cards: []};
                rfc.repeatsAdded[rfc.form.cards[cardIndex].name] = 0;
            } else {
                rfc.ngModel[rfc.form.cards[cardIndex].name] = {};
                rfc.metaModel[rfc.form.cards[cardIndex].name] = {};
            }
        }

        // this means the values from other cards in the form will be available to derived calcs, and to PEW calc.
        if (rfc.isSignOffLater()) {
            PastPatientObject.value[rfc.form.name] = rfc.ngModel;
        } else {
            rfc.patient.data[rfc.form.name] = rfc.ngModel;
        }

    };

    /**
     * This is used as a callback when we get the inherited data
     */
    rfc.setPrefilledValues = function (inheritedData, expandCard) {
        let deferred = $q.defer();

        //we can safely copy over whole cards from backend
        for (var cardKey in inheritedData[rfc.form.name]) {
            rfc.ngModel[cardKey] = inheritedData[rfc.form.name][cardKey];
        }

        for (var i in rfc.form.cards) {
            var card = rfc.form.cards[i];
            var cardKey = card.name;
            if (card.repeatable && rfc.ngModel[cardKey] !== undefined &&
                rfc.ngModel[cardKey].hasOwnProperty('cards')) {
                for (var rep in rfc.ngModel[cardKey].cards) {
                    if (cleverCards.evalDisable(card.archive, rfc.ngModel[cardKey].cards[rep])) {
                        rfc.ngModel[cardKey].cards[rep].archive = true;
                    }
                }
            }
        }

        setTimeout($scope.scrollToMe, 0);
        if (expandCard) {
            rfc.expanded = true;
        }
        $document.ready(function () {
            RepeatingFormActivityService.storeModelState(rfc.form.name, rfc.ngModel);
            deferred.resolve(true);
        });
        return deferred.promise;

    };

    rfc.send = function (countersignLater) {

        let deferred = $q.defer();

        $mdDialog.show({
            parent: angular.element(document.body),
            template: `
        <md-dialog aria-label='Progress Indicator' class="progress-indicator">
          <div layout="row" layout-sm="column" layout-align="center center">
            <md-progress-circular md-mode="indeterminate">
            </md-progress-circular>
          </div>
        </md-dialog>
      `
        });

        let formData = angular.copy(rfc.ngModel);

        rfc.form.cards.forEach(cardDef => {
            if (cardDef.repeatable) {
                rfc.ngModel[cardDef.name].cards.forEach((cardModel, index) => {
                    cardDef.fields.forEach(fieldDef => {
                        if (fieldDef.type == 'section') {
                            delete rfc.metaModel[cardDef.name].cards[index][fieldDef.name];
                        }
                        if (rfc.ngModel[cardDef.name].cards[index][fieldDef.name] != undefined) {
                            if ('warning' in rfc.metaModel[cardDef.name].cards[index][fieldDef.name]) {
                                delete rfc.metaModel[cardDef.name].cards[index][fieldDef.name].warning.css;
                                delete rfc.metaModel[cardDef.name].cards[index][fieldDef.name].warning.icon;
                            }
                            Utils.addFieldTextToMeta(rfc.ngModel[cardDef.name].cards[index][fieldDef.name],
                                rfc.metaModel[cardDef.name].cards[index][fieldDef.name],
                                fieldDef);
                        }
                    });
                });
            } else {
                cardDef.fields.forEach(fieldDef => {

                    if (fieldDef.type == 'section') {
                        delete rfc.metaModel[cardDef.name][fieldDef.name];
                    }
                    if (rfc.ngModel[cardDef.name][fieldDef.name] != undefined) {
                        if ('warning' in rfc.metaModel[cardDef.name][fieldDef.name]) {
                            delete rfc.metaModel[cardDef.name][fieldDef.name].warning.css;
                            delete rfc.metaModel[cardDef.name][fieldDef.name].warning.icon;
                        }
                        Utils.addFieldTextToMeta(rfc.ngModel[cardDef.name][fieldDef.name],
                            rfc.metaModel[cardDef.name][fieldDef.name],
                            fieldDef);
                    }
                });
            }
        });

        Object.keys(formData).forEach(cardName => {
            if ('cards' in formData[cardName]) {
                formData[cardName].cards.forEach((cardData, index) => {
                    cardData.meta = rfc.metaModel[cardName].cards[index];
                });
            } else {
                formData[cardName].meta = rfc.metaModel[cardName];
            }
        });

        var params = {id: rfc.patient.id, form: rfc.form.name};

        if (rfc.signoff) {
            //auth with supervisor token
            params.secret = signOffInfo.secret;
            params.repId = signOffInfo.repId;
        }

        if (rfc.signoffLater.value) {
            //pass original id.
            params.repId = signOffInfo.repId;
        }

        RepeatingFormStore.load().then(RepeatingData => {
            RepeatingData.post(params, formData, function (resp) {

                //copy data to be used immediately in sign off now.
                if (rfc.trainee && !countersignLater) {
                    rfc.formVals = angular.copy(rfc.ngModel);
                }

                let specifics = {};
                specifics.type = rfc.form.type;
                specifics.info = {
                    form: rfc.form.name,
                    formLabel: rfc.form.label
                };

                rfc.form.cards.forEach(card => {
                    card.fields.forEach(field => {
                        if (field.hasOwnProperty('action')) {
                            specifics.info.card = card.name;
                            specifics.info.cardLabel = card.label;
                            if (rfc.signoffLater.value || rfc.signoff) {
                                specifics.countersigned = true;
                            }
                            Actions.perform(field, specifics, rfc.patient.data, rfc.ngModel[card.name]);
                        }
                    });
                });


                $mdDialog.hide();
                $mdToast.show({
                    template: '<md-toast>Submitted</md-toast>',
                    hideDelay: 2000,
                    position: 'bottom center'
                });
                PatientAlerts.refresh(resp.alerts);
                CountersignStatus.refresh(resp.approvals);

                /** update the state for this form */
                if (resp.alerts && resp.alerts.assessment && resp.alerts.assessment[rfc.form.name]) {
                    PatientAlerts.setRepFormState(resp.alerts.assessment[rfc.form.name], rfc.form.state);
                }

                if (resp.alerts && resp.alerts.careplans && resp.alerts.careplans[rfc.form.name]) {
                    PatientAlerts.setRepFormState(resp.alerts.careplans[rfc.form.name], rfc.form.state);
                }

                rfc.patient.last_update = resp.last_update;
                rfc.patient.data.ews_map = resp.data.ews_map;

                if (rfc.trainee && !countersignLater) {
                    //trainee submit with sign off now, so go straight to supervisor login
                    $rootScope.signoff = true;
                    signOffInfo.patientId = rfc.patient.id;
                    signOffInfo.form = rfc.form.name;
                    signOffInfo.formVals = rfc.formVals;
                    signOffInfo.repId = resp.last_update.repeating_id;
                    if (rfc.form.type === 'care-plan') {
                        signOffInfo.careplan = true;
                        signOffInfo.assessment = false;
                        signOffInfo.static = false;
                    } else {
                        signOffInfo.assessment = true;
                        signOffInfo.careplan = false;
                        signOffInfo.static = false;
                    }
                    $state.go('signofflogin');

                } else if (rfc.signoff) {
                    //go to supervisor logout
                    $state.go('signofflogout');

                } else if ($rootScope.signoffLater.value) {

                    //just approved, go back to history
                    delete expandedEntities.repeating[rfc.form.name];
                    $rootScope.signoffLater.value = false;
                    signOffInfo.assessment = false;
                    signOffInfo.careplan = false;
                    $state.go('patient.formhistory', {id: rfc.patient.id, name: rfc.form.name});

                } else {
                    //this was a normal submission, prepare for another!
                    delete expandedEntities.repeating[rfc.form.name];
                    rfc.expanded = false;
                    rfc.prepareModel();

                    RepeatingFormStore.load().then(HistoryData => {
                        HistoryData.template({id: rfc.patient.id, form: rfc.form.name}, function (data) {
                            rfc.setPrefilledValues(data, false).then(_ => {
                                deferred.resolve(true);
                            });
                        });
                    });
                }

            }, err => {
            });
        });
        return deferred.promise;
    };

    rfc.goToDMD = function() {
        rfc.send().then(_ => {
            $state.go('patient.managecodes', {});
        });
    };

    rfc.goToMC = function() {
        $state.go('patient.managecodes', {});
    };

    rfc.trashCard = function (cardName) {
        if (rfc.repeatsAdded[cardName] > 0) {
            rfc.repeatsAdded[cardName] = rfc.repeatsAdded[cardName] - 1;
            rfc.ngModel[cardName].cards.pop();
            rfc.metaModel[cardName].cards.pop();
        }
    };

    rfc.repeatCard = function (cardName) {
        rfc.repeatsAdded[cardName] = rfc.repeatsAdded[cardName] + 1;
        rfc.ngModel[cardName].cards.push({});
        rfc.metaModel[cardName].cards.push({});
    };

    rfc.confirmDeletion = function (cardName) {
        $mdDialog.show({
            parent: angular.element(document.body),
            locals: {cardName: cardName, trashCard: rfc.trashCard},
            template: `
          <md-dialog aria-label='Card Remove Confirmation'>
            <div layout-align="center center" layout-margin layout-padding>
              <h4>Are you sure you want to delete?</h4>
              <div layout='row' layout-align='end' layout-margin>
                <md-button md-colors="{background: 'primary-600', color: 'primary-50'}"
                        ng-click='removeCard()'>
                  <i style='vertical-align: middle' class='fas fa-check fa-2x'></i>
                </md-button>
                <md-button md-colors="{background: 'primary-600', color: 'primary-50'}"
                           ng-click='closeDialog()'>
                  <i style='vertical-align: middle' class='fas fa-times fa-2x'></i>
                </md-button>
              </div>
            </div>
          </md-dialog>
        `,
            controller: function DialogController($scope, $mdDialog, trashCard) {

                $scope.cardName = cardName;
                $scope.trashCard = trashCard;

                $scope.closeDialog = function () {
                    $mdDialog.hide();
                };

                $scope.removeCard = function () {
                    $scope.trashCard($scope.cardName);
                    $scope.closeDialog();
                };

            }
        });

    };


}
