/**
 * @name patient
 * @ngdoc module
 * @description
 * A collection of components and services for dealing with patient
 * oriented services.
 */
angular.module('patient', [])

    /**
     * @name PatientStore
     * @ngdoc service
     * @description
     * An intermediate service to handle caching and loading of patients.
     */
    .service('PatientStore', function ($http, $q, Server) {
        this.load = function () {
            return Server.createResource('/patient/:id', {
                update: {
                    method: 'PUT',
                    url: '/patient/:id'
                },
                all: {
                    method: 'GET',
                    url: '/patients',
                    isArray: true
                },
                summary: {
                    method: 'GET',
                    url: '/patient/:id/summary'
                },
                pending: {
                    method: 'GET',
                    url: '/patients/pending',
                    isArray: true
                },
                import: {
                    method: 'POST',
                    url: '/patient/:id'
                },
                history: {
                    method: 'GET',
                    url: '/history/:id'
                },
                readmit: {
                    method: 'POST',
                    url: '/patient/:id/readmit'
                }

            });
        };
    })

    .service('PatientDecorator', function (Utils, CurrentValueStore, $rootScope, signOffInfo
    ) {

        this.addCardFunctions = function (patient) {

            let EWS = require('ews-calculations');

            patient.data.current_date = function () {
                return new Date();
            };

            patient.data.submitted_date = function () {

                if ($rootScope.signoffLater.value) {
                    let pastDate = moment(signOffInfo.submissionDateTime.date + '.' + signOffInfo.submissionDateTime.time,
                        'DD/MM/YY.HH:mm');
                    return pastDate._d;
                } else {
                    return moment().format('DD/MM/YYYY');
                }
            };

            patient.data.value_changed = function (form, card, field, field_value) {
                return !angular.equals(CurrentValueStore[form][card][field], field_value);
            };

            patient.data.isNaN = function (value) {
                return isNaN(parseFloat(value));
            };

            patient.data.date_diff = function (start_date, end_date, as) {
                var endDate = moment(end_date).startOf('day');
                var startDate = moment(start_date, 'DD/MM/YYYY');

                if (as == 'as_months') {
                    //total as months (e.g. 23)
                    return endDate.diff(startDate, 'months');
                } else if (as == 'as_days') {
                    //total days (e.g. 576)
                    return endDate.diff(startDate, 'days');
                } else if (as == 'years') {
                    //whole years
                    return endDate.diff(startDate, 'years');
                } else if (as == 'months') {
                    //whole months after years (<12)
                    startDate.add(endDate.diff(startDate, 'years'), 'years');
                    return endDate.diff(startDate, 'months');
                } else {
                    //whole days after years and months (based on actual dates)
                    startDate.add(endDate.diff(startDate, 'years'), 'years');
                    startDate.add(endDate.diff(startDate, 'months'), 'months');
                    return endDate.diff(startDate, 'days');
                }
            };

            patient.data.calc_age = function (start_date) {
                return Utils.ageCalc(start_date);
            };

            patient.data.age_calc = patient.data.calc_age;

            patient.data.calc_obs = function () {
                //this works because repeating forms data is added to the patient object
                if (this.obs === undefined) {
                    this.obs = {'clinical_obs': {}};
                }

                //we used to merge with the previous set of obs like this:
                //var obsData = angular.merge({}, this.obs.clinical_obs, this.clinical_obs.clinical_obs);
                return this.clinical_obs.clinical_obs;
            };

            patient.data.calc_ews_date = function () {
                return moment(Date.now()).format('DD/MM/YY, H:m')
            };

            patient.data.calc_pews = EWS.calc_pews
            patient.data.calc_pews_score = EWS.calc_pews_score
            patient.data.calc_pews_age_range = EWS.calc_pews_age_range
            patient.data.calc_pews_glyph_border_color = EWS.calc_pews_glyph_border_color;

            patient.data.calc_coast = EWS.calc_coast;
            patient.data.calc_coast_score = EWS.calc_coast_score;
            patient.data.calc_coast_age_range = EWS.calc_coast_age_range;
            patient.data.calc_coast_glyph_border_color = EWS.calc_coast_glyph_border_color;

            patient.data.calc_news = EWS.calc_news;
            patient.data.calc_news_score = EWS.calc_news_score;
            patient.data.calc_news_age_range = EWS.calc_news_age_range;
            patient.data.calc_news_glyph_border_color = EWS.calc_news_glyph_border_color;

            patient.data.Math = Math;

            patient.data.sumOverCards = function (card, fieldName) {
                return card.cards.reduce(function (prevVal, elem) {
                    if (angular.isNumber(elem[fieldName])) {
                        return prevVal + elem[fieldName];
                    } else {
                        return prevVal;
                    }
                }, 0);
            };

            return patient;
        };

    })



    /**
     * @name patientAge
     * @ngdoc directive
     * @restrict E
     * @description
     * Provides the a formatted age from a date
     */
    .directive('patientAge', function ($translate) {
        return {
            restrict: 'E',
            scope: {
                dob: '='
            },
            link: function (scope) {

                scope.updateFormattedAge = function () {

                    var age = moment(new Date()).diff(moment(scope.dob, 'DD/MM/YYYY'));
                    var ageMonths = moment.duration(age).asMonths();

                    if (ageMonths < 1 && ageMonths > 0) {
                        scope.formattedAge = (Math.floor(moment.duration(age).asDays()) + $translate.instant('d'));
                    } else if (ageMonths < 4 && ageMonths >= 1) {
                        scope.formattedAge = (Math.floor(moment.duration(age).asWeeks()) + $translate.instant('w'));
                    } else if (ageMonths < 23 && ageMonths >= 4) {
                        scope.formattedAge = (Math.floor(ageMonths) + 'm');
                    } else if (ageMonths >= 23) {
                        scope.formattedAge = (Math.floor(moment.duration(age).asYears()) + $translate.instant('y'));
                    } else {
                        scope.formattedAge = '0';
                    }
                };

                scope.updateFormattedAge();
                scope.$watch('dob', scope.updateFormattedAge);

            },
            template: `
      <span class='dno' ng-bind='formattedAge'></span>
    `
        };
    })


    /**
     * @name patientPreview
     * @ngdoc directive
     * @restrict E
     * @description
     * Provides the user with some heads up information, used for
     * identifying a patient, as well a providing a visual indicator
     * of their EWS score.
     */
    .directive('patientPreview', function ($rootScope, PatientAlerts,
                                           Utils, CountersignStatus, matchmedia,
                                           PatientStore, $mdDialog, $state, Readmissions) {
        return {
            restrict: 'E',
            scope: {
                patient: '=',
                type: '=',
                details: '='
            },
            link: function (scope) {


                scope.admissionReasonLabelName = $rootScope.admissionReasonLabelName;
                scope.trainee = $rootScope.trainee;
                scope.signoff = $rootScope.signoff;
                scope.isHospitalNumberPresent = $rootScope.isHospitalNumberPresent;
                scope.glyphSize = undefined;

                if ($rootScope.type == 'pro') {
                    scope.obsIcon = 'fa-heartbeat';
                    scope.assessmentIcon = 'fa-stethoscope';
                } else {
                    scope.obsIcon = 'fa-hand-holding-heart';
                    scope.assessmentIcon = 'fa-user-friends';
                }


                if (scope.type === 'batchsignoff') {
                    scope.glyphSize = 'small';
                }

                scope.readmitDialog = function () {
                    if (scope.patient.state !== 'READMIT') {
                        $state.go('patient.dash', {id: scope.patient.id}, {reload: true});

                    } else {
                        Readmissions.readmit(scope.patient.id);
                    }
                };

                matchmedia.onPhone(function (mql) {
                    scope.phone = mql.matches;
                    if (scope.type === 'batchsignoff') {
                        scope.glyphSize = scope.phone ? 'very-small' : 'small';
                    } else {
                        scope.glyphSize = scope.phone ? 'very-small' : undefined;
                    }
                });

                scope.updateEWS = function () {

                    scope.age = Utils.ageCalc(scope.patient.data.admissions.patient_details.date_of_birth);
                    scope.ews_map = scope.patient.data.ews_map;
                    scope.ews_map = scope.ews_map ? scope.ews_map : {};
                    scope.ews = scope.ews_map.ews_score;
                    scope.ews = scope.ews ? scope.ews : '-';
                };

                scope.formatTime = function (timestamp) {
                    return timestamp !== undefined ?
                        moment(timestamp).fromNow() :
                        '-';
                };

                scope.alerts = PatientAlerts.getAlerts();

                scope.patientListAlerts = {
                    assessWarn: false,
                    assessOverdue: false,
                    obsWarn: false,
                    obsOverdue: false,
                    taskWarn: false,
                    taskOverdue: false,
                    careplansWarn: false,
                    careplansOverdue: false
                };

                PatientAlerts.update(scope.patient.alerts, scope.patientListAlerts);

                scope.patientListCountersignStatus = {
                    overall: false,
                    static: {},
                    assessment: {},
                    careplans: {},
                    notes: {}
                };

                scope.hasCarePlans = false;

                CountersignStatus.update(scope.patientListCountersignStatus,
                    scope.patient.approvals,
                    true);

                scope.countersignStatus = CountersignStatus.getStatus();

                scope.anyAllergies = false;
                scope.covidStatus = false;
                scope.expandReason = false;
                scope.hasCarePlans = false;
                scope.covidStatusLabel = 'Form not filled yet';

                scope.updatePreview = function () {
                    if (scope.patient.data.admissions.allergies &&
                        scope.patient.data.admissions.allergies.cards) {
                        if (scope.patient.data.admissions.allergies.cards.length > 0) {
                            scope.anyAllergies = scope.patient.data.admissions.allergies.cards.some(card => {
                                return card.any_allergies;
                            });
                        } else {
                            scope.anyAllergies = false;
                        }
                    }

                    for (var careplan in scope.patient.careplans) {
if (scope.patient.careplans.hasOwnProperty(careplan) && scope.patient.careplans[careplan] === true) {
    scope.hasCarePlans = true;
}
                    }

                    if (scope.patient.data.covid) {
                        if (scope.patient.data.covid.covid_status) {
                            if (scope.patient.data.covid.covid_status.covid_status) {
                                scope.covidStatus = scope.patient.data.covid.covid_status.covid_status.value;
                                scope.covidStatusLabel = scope.patient.data.covid.covid_status.corona_status_text;
                            }
                        }
                    }
                };

                scope.updatePreview();

                // the final argument creates a equality watcher, which will
                // track object properties, rather than just references
                scope.$watch('patient.data.admissions',
                    scope.updateEWS, true);

                scope.$watch('patient.data.ews_map',
                    scope.updateEWS, true);

                scope.$watch('patient.data.covid',
                    scope.updatePreview, true);

                scope.unreadStatus = $rootScope.unreadStatus;
            },
            template: `

<div
     ng-class='["patient preview", {"large": type == "large"},{"mobile": phone},
              {"no-bottom-padding": type == "search",
               "batch-glyph batch-glyph--mobile": (type == "batchsignoff") && phone,
               "batch-glyph": (type == "batchsignoff") && !phone}]'>

    <!-- patient list -->
    <a class="clickable-glyph"
       ng-style='{"color": ews_map.ews_colour, "border-color": ews_map.ews_colour}'
       ng-if="type === 'normal'"
       ng-click='readmitDialog()'
       ui-sref-opts='{reload: true}'>
        <glyph
            seed='patient.data.admissions.patient_details.first_name +
            patient.data.admissions.patient_details.last_name'
            gender='patient.data.admissions.patient_details.gender.value'
            age='age'
            frailty='patient.data.admissions.patient_details.frailty_score'
            profile='patient.data.admissions.patient_details.profile_type'
            ews='ews'
            ews-map='ews_map'
            size='glyphSize'>
        </glyph>
    </a>

    <!-- dash -->
    <a class="clickable-glyph"
       ng-if="type === 'large'"
       ng-style='{"color": ews_map.ews_colour, "border-color": ews_map.ews_colour}'
       ui-sref='patient.dash({ id: patient.id })'
       ui-sref-opts='{reload: true}'>
        <glyph
            seed='patient.data.admissions.patient_details.first_name +
            patient.data.admissions.patient_details.last_name'
            gender='patient.data.admissions.patient_details.gender.value'
            age='age'
            frailty='patient.data.admissions.patient_details.frailty_score'
            profile='patient.data.admissions.patient_details.profile_type'
            ews='ews'
            ews-map='ews_map'
            size='glyphSize'>
        </glyph>
    </a>

    <!-- search, batch sign off-->
    <glyph ng-if="type !== 'large' && type !== 'normal'"
           seed='patient.data.admissions.patient_details.first_name +
           patient.data.admissions.patient_details.last_name'
           gender='patient.data.admissions.patient_details.gender.value'
           age='age'
           frailty='patient.data.admissions.patient_details.frailty_score'
           profile='patient.data.admissions.patient_details.profile_type'
           ews='ews'
           ews-map='ews_map'
           size='glyphSize'>
    </glyph>

    <section class='search-info' ng-if="type == 'search' || type == 'batchsignoff'">
        <!-- SEARCH RESULTS -->
       <div class='row'>
            <div class='small-12 columns patientname'>
                    <span class='name'
                          ng-bind="patient.data.admissions.patient_details.first_name || 'New'">
                    </span>
                <span class='name'
                      ng-bind="patient.data.admissions.patient_details.last_name || 'Patient'">
                    </span>
            </div>
        </div>

        <div class='row nowrap'>
            <div class="small-12 columns">

                <div ng-class='["dno-row",
                     {"hno-height": !patient.data.admissions.patient_details.preferred_name}]'>
                  <span class='dno doctor'
                        ng-show='patient.data.admissions.patient_details.preferred_name'
                        ng-bind='"(" + patient.data.admissions.patient_details.preferred_name + ")"'>
                  </span>
                </div>

                <div class="dno-row">
                    <span class='dno' ng-bind='"DOB: " + patient.data.admissions.patient_details.date_of_birth'> </span>
                    <patient-age dob="patient.data.admissions.patient_details.date_of_birth">
                    </patient-age>
                    <span class='dno uppercase'
                      ng-bind='patient.data.admissions.patient_details.gender.value == "other" ?
                             "" :
                             patient.data.admissions.patient_details.gender.value'>
                          </span>
                </div>


                <div ng-if="type == 'search'"
                     ng-class='["dno-row", {"hno-height": !ews_map.ews_name}]'>

                      <i ng-if='ews_map.ews_description' style='position: relative; top: .1em'
                         class='fas fa-tag' ng-style='{"color": ews_map.ews_colour}'>
                      </i>
                      <span class='dno' ng-if='ews_map.ews_description'
                            ng-bind='ews_map.ews_description'>
                      </span>

                  <span class='dno' ng-if='!ews_map.ews_description'
                        ng-bind='ews_map.ews_name'>
                  </span>
                </div>

                <div class="dno-row" ng-if="patient.data.admissions.patient_details.hospital_number">
                    <span class='dno'
                        ng-bind='patient.data.admissions.patient_details.hospital_number'>
                    </span>
                </div>


             <div class="dno-row">
<span translate class="updated">patient_1</span>
<span class='updated' ng-bind='(patient.data.admissions.patient_details.first_preferred_language || "-")'></span>
            </div>


                <div class="dno-row">
                    <span translate class='updated italicize'>updated</span>
                    <span md-colors="{color: 'primary-300'}"
                          class='updated time italicize'
                          ng-bind="formatTime(patient.last_update.timestamp)">
                      </span>
                </div>

            </div>
        </div>

    </section>

<section class='info' ng-if="type == 'normal'">
    <!-- PATIENT LIST -->
    <a ng-click='readmitDialog()'>

        <div class='row'>
            <div class='small-12 columns patientname'>
                    <span class='name'
                          ng-bind="patient.data.admissions.patient_details.first_name || 'New'">
                    </span>
                <span class='name'
                      ng-bind="patient.data.admissions.patient_details.last_name || 'Client'">
                    </span>
            </div>
        </div>

        <div class='row nowrap'>
            <div class="small-6 columns">

                <div ng-class='["dno-row", {"hno-height": !patient.data.admissions.patient_details.preferred_name}]'>
                  <span class='dno doctor'
                        ng-show='patient.data.admissions.patient_details.preferred_name'
                        ng-bind='"(" + patient.data.admissions.patient_details.preferred_name + ")"'>
                  </span>
                </div>

                <div class="dno-row">
                    <span class='dno' ng-bind='"DOB: " + patient.data.admissions.patient_details.date_of_birth'> </span>
                    <patient-age dob="patient.data.admissions.patient_details.date_of_birth">
                    </patient-age>
                    <span class='dno uppercase'
                      ng-bind='patient.data.admissions.patient_details.gender.value == "other" ?
                             "" :
                             patient.data.admissions.patient_details.gender.value'>
         </span>
                </div>

                <div ng-class='["dno-row", {"hno-height": !ews_map.ews_name}]'>

                  <i ng-if='ews_map.ews_description' style='position: relative; top: .1em'
                     class='fas fa-tag' ng-style='{"color": ews_map.ews_colour}'>
                  </i>
                  <span class='dno' ng-if='ews_map.ews_description'
                        ng-bind='ews_map.ews_description'>
                  </span>

                  <span class='dno' ng-if='!ews_map.ews_description'
                        ng-bind='ews_map.ews_name'>
                  </span>
                </div>

                <div class="dno-row">
                    <span translate class='updated italicize'>updated</span>
                    <span md-colors="{color: 'primary-300'}"
                          class='updated time italicize'
                          ng-bind="formatTime(patient.last_update.timestamp)">
                    </span>
                </div>

            </div>


            <div class="small-6 columns">
                <div class='row'>
                    <div class='small-12 columns right end' style="padding-right: 1.5em">

                    <span class="dash-icon" style='padding-left: .5em'>
    <i ng-class='[{"far fa-envelope-open fa-lg silver-grey": unreadStatus[patient.id] != true},
                  {"fas fa-envelope fa-lg": unreadStatus[patient.id] == true}]'></i>
                    </span>

                     <span class="dash-icon" style='padding-left: .5em'>
                          <span ng-class="['fas fa-lg',
                          {'icon3-allergies-active': anyAllergies},
                          {'icon3-allergies-inactive': !anyAllergies}]">
              <span class="path1"></span><span class="path2"></span><span class="path3"></span>
                          </span>
                     </span>

                      <span style='padding-left: .5em' class="dash-icon">
                          <span ng-class='["fa-lg",
                           {"fas fa-virus silver-grey": !covidStatus},
                           {"fas fa-virus overdue": covidStatus == "positive"},
                           {"fas fa-handshake": covidStatus == "negative"},
                           {"fas fa-head-side-cough warn": covidStatus == "symptoms"},
                           {"fas fa-people-arrows": covidStatus == "close_contact"},
                           {"fas fa-people-pants": covidStatus == "contact"},
                           {"far fa-handshake": covidStatus == "none"}]'>
                          </span>
                      </span>

                      <span class="dash-icon">
                        <i ng-if='!patientListCountersignStatus.overall'
                                                    style='padding-left:.25em'
                                                    class='fa-lg icon2-countersign silver-grey'></i>
                        <i ng-if='patientListCountersignStatus.overall'
                                                    style='padding-left:.25em'
                                                    class='fa-lg icon2-unapproved trainee-light'></i>
                       </span>
                   </div>
                </div>

                <div class="row">
                    <div class='small-12 columns right end' style="padding-right: 1.5em">
                    <span class="dash-icon">
                    <span class='fa-stack stack-lg'>
                        <i class='fas fa-lg {{ obsIcon }} fa-stack-1x'></i>
                        <i class='fas fa-bell fa-stack-1x lowerize warn' ng-if='patientListAlerts.obsWarn'>
                        </i>
                        <i class='fas icon3-alarm-clock fa-stack-1x lowerize overdue'
                           ng-if='patientListAlerts.obsOverdue'>
                        </i>
                    </span>
                    </span>
                    <span class="dash-icon">

              <span class='fa-stack stack-lg'>
                <i class='fas fa-lg {{ assessmentIcon }} fa-stack-1x'></i>
                <i class='fas fa-bell fa-stack-1x lowerize warn'
                   ng-if='patientListAlerts.assessWarn'>
                </i>
                <i class='fas icon3-alarm-clock fa-stack-1x lowerize overdue'
                   ng-if='patientListAlerts.assessOverdue'>
                </i>
              </span>
                    </span>

                   <span class="dash-icon">
              <span class='fa-stack stack-lg'>
                <i class='far fa-lg fa-check-square fa-stack-1x'
                   ng-class="[{'silver-grey': patient.alerts.task.overall.due === undefined}]"></i>
                <i class='fas fa-bell fa-stack-1x lowerize warn'
                   ng-if='patientListAlerts.taskWarn'>
                </i>
                <i class='fas icon3-alarm-clock fa-stack-1x lowerize overdue'
                   ng-if='patientListAlerts.taskOverdue'>
                </i>
              </span>
                    </span>
                    <span class="dash-icon">
                    <span class='fa-stack stack-lg'>
                        <i class='fas fa-lg fa-book-medical fa-stack-1x' ng-class="[{'silver-grey': !hasCarePlans}]"></i>
                        <i class='fas fa-bell fa-stack-1x lowerize warn'
                           ng-if='patientListAlerts.careplansWarn'>
                        </i>
                        <i class='fas icon3-alarm-clock fa-stack-1x lowerize overdue'
                           ng-if='patientListAlerts.careplansOverdue'>
                        </i>
                    </span>
                    </span>
                </div>
            </div>
        </div>
    </a>
</section>


<section class='dashboardinfo' ng-if="type == 'large'">
    <!-- PATIENT DASHBOARD -->
    <div class='row'>
        <div class='small-12 columns patientname'>
            <span class='name'
                  ng-bind="patient.data.admissions.patient_details.first_name || 'New'">
            </span>
            <span class='name'
                  ng-bind="patient.data.admissions.patient_details.last_name || 'Client'">
            </span>
        </div>
    </div>

    <div class='row nowrap'>
        <div class="small-6 columns">


                <div ng-class='["dno-row", {"hno-height": !patient.data.admissions.patient_details.preferred_name}]'>
                  <span class='dno doctor'
                        ng-show='patient.data.admissions.patient_details.preferred_name'
                        ng-bind='"(" + patient.data.admissions.patient_details.preferred_name + ")"'>
                  </span>
                </div>



                <div class="dno-row">
                    <span class='dno' ng-bind='"DOB: " + patient.data.admissions.patient_details.date_of_birth'> </span>
                    <patient-age dob="patient.data.admissions.patient_details.date_of_birth">
                    </patient-age>
                    <span class='dno uppercase'
                      ng-bind='patient.data.admissions.patient_details.gender.value == "other" ?
                             "" :
                             patient.data.admissions.patient_details.gender.value'>
                    </span>
                </div>


            <div ng-class='["dno-row", {"hno-height": !ews_map.ews_name}]'>
              <i ng-if='ews_map.ews_description' style='position: relative; top: .1em'
                 class='fas fa-tag' ng-style='{"color": ews_map.ews_colour}'>
              </i>
              <span class='dno' ng-if='ews_map.ews_description'
                    ng-bind='ews_map.ews_description'>
              </span>
              <span class='dno' ng-if='!ews_map.ews_description'
                    ng-bind='ews_map.ews_name'>
              </span>
            </div>

            

            <div class="dno-row" ng-if="patient.data.admissions.patient_details.hospital_number">
                <span class='dno'
                    ng-bind='patient.data.admissions.patient_details.hospital_number'>
                </span>
            </div>


             <div class="dno-row" ng-if='!phone'>
                <span translate class="updated">patient_1</span>
<span class='updated' ng-bind='(patient.data.admissions.patient_details.first_preferred_language || "-")'></span>
            </div>


        </div>


        <div class="small-6 columns">
            <div class='row'>
                <div class='small-12 columns right end' style="padding-right: 1.5em">
                    <span class="dash-icon">
                        <a ui-sref='patient.messages({id: patient.id})'>
    <i ng-class='[{"far fa-envelope-open silver-grey": unreadStatus[patient.id] != true},
                  {"fas fa-envelope": unreadStatus[patient.id] == true},
                  {"fa-lg": phone}, {"fa-2x": !phone}]'></i>
                        </a>
                    </span>
                    <span class="dash-icon">
                        <a ui-sref='patient.card({form: "admissions", card: "allergies"})'>
                            <span ng-class="['fas',{'fa-lg':phone},{'fa-2x':!phone},
                                  {'icon3-allergies-active': anyAllergies},
                                  {'icon3-allergies-inactive': !anyAllergies}]">
                                <span class="path1"></span><span class="path2"></span><span class="path3"></span>
                            </span>
                        </a>
                    </span>

                      <span class="dash-icon">
                        <a ui-sref='patient.card({form: "covid", card: "covid_status"})'>
                          <span ng-class='[
                           {"fa-lg":phone},
                           {"fa-2x":!phone},
                            {"fas fa-virus silver-grey": !covidStatus},
                            {"fas fa-virus overdue": covidStatus == "positive"},
                            {"fas fa-handshake": covidStatus == "negative"},
                            {"fas fa-head-side-cough warn": covidStatus == "symptoms"},
                            {"fas fa-people-arrows": covidStatus == "close_contact"},
                            {"fas fa-people-pants": covidStatus == "contact"},
                            {"far fa-handshake": covidStatus == "none"}]'>
                          </span>
                        </a>
                      </span>

                    <span class="dash-icon">
                        <i ng-if='!patientListCountersignStatus.overall'
                           style='padding-left:.25em'
                           class='fa-lg icon2-countersign silver-grey'
                           ng-class="[{'fa-lg':phone},{'fa-2x':!phone}]"></i>
                        <i ng-if='patientListCountersignStatus.overall'
                           style='padding-left:.25em'
                           class='fa-lg icon2-unapproved trainee-light'
                           ng-class="[{'fa-lg':phone},{'fa-2x':!phone}]"></i>
                    </span>
                </div>
            </div>

            <div class="row">
                <div class='small-12 columns right end' style="padding-right: 1.5em">
                    <span class="dash-icon">
                        <a ui-sref='patient.assessment({card: "clinical_obs"})'>
                            <span class='fa-stack' ng-class="[{'fa-stack-lg':phone}]">
                                <i class='fas {{ obsIcon }}' ng-class="[{'fa-stack-1x fa-lg':phone},{'fa-stack-2x':!phone}]"></i>
                                <i class='fas fa-bell fa-stack-1x lowerize warn' ng-if='alerts.obsWarn'>
                                </i>
                                <i class='fas icon3-alarm-clock fa-stack-1x lowerize overdue'
                                   ng-if='alerts.obsOverdue'>
                                </i>
                            </span>
                        </a>
                    </span>
                    <span class="dash-icon">

                        <a ui-sref='patient.assessment({card: ""})' ui-sref-opts="{reload: true}">
                            <span class='fa-stack' ng-class="[{'fa-stack-lg':phone}]" >
                                <i class='fas {{ assessmentIcon }}' ng-class="[{'fa-stack-1x fa-lg':phone},{'fa-stack-2x':!phone}]"></i>
                                <i class='fas fa-bell fa-stack-1x lowerize warn'
                                   ng-if='alerts.assessWarn'>
                                </i>
                                <i class='fas icon3-alarm-clock fa-stack-1x lowerize overdue'
                                   ng-if='alerts.assessOverdue'>
                                </i>
                            </span>
                        </a>
                    </span>

                    <span class="dash-icon">
                        <a ui-sref='patient.messages({id: patient.id, filter: "tasks"})'>
                            <span class='fa-stack'  ng-class="[{'fa-stack-lg':phone}]">
                                <i class='fas fa-check-square'
                                   ng-class="[{'silver-grey': patient.alerts.task.overall.due === undefined},
                                   {'fa-stack-1x fa-lg':phone},{'fa-stack-2x':!phone}]"></i>
                                <i class='fas fa-bell fa-stack-1x lowerize warn'
                                   ng-if='alerts.taskWarn'>
                                </i>
                                <i class='fas icon3-alarm-clock fa-stack-1x lowerize overdue'
                                   ng-if='alerts.taskOverdue'>
                                </i>
                            </span>
                        </a>
                    </span>
                    <span class="dash-icon">
                        <a ui-sref='patient.careplans({card: ""})'>
                            <span class='fa-stack'  ng-class="[{'fa-stack-lg':phone}]">
                                <i class='fas fa-book-medical' ng-class="[{'silver-grey': !hasCarePlans},{'fa-stack-1x fa-lg':phone},{'fa-stack-2x':!phone}]"></i>
                                <i class='fas fa-bell fa-stack-1x lowerize warn' ng-if='alerts.careplansWarn'></i>
                                <i class='fas icon3-alarm-clock fa-stack-1x lowerize overdue' ng-if='alerts.careplansOverdue'></i>
                            </span>
                        </a>
                    </span>
                </div>
            </div>

        </div>
    </div>
</section>

<!-- additional rows for dashboard -->

     <div class="row moreinfo no-top-margin wrap" ng-if="type === 'large' && phone" style="margin-top: -1em;">
        <div class="small-12 columns">
<span translate class="updated">patient_1</span>
<span class='updated' ng-bind='(patient.data.admissions.patient_details.first_preferred_language || "-")'></span>
        </div>
     </div>


    <div class="row moreinfo no-top-margin wrap" ng-if="type === 'large' && phone">
        <div class="small-12 columns">

            <span translate class='updated'>summary_1</span>
            <span class='updated'>
              {{ covidStatusLabel }}
            </span>

        </div>
    </div>

    <div class="row moreinfo no-top-margin wrap" ng-if="type === 'large' && phone">
        <div class="small-12 columns">

          <a ui-sref='patient.card({form: "admissions", card: "reason_for_admission"})'>
          
          <span class='updated' ng-bind="admissionReasonLabelName + ': '"></span>
          <span ng-bind="patient.data.admissions.reason_for_admission.presenting_complaint"
                ng-if="patient.data.admissions.reason_for_admission.presenting_complaint" class="updated">
          </span>
          <span translate ng-if="!patient.data.admissions.reason_for_admission.presenting_complaint" class="updated">
            patient_2
          </span>
          
          </a>

        </div>
    </div>


    <div class="row moreinfo no-top-margin wrap" ng-if="type === 'large' && phone">
        <div class="small-12 columns">
            <span translate class='updated italicize'>
                summary_2
            </span>
            <span class='updated italicize'
                  ng-bind="(patient.last_update.hasOwnProperty('fullName') ?
                  patient.last_update.fullName: patient.last_update.user) + ','">
            </span>
            <span md-colors="{color: 'primary-300'}"
                  class='updated time italicize'
                  ng-bind="formatTime(patient.last_update.timestamp)">
            </span>

        </div>
    </div>


    <div style='margin-top: .2em'
         class="row moreinfo no-top-margin wrap"
         ng-if="type === 'large' && !phone">

        <div class="small-6 columns">

            <span translate class='updated'>patient_3</span>
            <span class='updated'>
              {{ covidStatusLabel }}
            </span>

        </div>

        <div class="small-6 columns align-right">
            <span translate class='updated italicize'>
                summary_2
            </span>
            <span class='updated italicize'
                  ng-bind="(patient.last_update.hasOwnProperty('fullName') ?
                  patient.last_update.fullName: patient.last_update.user) + ','">
            </span>
            <span md-colors="{color: 'primary-300'}"
                  class='updated time italicize'
                  ng-bind="formatTime(patient.last_update.timestamp)">
            </span>
        </div>

    </div>


    <div class="row moreinfo no-top-margin wrap" ng-if="type === 'large' && !phone">
        <div class="small-12 columns">
          <a ui-sref='patient.card({form: "admissions", card: "reason_for_admission"})'>
          <span class='updated' ng-bind="admissionReasonLabelName + ': '"></span>
          <span ng-bind="patient.data.admissions.reason_for_admission.presenting_complaint"
                ng-if="patient.data.admissions.reason_for_admission.presenting_complaint" class="updated">
          </span>
          <span translate ng-if="!patient.data.admissions.reason_for_admission.presenting_complaint" class="updated">
            patient_2
          </span>
          </a>
        </div>
    </div>

</div>
</div>


    `
        };
    });
