module.exports = {
    url: '/login',
    controller() {
        'ngInject';
    },
    template: `
    <div class='mobile-login-filler'></div>
    <login failure='$state.go("login")'>
    </login>
  `
};
