/**
 * @name exception
 * @ngdoc module
 * @description
 * A module for handling exceptions.
 */
angular.module('exception', [])

    /**
     * This replaces the default angularJS $exceptionHandler.
     */

    .factory('$exceptionHandler', function ($log, $state, $injector, Exceptions) {
        return function myExceptionHandler(exception, cause) {
            $log.warn(exception, cause);
            let $rootScope = $injector.get('$rootScope');
            if ($rootScope.noExceptionHandler) {
                $rootScope.noExceptionHandler = false;
                return;
            }

            if (Exceptions.value.filter(e => (e.exception.message === exception.message)).length === 0) {
                Exceptions.value.push({exception: exception, cause: cause});
            }

            let currentLocation = $injector.get('$location').url();
            if (currentLocation !== '/exception') {
                $state.go('exception');
            }
        };
    });
