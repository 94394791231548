/**
 * @name widgets
 * @ngdoc module
 * @description
 * A collection of non-purposed application components.
 */
angular.module('widgets', [])

    /**
     * @name titleBar
     * @ngdoc directive
     * @restrict E
     * @description
     * The bar which provides the context for the name of the page
     * that the user is currently on. It sits below the navigation
     * bar.
     *
     * Content will be transcluded directly into the top level of
     * the element.
     */
    .directive('titleBar', function () {
        return {
            restrict: 'E',
            transclude: true,
            replace: true,
            controller: function ($scope, $rootScope, matchmedia) {
                $scope.signoff = $rootScope.signoff;
                $scope.trainee = $rootScope.trainee;
                matchmedia.onPhone(function (mql) {
                    $scope.phone = mql.matches;
                });
            },
            template: `
      <nav md-colors="{color: 'primary-700'}"
           ng-class='[{"nodisplay": signoff}, {"phone": phone}, "title"]'>
        <ng-transclude></ng-transclude>
      </nav>
    `
        };
    })

    /**
     * @name panel
     * @ngdoc directive
     * @restrict E
     * @description
     * The generic panel element.
     */
    .directive('panel', function () {
        return {
            restrict: 'E',
            transclude: true,
            template: `
      <div class='panel'>
        <ng-transclude></ng-transclude>
      </nav>
    `
        };
    })

    /**
     * @name floating
     * @ngdoc directive
     * @restrict A
     * @description
     * A directive which will add a floating class.
     */
    .directive('floating', function () {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element.addClass('floating');
            }
        };
    });

