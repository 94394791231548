module.exports = {
    url: '/countersignnote',
    controller: function ($scope, $rootScope, signOffInfo, $window, $state) {
        'ngInject';

        $scope.trainee = $rootScope.trainee;
        $window.document.title = 'Countersign Note';

        $scope.countersigned = function () {
            // $rootScope.signoffLater.value = false;
            signOffInfo.notes = false;
            $state.go(signOffInfo.next);
        };

        $scope.note = signOffInfo.note;
        $scope.username = $rootScope.username;

    },
    template: `
  <navigation-bar></navigation-bar>

  <title-bar>
    <center>
      Countersign Note
    </center>
  </title-bar>


  <main>
    <countersign-note-view style='margin:auto' note='note'
                           username='username' on-dialog='false'
                           on-completion='countersigned()'>
    </countersign-note-view>
  </main>

  `
};
