module.exports = {
    url: '/signofflogout',
    controller($scope, $rootScope, $state, signOffInfo, matchmedia) {
        'ngInject';

        var body = document.getElementsByTagName('body')[0];
        body.style.background = 'linear-gradient(to right, #8949b4, #b289d4)';

        $rootScope.signoff = false;
        $rootScope.trainee = true;
        $rootScope.fullName = $rootScope.traineeUsername;
        signOffInfo.assessment = false;
        signOffInfo.static = false;
        signOffInfo.careplan = false;
        signOffInfo.notes = false;

        $scope.patientId = signOffInfo.patientId;

        matchmedia.onPhone(function (mql) {
            $scope.mobile = mql.matches;
        });

        $scope.logout = function () {
            $state.go('patient.dash', {id: $scope.patientId});
            body.style.background = '#f1f2f2';
        };

    },
    template: `
    <main>
      <div ng-class='["row supervisor-login",
                      {"mobile": mobile}]'>

        <div ng-class='["large-4 small-4 columns large-offset-0 small-offset-4",
                       {"center": mobile}]'>
          <img src='assets/images/chai-logo-purple.svg'>
        </div>

        <div class='small-12 large-8 columns'>

          <div class='heading trainee-light'>
            Log Out and Hand Over to Trainee
          </div>

          <div class='subtext'>
            Click OK to log out and return to this
            patient's dashboard in trainee mode.
            <br><br>
            Afterwards, please hand this device over
          </div>

          <div class='row'>
            <button class='logout-button' ng-click='logout()'>
              OK
            </button>
          </div>

        </div>

      </div>
    </main>
  `
};
