/**
 * @name cards
 * @ngdoc module
 * @description
 * A collection of fields that make up the cards for admissions forms.
 */
angular.module('cards', ['input', 'nhs', 'teljs'])

    /**
     * @name Cards
     * @ngdoc service
     * @description
     * A service for handling and managing the cards for any given form.
     */

    .service('Cards', function ($rootScope, $http, $q,
                                FormStore, CachedForms, CachedPatientForms) {

        this.get = function (formName, patientId) {
            let deferred = $q.defer();

            if (patientId) {
                if (!CachedPatientForms[patientId]) {
                    CachedPatientForms[patientId] = {};
                }
                if (CachedPatientForms[patientId][formName]){
                    deferred.resolve(CachedPatientForms[patientId][formName]);
                    return deferred.promise;
                }
                return FormStore.loadPatientForms().then(function (Form) {
                    return Form.getForm({
                        patientId: patientId,
                        formName: formName
                    }).$promise.then(function (form) {
                        CachedPatientForms[patientId][formName] = form;
                        return form;
                    });
                });
            }

            if (CachedForms[formName]) {
                deferred.resolve(CachedForms[formName]);
            } else {
                FormStore.load().then(Form => {
                    Form.get({formName: formName}, data => {
                        // angular attaches $promise, $resolved.
                        var form = angular.fromJson(angular.toJson(data));
                        CachedForms[formName] = form;
                        deferred.resolve(form);
                    }, err => {
                        console.error(err);
                    });
                });
            }
            return deferred.promise;
        };

    })

    .service('FormStore', function (Server, $rootScope, $q) {
        this.load = function () {
            return Server.createResource('/forms/:formName', {
                all: {
                    url: '/forms',
                    isArray: true
                }
            });
        };

        this.loadPatientForms = function () {
            return Server.createResource('/patient/:patientId:/forms/:formName', {
                getForm: {
                    url: '/patient/:patientId/forms/:formName'
                },
                getForms: {
                    url: '/patient/:patientId/forms',
                    isArray: true
                }
            });
        };

        function getFormsByType(forms, type) {
            return forms.filter(form => {
                return form.type === type;
            }).sort(function (a, b) {
                return a.order > b.order ? 1 : -1;
            });
        }

        this.patientRepeatingForms = function (patientId) {
            return this.loadPatientForms().then(function (Forms) {
                return Forms.getForms({
                    patientId: patientId
                }).$promise.then(forms => getFormsByType(forms, 'repeating'));
            });
        };

        function getFormByName(forms, name) {
            return forms.filter(form => {
                return form.name === name;
            });
        }

        this.loadForms = function () {
            if ($rootScope.staticForms === undefined) {
                return this.load().then(Forms => {
                    return Forms.all().$promise.then(function (forms) {
                        $rootScope.staticForms = getFormsByType(forms, 'static');
                        $rootScope.customForms = getFormsByType(forms, 'custom');
                        $rootScope.repeatingForms = getFormsByType(forms, 'repeating');
                        $rootScope.carePlans = getFormsByType(forms, 'care-plan');
                        $rootScope.accessCodeForm = getFormByName(forms, 'access_code');
                        return {
                            staticForms: $rootScope.staticForms,
                            customForms: $rootScope.customForms,
                            repeatingForms: $rootScope.repeatingForms,
                            carePlans: $rootScope.carePlans,
                            accessCodeForm: $rootScope.accessCodeForm
                        };
                    });
                });
            }
            let deferred = $q.defer();
            deferred.resolve({
                staticForms: $rootScope.staticForms,
                customForms: $rootScope.customForms,
                repeatingForms: $rootScope.repeatingForms,
                carePlans: $rootScope.carePlans,
                accessCodeForm: $rootScope.accessCodeForm
            });
            return deferred.promise;
        };


    })

    .service('CardStore', function (Server) {
        this.load = function () {
            return Server.createResource('/patient/:id/:form/:card', {
                update: {
                    method: 'PUT',
                    url: '/patient/:id/:form/:card'
                },
                reset: {
                    method: 'POST',
                    url: '/patient/:id/reset'
                }
            });
        };
    })

    /**
     * @name card
     * @ngdoc directive
     * @restrict E
     * @description
     *
     */
    .directive('card', function (Animate, $rootScope,
                                 $parse, $timeout, cleverCards,
                                 CardStore, ProgressAggregator,
                                 expandCards, Actions, Reject, $state,
                                 signOffInfo, CountersignStatus, CurrentValueStore,
                                 $transitions, expandedEntities, FormLiveModels, FormPreviousModels,
                                 Utils, CachedForms, $interval, PatientStore,
                                 $mdDialog, $q, $translate) {

        return {
            restrict: 'E',
            scope: {
                ngModel: '=',
                patient: '=',
                patientId: '=',
                formName: '=',
                formLabel: '=',
                groupIndex: '=',
                external: '=',
                card: '='
            },
            link: function (scope, element) {
                scope.repeatable = scope.card.repeatable;
                scope.hasNa = scope.card.hasNA;
                scope.preview = scope.card.preview;
                scope.instructions = scope.card.instructions;
                scope.name = scope.card.name;
                scope.label = scope.card.label;
                scope.icon = scope.card.icon;
                scope.fields = scope.card.fields;
                scope.disable = scope.card.disable;
                scope.warning = scope.card.warning;
                scope.trainee = $rootScope.trainee;
                scope.signoff = $rootScope.signoff;
                scope.countersignStatus = CountersignStatus.getStatus();

                if (!(scope.formName in CurrentValueStore)) {
                    CurrentValueStore[scope.formName] = {};
                    FormLiveModels[scope.formName] = {};
                    FormPreviousModels[scope.formName] = {};
                }

                if (!(scope.name in CurrentValueStore[scope.formName])) {
                    CurrentValueStore[scope.formName][scope.name] = {};
                    FormLiveModels[scope.formName][scope.name] = {};
                    FormPreviousModels[scope.formName][scope.name] = {};
                }

                function removeDerivedKeys(oldData, newData, formName, cardName) {
                    for (let group of CachedForms[formName].groups) {
                        for (let card of group.cards) {
                            if (card.name === cardName) {
                                for (let field of card.fields) {
                                    if ('value' in field) {
                                        delete oldData[field.name];
                                        delete newData[field.name];
                                    }
                                }
                            }
                        }
                    }
                }

                var confirmNavigateAway = $mdDialog.confirm()
                    .title($translate.instant('form_4'))
                    .ariaLabel($translate.instant('form_5'))
                    .ok($translate.instant('no'))
                    .cancel($translate.instant('yes'));

                function navigateAway() {
                    let deferred = $q.defer();
                    let anyModelChanged = false;
                    if (Object.keys(expandedEntities.static).length > 0) {
                        for (let cardKey in expandedEntities.static) {
                            cardKey = cardKey.split('-');
                            let form = cardKey[0];
                            let card = cardKey[1];
                            let oldData = angular.copy(CurrentValueStore[form][card]);
                            let newData = angular.copy(FormLiveModels[form][card]);
                            removeDerivedKeys(oldData, newData, form, card);
                            let modelChanged = !angular.equals(oldData, newData);
                            if (modelChanged) {
                                anyModelChanged = true;
                                $mdDialog.show(confirmNavigateAway).then(function () {
                                    deferred.resolve(false);
                                }, function () {
                                    deferred.resolve(true);
                                });
                                break;
                            }
                        }

                        if (!anyModelChanged) {
                            deferred.resolve(true);
                        }
                    } else {
                        deferred.resolve(true);
                    }
                    return deferred.promise;
                }

                function confirmCloseCard(form, card) {
                    let deferred = $q.defer();
                    let oldData = angular.copy(CurrentValueStore[form][card]);
                    let newData = angular.copy(FormLiveModels[form][card]);
                    removeDerivedKeys(oldData, newData, form, card);
                    let modelChanged = !angular.equals(oldData, newData);
                    if (modelChanged) {
                        $mdDialog.show(confirmNavigateAway).then(function () {
                            deferred.resolve(false);
                        }, function () {
                            deferred.resolve(true);
                        });
                    } else {
                        deferred.resolve(true);
                    }
                    return deferred.promise;
                }

                var filteredHook = $transitions.getHooks('onBefore').filter(hook => {
                    return hook.callback.name === navigateAway.name && hook.callback.length === navigateAway.length;
                });

                if (filteredHook.length === 0) {
                    $transitions.onBefore({
                        from: 'patient.group', to: function (state) {
                            return state.name != 'exception' && state.name != 'login';
                        }
                    }, navigateAway);
                }

                if (signOffInfo.static) {
                    scope.signoffCard = signOffInfo.card;
                }

                scope.evalPreview = function (index) {
                    const preview = $parse(scope.preview);
                    return preview(scope.ngModel.cards[index]);
                };


                const domElement = element[0];
                scope.expanded = false;
                if (scope.repeatable) {
                    scope.collapsed = [];
                    scope.ngModel = scope.ngModel || {cards: [{}]};
                    for (var _ = 0; _ < scope.ngModel.cards.length; _++) {
                        scope.collapsed.push(true);
                    }
                } else {
                    scope.ngModel = scope.ngModel || {};
                }
                scope.completed = scope.ngModel._completed_;
                scope.otherUser = scope.ngModel._submittedBy_ ?
                    scope.ngModel._submittedBy_ !== $rootScope.username :
                    false;

                scope.warnings = {};

                scope.toggleCollapse = function (index) {
                    scope.collapsed[index] = !scope.collapsed[index];
                };

                scope.scrollToMe = function () {
                    const navs = document.getElementsByClassName('global');
                    let target = domElement.offsetTop;

                    if (navs.length > 0) {
                        const nav = navs[0];
                        target -= nav.offsetHeight;
                    }

                    Animate(document.documentElement)
                        .steps(10)
                        .between({
                            scrollTop: document.body.scrollTop
                        })
                        .and({
                            scrollTop: target
                        })
                        .play();
                };

                scope.submitDisabled = function () {
                    return (!Reject.isEmpty(scope.formName, scope.name) || !$rootScope.canSubmitForms);
                };

                function updateScope(newScope) {

                    for (let field in newScope) {
                        scope.ngModel[field] = newScope[field];
                    }

                    for (let field in scope.ngModel) {
                        if (!(field in newScope)) {

                            if (field === 'cards') {
                                scope.ngModel = {cards: [{}]};
                                return;
                            }

                            if (Utils.isFieldEmpty(scope.ngModel[field])) {

                            } else {
                                delete scope.ngModel[field];
                            }
                        }
                    }
                }

                scope.toggle = function () {
                    let cardKey = scope.formName + '-' + scope.name;
                    if (!scope.expanded) {
                        expandedEntities.static[cardKey] = true;
                        CardStore.load().then(Card => {
                            Card.get({
                                id: scope.patientId,
                                form: scope.formName,
                                card: scope.name
                            }, function (data) {
                                updateScope(data[scope.name]);

                                scope.completed = false;
                                scope.otherUser = false;
                                scope.expanded = !scope.expanded;
                                if (!(scope.formName in CurrentValueStore)) {
                                    CurrentValueStore[scope.formName] = {};
                                    FormLiveModels[scope.formName] = {};
                                    FormPreviousModels[scope.formName] = {};
                                }
                                CurrentValueStore[scope.formName][scope.name] = angular.copy(scope.ngModel);
                                FormLiveModels[scope.formName][scope.name] = scope.ngModel;
                                FormPreviousModels[scope.formName][scope.name] = angular.copy(scope.ngModel);
                                setTimeout(scope.scrollToMe, 0);
                            }, err => {
                            });
                        });
                    } else {
                        confirmCloseCard(scope.formName, scope.name).then(function (result) {
                            if (result) {
                                delete expandedEntities.static[cardKey];
                                scope.completed = scope.ngModel._completed_;
                                scope.otherUser = scope.ngModel._submittedBy_ ?
                                    scope.ngModel._submittedBy_ !== $rootScope.username :
                                    false;
                                scope.expanded = !scope.expanded;
                                setTimeout(scope.scrollToMe, 0);
                            }
                        });
                    }
                };

                scope.evalExpressions = function (expressions) {
                    return cleverCards.evalExpressions(expressions, scope.patient.data, scope.ngModel);
                };

                scope.evalDisable = function (expressions) {
                    if (scope.disable !== undefined && scope.ngModel._completed_) {
                        return false;
                    } else {
                        return cleverCards.evalDisable(expressions, scope.patient.data, scope.ngModel);
                    }
                };

                scope.evalWarning = function (expressions, field_name) {
                    var result = scope.evalExpressions(expressions);
                    if (result !== undefined) {
                        scope.warnings[field_name] = result.color || '#f3732c';
                        return result.message;
                    } else {
                        delete scope.warnings[field_name];
                    }
                };

                scope.warningBorder = function (field_name) {
                    var color = scope.warnings[field_name];
                    if (color !== undefined) {
                        return {'border': '2px solid ' + color};
                    }
                };

                scope.submit = function (event, countersignLater) {
                    if (event) {
                        event.stopPropagation();
                    }

                    $mdDialog.show({
                        parent: angular.element(document.body),
                        template: `
            <md-dialog aria-label='Progress Indicator' class="progress-indicator">
              <div layout="row" layout-sm="column" layout-align="center center">
                <md-progress-circular md-mode="indeterminate">
                </md-progress-circular>
              </div>
            </md-dialog>
          `
                    });

                    scope.ngModel._completed_ = true;
                    scope.ngModel._submittedBy_ = $rootScope.username;
                    scope.ngModel._submittedOn_ = new Date().getTime();
                    scope.otherUser = false;
                    delete scope.ngModel._external_;

                    let specifics = {};
                    specifics.type = 'static';
                    specifics.info = {
                        group: scope.groupIndex,
                        form: scope.formName,
                        card: scope.name,
                        cardLabel: scope.label,
                        formLabel: scope.formLabel
                    };

                    scope.card.fields.forEach(field => {
                        if (field.hasOwnProperty('action')) {
                            Actions.perform(field, specifics, scope.patient.data, scope.ngModel);
                        }
                    });

                    var params = {
                        id: scope.patientId,
                        form: scope.formName,
                        card: scope.name
                    };

                    if (scope.signoff) {
                        params.secret = signOffInfo.secret;
                    }

                    CardStore.load().then(Card => {
                        Card.update(params, scope.ngModel, data => {

                            if (!scope.trainee || countersignLater) {
                                scope.expanded = false;
                                scope.completed = true;
                            }

                            scope.patient.last_update = data.last_update;
                            ProgressAggregator.update(scope.formName, data.progress);
                            CountersignStatus.refresh(data.approvals);

                            $mdDialog.hide();

                            let cardKey = scope.formName + '-' + scope.name;
                            delete expandedEntities.static[cardKey];

                            if (scope.trainee && !countersignLater) {
                                $rootScope.signoff = true;
                                signOffInfo.patientId = scope.patientId;
                                signOffInfo.form = scope.formName;
                                signOffInfo.card = scope.name;
                                signOffInfo.static = true;
                                $state.go('signofflogin');
                            }

                            if (scope.signoff) {
                                $state.go('signofflogout');
                            }

                        }, err => {
                        });
                    });
                };

                scope.repeatCard = function () {
                    scope.ngModel.cards.push({});
                    scope.collapsed.push(false);
                };


                scope.confirmDeletion = function () {

                    $mdDialog.show({
                        parent: angular.element(document.body),
                        locals: {ngModel: scope.ngModel, collapsed: scope.collapsed},
                        template: `
          <md-dialog aria-label='Card Remove Confirmation'>
            <div layout-align="center center" layout-margin layout-padding>
              <h4>Are you sure you want to delete?</h4>
              <div layout='row' layout-align='end' layout-margin>
                <md-button md-colors="{background: 'primary-600', color: 'primary-50'}"
                           ng-click='trashCard()'>
                  <i style='vertical-align: middle' class='fas fa-check fa-2x'></i>
                </md-button>
                <md-button md-colors="{background: 'primary-600', color: 'primary-50'}"
                           ng-click='closeDialog()'>
                  <i style='vertical-align: middle' class='fas fa-times fa-2x'></i>
                </md-button>
              </div>
            </div>
          </md-dialog>
        `,
                        controller: function DialogController($scope, $mdDialog, ngModel, collapsed) {
                            $scope.ngModel = ngModel;
                            $scope.collapsed = collapsed;

                            $scope.closeDialog = function () {
                                $mdDialog.hide();
                            };

                            $scope.trashCard = function () {
                                $scope.closeDialog();
                                $scope.ngModel.cards.pop();
                                $scope.collapsed.pop();
                            };
                        }
                    });

                };

                if (expandCards[scope.name]) {
                    delete expandCards[scope.name];
                    scope.toggle();
                }

                scope.$watch('ngModel', function () {
                    // Something is setting ngModel to {} further down
                    // no idea what. This is just a hacky fix. Don't
                    // ever rely on it.
                    if (scope.repeatable && !(scope.ngModel.hasOwnProperty('cards'))) {
                        scope.ngModel = {'cards': [{}]};
                    }
                });
            },
            template: `
      <div ng-class='["card static",
                      {"nodisplay": signoff && name !== signoffCard}]'
       ng-style='warningBorder(name)'
       ng-hide='evalDisable(disable) == true'>

        <header ng-click='toggle()'
                ng-class="{'external': external,
                           'otheruser': otherUser,
                           'completed': completed,
                           'trainee-light-bg': countersignStatus.static[formName][name]}">
          <div class='left'>
            <card-glyph seed='name'></card-glyph>
          </div>
          <div class='left heading'>
            <span ng-bind='label'></span>
          </div>
          <i ng-if='countersignStatus.static[formName][name]'
             style='position:relative;top: 0.05em; left: 0.3em; color:white'
             class='icon2-unapproved va-bottom'>
          </i>
          <div class='right' ng-if='hasNa'>
            <button class='danger tiny' ng-click='submit($event)'>N/A</button>
          </div>
        </header>

        <section ng-show='expanded'>
          <p md-colors="{color: 'primary-100'}"
             chai-markdown='instructions'>
          </p>

          <div ng-if='!repeatable'>
            <form ng-class='{external: external}'>
              <section>
                <card-field
                 ng-repeat='field in fields'
                  field='field'
                  ng-model='ngModel'
                  meta-model={}
                  card-name='name'
                  group-index='groupIndex'
                  form-name='formName'
                  form-type='static'
                  external='ngModel._external_'
                  patient='patient.data'>
                </card-field>
              </section>
            </form>
          </div>


        <div ng-if='repeatable'>
          <div ng-repeat='card in ngModel.cards'>
            <form ng-class='{card: true, external: external}'>
              <header ng-click='toggleCollapse($index)'>
                <div class='right'>
                  <span md-colors="{color: 'primary-100'}"
                        ng-if='collapsed[$index]'>►</span>
                  <span md-colors="{color: 'primary-100'}"
                        ng-if='!collapsed[$index]'>▼</span>
                </div>
                <div class='center'>
                  <span md-colors="{color: 'primary-100'}"
                        ng-bind='evalPreview($index)'></span>
                </div>
              </header>

              <section ng-hide='collapsed[$index]'>
                <card-field
                  ng-repeat='field in fields'
                  ng-model='card'
                  meta-model={}
                  group-index='groupIndex'
                  card-name='name'
                  form-name='formName'
                  field='field'
                  patient='patient.data'
                  form-type='static'
                  external='ngModel._external_' >
                </card-field>
              </section>
            </form>
          </div>
            <hr />
            <md-button md-colors="{background: 'primary-600', color: 'primary-50'}"
                       style='margin-top: .75em' aria-label='Repeat Card'
                       class='md-fab md-primary'
                    ng-click='repeatCard()'>
              <i class='fas fa-plus'></i>
            </md-button>

            <md-button md-colors="{background: 'primary-600', color: 'primary-50'}"
                       style='margin-top: .75em' aria-label='Delete Card'
                       class='md-fab md-primary'
                    ng-click='confirmDeletion()'>
              <i class='fas fa-trash'></i>
            </md-button>
          </div>
        </section>

          <center ng-if= '!trainee && !countersignStatus.static[formName][name]'
                  ng-show='expanded' class='topmargin'>
            <button translate class='tiny submit'
                    ng-disabled="submitDisabled()"
                    ng-click='submit()'>submit
            </button>
          </center>

           <center ng-if= '!trainee && countersignStatus.static[formName][name] && !signoff'
                  ng-show='expanded' class='topmargin'>
            <button class='tiny submit'
                    ng-disabled="submitDisabled()"
                    ng-click='submit()'>
              <i class='icon2-countersign'></i>
              <span> Countersign Card</span>
            </button>
          </center>


          <div class='row'
               ng-if='!trainee && countersignStatus.static[formName][name] && signoff'
               ng-show='expanded'
               class='topmargin'>
            <div class='small-6 columns'>
              <button class='submit full-width'
                      ng-disabled="submitDisabled()"
                      ng-click='submit()'>
              <i class='icon2-countersign'></i>
              <span> Countersign Now </span>
              </button>
            </div>
            <div class='small-6 columns'>
              <button class='submit full-width'
                      ng-disabled="submitDisabled()"
                      ui-sref='signofflogout'>
                <i class='fas fa-times'></i>
                <span> Cancel </span>
              </button>
            </div>
          </div>


          <div class='row' ng-if='trainee' ng-show='expanded' class='topmargin'>
            <div class='small-6 columns'>
              <button class='submit full-width trainee-colored no-border'
                      ng-disabled="submitDisabled()"
                      ng-click='submit()'>
              <i class='far fa-handshake'></i>
              <span> Countersign Now</span>
              </button>
            </div>
            <div class='small-6 columns'>
              <button class='submit full-width trainee-colored no-border'
                      ng-disabled="submitDisabled()"
                      ng-click='submit(undefined, true)'>
                <i class='fas fa-paper-plane'></i>
                <span> Countersign Later</span>
              </button>
            </div>
          </div>

      </div>
      <span ng-bind='evalWarning(warning, name)'></span>
    `
        };
    });
