angular.module('audioInput', [])

    .directive('audioInput', function ($sce, MediaStore, $rootScope, $interval, Utils) {
        return {
            restrict: 'E',
            scope: {
                src: '=',
                ward: '=?'
            },
            controller: function ($scope) {

                if (!$scope.src) {
                    $scope.src = {};
                }

                $scope.ward = $scope.ward || false;

                $scope.convertToMinutes = Utils.convertToMinutes;

                $scope.recording = false;
                $scope.loading = false;

                var RecordRTC = require('recordrtc');

                var mediaRecorder;
                var mediaStream;
                var audioLimit = 120000;
                let mimeType = 'audio/wav';
                $scope.timeLeft = $scope.convertToMinutes(audioLimit / 1000);

                function onMediaSuccess(stream) {
                    mediaStream = stream;
                    let options = {
                        type: 'audio',
                        mimeType: mimeType,
                        disableLogs: true,
                        recorderType: RecordRTC.StereoAudioRecorder,
                        numberOfAudioChannels: 1
                    }
                    mediaRecorder = RecordRTC(stream, options);
                    mediaRecorder.startRecording();
                }

                function onMediaError(e) {
                    console.error('media error', e);
                }

                $scope.startRecording = function () {
                    $scope.src.data = undefined;
                    $scope.timeLeft = $scope.convertToMinutes(audioLimit / 1000);
                    var counter = 0;
                    $scope.timer = $interval(function () {
                        counter = counter + 1;
                        $scope.timeLeft = $scope.convertToMinutes((audioLimit - (counter * 1000)) / 1000);
                    }, 1000, audioLimit / 1000);

                    navigator.mediaDevices.getUserMedia({audio: true}).then(onMediaSuccess).catch(onMediaError);

                    $scope.recording = true;
                };

                $scope.stopRecording = function () {
                    $scope.loading = true;
                    $scope.recording = false;
                    $interval.cancel($scope.timer);
                    mediaRecorder.stopRecording(function () {
                        mediaStream.getTracks().forEach(track => {
                            track.stop();
                        });

                        let blob = mediaRecorder.getBlob();
                        MediaStore.postMedia(mimeType, !$scope.ward).then(Media => {
                            Media.send(blob, function (resp) {
                                mediaRecorder.destroy();
                                $scope.src.data = resp.path;
                                $scope.timeLeft = $scope.convertToMinutes(audioLimit / 1000);
                                $scope.loading = false;
                            });
                        });
                    })
                };

                $scope.toggle = function () {
                    if ($scope.recording) {
                        $scope.stopRecording();
                    } else {
                        $scope.startRecording();
                    }
                };

            },
            template: `
      <div ng-class='["microphone", {"recording": recording}]' ng-click='toggle()'>
        <i class='fas fa-microphone fa-4x'></i>
      </div>

      <div ng-bind='timeLeft' ng-if='!src.data' class='timer'></div>

      <div style='color: #888a85' class='center'
           ng-bind='"Tap To Begin Recording"'
           ng-if='!src.data && !recording && !loading'>
      </div>

      <div style='color: #888a85' class='center'
           ng-bind='"Tap To Stop Recording"'
           ng-if='recording'>
      </div>

      <div ng-if='loading && !src.data' layout layout-align='center center' layout-margin>
        <i class='fas fa-spinner fa-spin fa-2x'>
        </i>
      </div>

    <div ng-if='src.data' class='audio-test'>
      <audio-player source='src' show-clear='true'></audio-player>
    </div>

    `
        };
    })

    .directive('audioPlayer', function ($rootScope, Utils, $timeout, $http) {
        return {
            scope: {
                source: '=',
                showClear: '=',
                showStop: '=',
                open: '='
            },
            link: function (scope) {
                var audioPlayer = new Audio();

                scope.$watch('open.value', function () {
                    if (scope.open) {
                        if (scope.open.value === false) {
                            scope.reset();
                            scope.pause();
                        }
                    }
                });

                scope.playing = false;
                scope.slider = {max: 0};

                scope.$watch('source.data', function () {
                    if (scope.source.data) {

                        $http({
                            url: $rootScope.serverUrl + scope.source.data,
                            method: 'GET',
                            responseType: 'blob'
                        })
                            .then(function (response) {
                                var fileURL = URL.createObjectURL(response.data);
                                audioPlayer.src = fileURL
                                scope.duration = audioPlayer.duration.toFixed(1);
                                scope.durationInMinutes = scope.convertToMinutes(scope.duration || 0);
                                $timeout(function () {
                                    scope.slider.max = scope.duration;
                                }, 100);
                            });
                    }
                });

                scope.convertToMinutes = Utils.convertToMinutes;

                scope.play = function () {
                    audioPlayer.play();
                    scope.playing = true;
                };

                scope.pause = function () {
                    audioPlayer.pause();
                    scope.playing = false;
                };

                scope.playPause = function () {
                    if (scope.playing) {
                        scope.pause();
                    } else {
                        scope.play();
                    }
                };

                scope.reset = function () {
                    audioPlayer.currentTime = 0;
                };

                scope.seek = function (increase) {
                    audioPlayer.currentTime = increase ?
                        audioPlayer.currentTime + 5 :
                        audioPlayer.currentTime - 5;
                };

                audioPlayer.addEventListener('timeupdate', function () {
                    $timeout(function () {
                        scope.slider.value = parseFloat(audioPlayer.currentTime.toFixed(1));
                    }, 100);
                });

                audioPlayer.addEventListener('durationchange', function () {
                    scope.duration = audioPlayer.duration.toFixed(1);
                    scope.durationInMinutes = scope.convertToMinutes(scope.duration || 0);
                    // scope.play();
                    // scope.pause();
                    $timeout(function () {
                        scope.slider.max = scope.duration;
                    }, 100);
                });

                audioPlayer.addEventListener('ended', function () {
                    scope.playing = false;
                });

                scope.clearAudio = function () {
                    scope.source.data = undefined;
                };

            },
            template: `
      <div layout layout-align='space-around' style='width: 90%; margin: auto;'>
        <div style='line-height: 3em'
             md-colors="{color: 'primary-200'}"
             class='center'>
          <span ng-bind='convertToMinutes(slider.value || 0)'></span>
        </div>

        <div class='center slider'>
          <md-slider aria-label='Audio Playback Slider'
                     ng-model='slider.value' step='0.01' min='0'
                     disabled max='{{ slider.max }}'>
          </md-slider>

        </div>

        <div style='line-height: 3em' md-colors="{color: 'primary-200'}"
             class='center'>
          <span ng-bind='durationInMinutes'></span>
        </div>
      </div>

      <div layout layout-align='center center'>
        <i style='margin: 1em' class='fas fa-undo' ng-click='reset()'></i>

        <i style='margin: 1em' class='fas fa-backward' ng-click='seek(false)'></i>

        <i style='margin: .5em' md-colors="{color: 'primary-200'}"
           ng-class='["fas fa-2x", {"fa-play": !playing, "fa-pause": playing}]'
           ng-click='playPause()'>
        </i>

        <i style='margin: 1em' class='fas fa-forward' ng-click='seek(true)'></i>

        <i ng-if='showClear' style='margin: 1em' class='fas fa-trash-alt' ng-click='clearAudio()'></i>

        <i ng-if='showStop' style='margin: 1em' class='fas fa-stop' ng-click='reset()'></i>
      </div>

    `
        };
    });
