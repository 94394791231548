/**
 * @name cleverCards
 * @ngdoc module
 * @description
 * Collection of clever card functions.
 * Uses the $parse module to compile expressions into functions.
 * Functions are then evaluated against context (patient) and localscope (current card).
 */

angular.module('cleverCards', [])

    .service('cleverCards', function ($parse, Reject) {

        /**
         * Determines which element of an array of objects to return,
         * by returning the first element where the condition property evaluates to true
         * @returns {*}
         */
        this.evalExpressions = function (expressions, patient, card) {
            if (expressions) {
                for (var i = 0; i < expressions.length; i++) {
                    if (this.evalExpression(expressions[i].condition, patient, card)) {
                        return expressions[i];
                    }
                }
            }
        };


        /**
         * Returns all the expressions which evaluated to true unlike evalExpressions
         * which returns the very first one.
         */
        this.evalActionExpressions = function (expressions, patient, card) {
            return expressions.filter(expression => {
                if (this.evalExpression(expression.condition, patient, card)) {
                    return true;
                }
            })
        };


        /**
         * Determine if the field should be hidden.
         * Return true if hidden is true for the first condition which is true
         * Return false if disabled but not hidden.
         * Return undefined if not disabled at all.
         */
        this.evalDisable = function (expressions, patient, card) {
            var result = this.evalExpressions(expressions, patient, card);
            if (result !== undefined) {
                return result.hidden || false;
            }

        };


        /**
         * Determine a derived value for a field.
         * @returns {*}
         */
        this.evalDerived = function (expressions, patient, card) {
            var result = this.evalExpressions(expressions, patient, card);

            if (result !== undefined) {
                // combine patient and card as context rather than passing separately,
                // then functions such as pew_value() can access both as via "this"
                var value = $parse(result.expression)(angular.extend({}, patient, card));
                return value;
            }
        };

        /**
         * Determine if there is a warning for the field. Set the properties on the supplied warning object
         * @returns {boolean}
         */
        this.evalWarning = function (expressions, patient, card, warning) {
            var result = this.evalExpressions(expressions, patient, card);
            if (result !== undefined) {
                warning.color = result.color || '#f3732c';
                warning.message = result.message;
                warning.icon = 'fa-exclamation-triangle';
                return true;
            } else {
                delete warning.css;
                return false;
            }
        };

        /**
         * Determine if a field value should be rejected,
         * Set properties on supplied details object.
         * Add/Remove form/field to Reject
         * @returns {boolean}
         */
        this.evalReject = function (expressions, patient, card,
                                    form, field, cardName, details) {
            var result = this.evalExpressions(expressions, patient, card);
            if (result !== undefined) {
                Reject.add(form, cardName, field);
                details.message = result.message;
                details.color = '#e74f4f';
                details.icon = 'fa-exclamation-triangle';
                return true;
            } else {
                delete details.css;
                Reject.remove(form, cardName, field);
                return false;
            }
        };

        this.evalAction = function (actions, patient, card) {
            actions.forEach(action => {
                action.condition = action.on;
            })
            let results = this.evalActionExpressions(actions, patient, card);
            let alerts = [];
            if (results.length > 0) {
                for (let result of results) {
                    let alert = $parse(result.note)(angular.extend({}, patient, card));
                    if (alert) {
                        if (result.name == 'create_task') {
                            alert = 'Task will be added: ' + alert;
                        }
                        if (result.name == 'attach_concern') {
                            alert = 'Important info will be noted on Summary Page: ' + alert;
                        }
                        alerts.push(alert);
                    }
                }
                return alerts;
            }
        };

        /**
         * Evaluate a single expression   *
         * @returns {*}
         */
        this.evalExpression = function (expression, patient, card) {
            var evaluator = $parse(expression);
            return evaluator(patient, card);
        };

    });
