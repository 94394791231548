module.exports = {
    url: '/form/:form/group/:group/:card',
    views: {
        main: {
            controller: function ($scope, $state, Cards, expandCards, $window,
                                  $rootScope, expandedEntities, CardsToReset) {
                'ngInject';
                $scope.form = {};
                $scope.group = {};
                $scope.formName = $state.params.form;
                $scope.groupIndex = Number($state.params.group);
                $scope.patient = $scope.$parent.patient;
                $scope.trainee = $rootScope.trainee;

                $scope.signoff = $rootScope.signoff;

                $window.document.title = 'CHAI ' + $rootScope.patientLabel + ' Form';

                expandedEntities.static = {};
                expandedEntities.repeating = {};
                expandCards[$state.params.card] = true;

                $scope.initialProgress = function (cardName) {
                    const {formName, patient} = $scope;
                    patient.data[formName] = patient.data[formName] || {};
                    patient.data[formName][cardName] = patient.data[formName][cardName] || {};
                    if (CardsToReset.value[formName]) {
                        if (CardsToReset.value[formName][cardName]) {
                            if (patient.data[formName][cardName]._completed_) {
                                patient.data[formName][cardName]._completed_ = false;
                                delete CardsToReset.value[formName][cardName];
                            }
                        }
                    }
                };

                $scope.scrollUp = function () {
                    window.scrollTo(0, 0);
                };

                Cards
                    .get($scope.formName)
                    .then(form => {
                        $scope.form = form;
                        $scope.totalGroups = form.groups.length;
                        $scope.group = form.groups[$scope.groupIndex];
                        if ($scope.formName == 'admissions') {
                            $rootScope.admissionsForm = form;
                        }
                    });


            },
            template: `


 <div class='row form-group-nav' ng-class='{"nodisplay": signoff}'>

      <a class='left' style='font-size: .75em;' ng-if='groupIndex > 0'
         ui-sref='^.group({form: formName, group: groupIndex - 1, card: ""})'>
        <i class="fas fa-caret-left"></i>
        <span translate>prev</span>
      </a>

      <a ui-sref-active='activegroup'
         ng-repeat='group in form.groups'
         ui-sref='^.group({form: formName, group: $index, card: ""})'>
        <i ng-class='[group.icon]'></i>
      </a>

      <a class='right' style='font-size: .75em;' ng-if='groupIndex < totalGroups - 1'
         ui-sref='^.group({form: formName, group: groupIndex + 1, card: ""})'>
        <span translate>next</span>
        <i class="fas fa-caret-right"></i>
      </a>
  </div>
  <div class='row group-title'>
    <a ng-class='{"nodisplay" : signoff}'
       ui-sref='patient.form({id: patient.id, name: form.name})'>
      <span ng-bind='form.label'></span>
    </a>
      <span ng-bind='" : " + group.label'></span>
  </div>


    <card
      ng-model='patient.data[formName][card.name]'
      ng-init='initialProgress(card.name)'
      patient='patient'
      patient-id='patient.id'
      card='card'
      form-name='formName'
      form-label='form.label'
      ng-repeat='card in group.cards'
      group-index='groupIndex.toString()'
      external='patient.data[formName][card.name]._external_'>
    </card>

    <div class='row' ng-class='{"nodisplay": signoff}'>
      <a ng-if='groupIndex > 0'
         ui-sref='^.group({form: formName, group: groupIndex - 1, card: ""})'>
        <i class="fas fa-caret-left"></i>
        <span>Prev</span>
      </a>

      <a class='right' ng-if='groupIndex < totalGroups - 1'
         ui-sref='^.group({form: formName, group: groupIndex + 1, card: ""})'>
        <span translate>next</span>
        <i class="fas fa-caret-right"></i>
      </a>
    </div>

    <div class='action-tray'>
      <div class='right'>
        <a class='action-btn' ng-click='scrollUp()'>
          <i class='fas fa-arrow-up'></i>
        </a>
      </div>
    </div>
  `
        },
        title: {
            controller: function ($scope, $state, Cards) {
                'ngInject';
                //ok to call Cards.get because it caches form definitions
                Cards.get($state.params.form)
                    .then(form => {
                        $scope.form = form;
                    });

            },
            template: `
<span ng-bind='form.label'></span>
    `
        }
    }
};
