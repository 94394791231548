require("../styles/main.scss");
require('./modules/constants');
require('./modules/widgets');
require('./modules/navigation');
require('./modules/fields');
require('./modules/cards');
require('./modules/progress');
require('./modules/glyph');
require('./modules/patient');
require('./modules/exception');
require('./modules/random');
require('./modules/animate');
require('./modules/server');
require('./modules/login');
require('./modules/input');
require('./modules/print');
require('./modules/responseInterceptor');
require('./modules/messages');
require('./modules/ws');
require('./modules/nhs');
require('./modules/cleverCards');
require('./modules/utils');
require('./modules/repeatingForms');
require('./modules/reject');
require('./modules/actions');
require('./modules/concerns');
require('./modules/task');
require('./modules/media');
require('./modules/audioInput');
require('./modules/history');
require('./modules/alerts');
require('./modules/search');
require('./modules/angularTable');
require('./modules/supervision');
require('./modules/readmit');
require('./modules/paginate');
require('./modules/about');
require('./modules/accessCode');

const MODULE_NAME = 'ad-chai';

let en_translations = {
    submit: 'Submit',
    Patient: 'Patient',
    Client: 'Client',
    yes: 'Yes',
    no: 'No',
    ok: 'OK',
    cancel: 'Cancel',
    prev: 'Previous',
    next: 'Next',
    history: 'History',
    print: 'Print',
    back: 'Back',
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    d: 'd',
    w: 'w',
    y: 'y',
    updated: 'Updated:',
    header_1: 'Logged in as',
    header_2: 'My List',
    header_3: 'Summary',
    header_4: 'CHAI Individual Summary',
    footer_2: 'User Guide & FAQ',
    footer_3: 'Terms & Conditions',
    footer_4: 'Copyright &copy; Science &amp; Engineering Applications Ltd',
    summary_1: 'Covid Status: ',
    summary_2: 'Last updated by ',
    dash_1: 'Important Information',
    dash_2: 'Allergies',
    dash_3: 'Concern or help',
    dash_4: 'Forms & PDFs',
    dash_5: 'Covid-19',
    dash_6: 'Details',
    dash_7: 'Assessment Tools',
    dash_8: 'Assistance',
    notes_1: 'Full Notes',
    notes_2: 'Choose Document',
    notes_3: 'Choose Image',
    notes_4: 'User Notes',
    notes_5: 'Generated Notes',
    notes_6: 'Attachments',
    notes_7: 'Tasks',
    notes_8: 'Reset',
    notes_9: '{{ label }} Notes',
    notes_10: 'View Full {{ label }} Notes ',
    notes_11: 'Tap here to add your note',
    notes_12: 'Are you sure you want to complete this task?',
    notes_13: 'Confirm Task Completion',
    notes_14: 'This task is due in',
    notes_15: 'Attachment',
    notes_16: 'Audio Note',
    notes_17: 'Image',
    notes_18: 'Task',
    form_1: 'This form can (or may already) be shared with a third party. Use the button below to manage options.',
    form_2: 'Submit and Manage Sharing Options',
    form_3: 'Manage Sharing Options',
    form_4: 'Unsaved changes present. Would you like to navigate away?',
    form_5: 'Confirm Navigate Away',
    careplan_1: 'Please enter a reason for deactivating the {{ label }}',
    careplan_2: 'Deactivate {{ label }}',
    careplan_3: 'Activated {{ label }} (if any)',
    careplan_4: 'Activate {{ label }} Below',
    careplan_5: 'Jump to Previously Active {{ label }}',
    careplan_6: 'Previously Active {{ label }} (if any)',
    careplan_7: 'Care Plans',
    careplan_8: 'Health Diary',
    patient_1: 'Preferred Language: ',
    patient_2: 'No reason specified yet',
    patient_3: 'Covid Status: '


};

let cy_translations = {
    cancel: 'Canslo',
header_4: 'Crynodeb Unigol CHAI',
header_2: 'Fy Rhestr',
prev: 'Blaenorol',
header_3: 'Crynodeb',
footer_3: 'Telerau ac Amodau',
footer_2: 'Canllaw Defnyddiwr & Cwestiynau Cyffredin',
notes_10: 'Gweld Nodiadau Llawn {{label}}',
notes_11: 'Tap yma i ychwanegu eich nodyn',
notes_12: 'Ydych chi\'n siŵr eich bod chi am gyflawni\'r dasg hon?',
footer_4: 'Hawlfraint a chopi; Gwyddoniaeth &amp; Ceisiadau Peirianneg Cyf',
Client: 'Cleient',
form_5: 'Cadarnhau Llywio i Ffwrdd',
form_4: 'Newidiadau heb eu cadw yn bresennol. Hoffech chi lywio i ffwrdd?',
form_3: 'Rheoli Opsiynau Rhannu',
form_2: 'Cyflwyno a Rheoli Opsiynau Rhannu',
form_1: 'Gellir (neu efallai eisoes) y ffurflen hon ei rhannu â thrydydd parti. Defnyddiwch y botwm isod i reoli opsiynau.',
history: 'Hanes',
notes_13: 'Cadarnhau Cwblhau Tasg',
notes_14: 'Mae\'r dasg hon yn ddyledus yn',
notes_15: 'Ymlyniad',
notes_16: 'Nodyn Sain',
notes_17: 'Delwedd',
notes_18: 'Tasg',
next: 'Nesaf',
careplan_3: 'Wedi\'i actifadu {{label}} (os oes un)',
no: 'Na',
days: 'Dyddiau',
hours: 'Oriau',
minutes: 'munudau',
d: 'd',
w: 'w',
y: 'm',
careplan_4: 'Actifadu {{label}} Isod',
careplan_1: 'Rhowch reswm dros ddadactifadu\'r {{label}}',
careplan_2: 'Deactivate {{label}}',
submit: 'Cyflwyno',
back: 'Yn ôl',
notes_7: 'Tasgau',
notes_6: 'Atodiadau',
notes_9: 'Nodiadau {{label}}',
notes_8: 'Ail gychwyn',
notes_1: 'Nodiadau Llawn',
ok: 'iawn',
updated: 'Wedi\'i ddiweddaru:',
notes_3: 'Dewiswch Delwedd',
notes_2: 'Dewiswch Dogfen',
notes_5: 'Nodiadau a Gynhyrchir',
careplan_5: 'Neidio i Actif Blaenorol {{label}}',
notes_4: 'Nodiadau Defnyddiwr',
careplan_6: 'Yn flaenorol yn weithredol {{label}} (os oes un)',
careplan_7: 'cynllun gofal',
careplan_8: 'Dyddiadur Iechyd',
summary_2: 'Diweddarwyd ddiwethaf gan',
summary_1: 'Statws Covid : ',
yes: 'Ydw',
Patient: 'Claf',
print: 'Argraffu',
dash_2: 'Alergeddau',
dash_1: 'Gwybodaeth Pwysig',
dash_4: 'Ffurflenni a PDFs',
dash_3: 'Pryder neu Gymorth',
dash_6: 'Manylion',
dash_7: 'Offer Asesu',
dash_8: 'Cymorth Dyddiol',
header_1: 'Mewngofnodi fel',
dash_5: 'Covid-19',
patient_1: 'Yr Iaith a Ffefrir: ',
patient_2: 'Dim rheswm wedi\'i nodi eto',
patient_3: 'Statws Covid:'
};

let hi_translations = {cancel: 'रद्द करना',
header_4: 'चाय व्यक्तिगत सारांश',
header_2: 'मेरी सूची',
prev: 'पहले का',
updated: 'hindi Updated:',
header_3: 'सारांश',
footer_3: 'नियम एवं शर्तें',
footer_2: 'उपयोगकर्ता गाइड',
notes_10: 'पूरा देखें {{लेबल}} नोट',
notes_11: 'अपना नोट जोड़ने के लिए यहां टैप करें',
notes_12: 'क्या आप वाकई इस कार्य को पूरा करना चाहते हैं?',
footer_4: 'कॉपीराइट और कॉपी; विज्ञान और amp; इंजीनियरिंग एप्लीकेशन लिमिटेड',
Client: 'ग्राहक',
form_5: 'दूर नेविगेट करने की पुष्टि करें',
form_4: 'सहेजे नहीं गए परिवर्तन मौजूद हैं। क्या आप दूर नेविगेट करना चाहेंगे?',
form_3: 'साझाकरण विकल्प प्रबंधित करें',
form_2: 'साझाकरण विकल्प सबमिट करें और प्रबंधित करें',
form_1: 'यह फ़ॉर्म किसी तीसरे पक्ष के साथ साझा किया जा सकता है (या हो सकता है)। विकल्पों को प्रबंधित करने के लिए नीचे दिए गए बटन का उपयोग करें।',
history: 'इतिहास',
notes_13: 'कार्य पूर्ण होने की पुष्टि करें',
notes_14: 'Hindi This task is due in',
notes_15: 'Attachment',
notes_16: 'Audio Note',
notes_17: 'Image',
notes_18: 'Hindi Task',
next: 'अगला',
days: 'hindi Days',
hours: 'hindi Hours',
minutes: 'hindi Minutes',
d: 'hd',
w: 'hw',
y: 'hy',
careplan_3: 'सक्रिय {{लेबल}} (यदि कोई हो)',
no: 'नहीं',
careplan_4: 'नीचे {{लेबल}} सक्रिय करें',
careplan_1: 'कृपया {{लेबल}} को निष्क्रिय करने का कारण दर्ज करें',
careplan_2: 'निष्क्रिय करें {{लेबल}}',
submit: 'प्रस्तुत करना',
back: 'वापस',
notes_7: 'कार्य',
notes_6: 'संलग्नक',
notes_9: '{{लेबल}} नोट',
notes_8: 'रीसेट',
notes_1: 'पूर्ण नोट्स',
ok: 'ठीक है',
notes_3: 'छवि चुनें',
notes_2: 'दस्तावेज़ चुनें',
notes_5: 'उत्पन्न नोट्स',
careplan_5: 'पहले सक्रिय {{लेबल}} पर जाएं',
notes_4: 'उपयोगकर्ता नोट्स',
careplan_6: 'पहले सक्रिय {{लेबल}} (यदि कोई हो)',
careplan_7: 'Care Plans',
careplan_8: 'Health Diary',
summary_2: 'पिछली बार अपडेट किया गया',
summary_1: 'कोविड स्थिति : ',
yes: 'हाँ',
Patient: 'मरीज़',
print: 'छाप',
dash_2: 'एलर्जी',
dash_1: 'महत्वपूर्ण सूचना',
dash_4: 'फॉर्म और पीडीएफ',
dash_3: 'चिंता या मदद',
dash_6: 'विवरण',
dash_7: 'HI Assessment Tools',
dash_8: 'HI Daily Assistance',
header_1: 'के रूप में लॉग इन किया',
dash_5: 'कोविड -19',
patient_1: 'Hindi Preferred Language: ',
patient_2: 'Hindi No reason specified yet',
patient_3: 'Hindi Covid Status: '
};


var chaiApp = angular.module(MODULE_NAME, [
    'ui.router',
    'ngSanitize', 'chai.markdown', 'widgets', 'nav', 'cards', 'progress', 'glyphs',
    'patient', 'exception', 'random', 'animate', 'server', 'login', 'input',
    'print', 'angularMoment', 'responseInterceptor', 'nvd3', 'messages',
    'ngWebSocket', 'ws', 'nhs', 'matchmedia-ng', 'cleverCards', 'utils',
    'repeatingForms', 'reject', 'actions', 'concerns', 'media', 'task', 'audioInput',
    'ngFileUpload', 'history', 'signature', 'fields', 'constants', 'alerts',
    'search', 'materialComponentsTable', 'supervision', 'ngCookies', 'ngAria', 'ngAnimate', 'ngMaterial',
    'readmit', 'paginate', 'about', 'angular.img', 'ngIdle', 'angular-page-visibility',
    'md-steppers', 'accessCode', 'pascalprecht.translate',
])


    .config(function (IdleProvider) {
        IdleProvider.idle(30);
        IdleProvider.timeout(600);
    })

    .config(function ($translateProvider) {
        $translateProvider.translations('en', en_translations);
        $translateProvider.translations('cy', cy_translations);
        $translateProvider.translations('hi', hi_translations);
        $translateProvider.preferredLanguage('en');
    })

    .config(function ($httpProvider) {

        $httpProvider.useApplyAsync(true);

        $httpProvider.defaults.withCredentials = true;


        $httpProvider.interceptors.push(function ($q) {
            'ngInject';
            return {
                response(response) {
                    return response || $q.when(response);
                },
                responseError(rejection) {
                    return $q.reject(rejection);
                }
            };
        });
        $httpProvider.interceptors.push('ResponseInterceptor');
    })

    .config(function ($mdDateLocaleProvider) {

        $mdDateLocaleProvider.parseDate = function (dateString) {
            var m = moment(dateString, 'DD/MM/YYYY', true);
            return m.isValid() ? m.toDate() : new Date(NaN);
        };

        $mdDateLocaleProvider.formatDate = function (date) {
            return date ? moment(date).format('DD/MM/YYYY') : '';
        };
    })

    .config(['$mdGestureProvider', function ($mdGestureProvider) {
        $mdGestureProvider.skipClickHijack();
    }])

    .config(function ($mdThemingProvider, $provide) {

        // $mdThemingProvider.generateThemesOnDemand(true);
        $provide.value('themeProvider', $mdThemingProvider);


        $mdThemingProvider.definePalette('traineePalette', {
            '50': 'ffffff',
            '100': '8949b4',
            '200': '8949b4',
            '300': '8f5bb3',
            '400': 'ac7bcc',
            '500': '8949b4',
            '600': 'b289d4',
            '700': 'dfb6fa',
            '800': 'd5d5d5',
            '900': '8949b4',
            '1000': 'd3a1ff',
            '1100': '47067a',
            '1200': '8949b4',
            '1300': 'b289d4',
            'A100': 'eedaff',
            'A200': '8f5bb3',
            'A400': 'b893d7',
            'A700': 'ce96ff',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': ['50', '100',
                '200', '300', '400', 'A100'],
            'contrastLightColors': undefined
        });

        $mdThemingProvider.definePalette('defaultPalette', {
            '50': 'ffffff',
            '100': '6aafbf',
            '200': '56a1b0',
            '300': '56a1b0',
            '400': '2ba6db',
            '500': '65ccdb',
            '600': '2ba6cb',
            '700': '4fd1e7',
            '800': 'bfe2e8',
            '900': '356883',
            '1000': 'bceff7',
            '1100': '56a1b0',
            '1200': '4fd1e7',
            '1300': '65ccdb',
            'A100': 'dcfaff',
            'A200': '44b0d1',
            'A400': '9dbec3',
            'A700': 'a6d3d9',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': ['50', '100',
                '200', '300', '400', 'A100'],
            'contrastLightColors': undefined
        });

        $mdThemingProvider.theme('default')
            .primaryPalette('defaultPalette');


    })

    .config(function (MarkdownTransformProvider) {
        // give all anchor tags a blank target
        MarkdownTransformProvider
            .post(function (element) {
                var anchors = element.find('a');

                angular.forEach(anchors, function (a) {
                    //external links should start in new window.
                    if (a.getAttribute('href').startsWith('http')) {
                        a.setAttribute('target', '_blank');
                    }
                });

                return element;
            });

        MarkdownTransformProvider
            .pre(function (markdown, scope) {
                // do some stuff before the markdown is rendered.
                // e.g. convert strong to emphasis
                //console.log('markdown: ' + markdown);
                //console.log('scope: ' + JSON.stringify(scope));
                return markdown.replace('#patient#', scope.patientId);
            });
    })

    .config(['matchmediaProvider', function (matchmediaProvider) {
        matchmediaProvider.rules.phone = '(max-width: 500px)';
    }])

    .config(function ($compileProvider) {
        $compileProvider.debugInfoEnabled(false);
    })

    .config(function ($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.otherwise('/');

        $stateProvider
            .state('home', require('./routes/home'))
            .state('login', require('./routes/login'))
            .state('signofflogin', require('./routes/signofflogin'))
            .state('signofflogout', require('./routes/signofflogout'))
            .state('repopulate', require('./routes/repopulate'))
            .state('logout', require('./routes/logout'))
            .state('messages', require('./routes/messages'))
            .state('countersignnote', require('./routes/countersignnote'))
            .state('search', require('./routes/search'))
            .state('tasks', require('./routes/tasks'))
            .state('batchsignoff', require('./routes/batchsignoff'))
            .state('import', require('./routes/import'))
            .state('exception', require('./routes/exception'))
            .state('patient', require('./routes/patient'))
            .state('patient.dash', require('./routes/patient/dash'))
            .state('patient.messages', require('./routes/patient/messages'))
            .state('patient.assessment', require('./routes/patient/assessment'))
            .state('patient.staticforms', require('./routes/patient/staticforms'))
            .state('patient.careplans', require('./routes/patient/careplans'))
            .state('patient.formhistory', require('./routes/patient/formhistory'))
            .state('patient.form', require('./routes/patient/form'))
            .state('patient.group', require('./routes/patient/group'))
            .state('patient.card', require('./routes/patient/card'))
            .state('patient.dmd', require('./routes/patient/codegeneration'))
            .state('patient.managecodes', require('./routes/patient/managecodes'));
    })

    .run(function ($rootScope, $state, $location, ChaiWebSocket, Idle, $pageVisibility, Cards) {

        $rootScope.initCards = function() {
            return Cards
                .get('admissions')
                .then(form => {
                        $rootScope.loaded=false;
                        for (let group of form.groups) {
                            for (let card of group.cards) {
                                if (card.name === 'patient_details') {
                                    for (let field of card.fields) {
                                        if (field.name === 'hospital_number') {
                                            $rootScope.isHospitalNumberPresent = true;
                                        }
                                        if (field.name === 'frailty_score') {
                                            $rootScope.useFrailtyGlyphs = true;
                                        }
                                    }
                                }
                                if (card.name === 'reason_for_admission') {
                                    $rootScope.admissionReasonLabelName = card.label;
                                }
                            }
                        }
                        $rootScope.loaded=true;
                        return null;
                    }
                );
        };

        $pageVisibility.$on('pageFocused', function () {
            $rootScope.updateToken();
        });
        $pageVisibility.$on('active', function () {
            $rootScope.updateToken();
        });

        Idle.watch();

        let homeRedirectURLS = ['/countersignnote', '/repopulate', '/signofflogin', '/signofflogout'];


        if (!$rootScope.username) {
            let nextUrl = $location.url();
            if (nextUrl == '/login' || !nextUrl) {
                return;
            }

            $rootScope.pastUrl = homeRedirectURLS.includes(nextUrl) ? '/' : nextUrl;
            ChaiWebSocket.stop();
            $state.go('login');
        }


        moment.updateLocale('cy',
           {
        months: 'Ionawr_Chwefror_Mawrth_Ebrill_Mai_Mehefin_Gorffennaf_Awst_Medi_Hydref_Tachwedd_Rhagfyr'.split(
            '_'
        ),
        monthsShort: 'Ion_Chwe_Maw_Ebr_Mai_Meh_Gor_Aws_Med_Hyd_Tach_Rhag'.split(
            '_'
        ),
        weekdays: 'Dydd Sul_Dydd Llun_Dydd Mawrth_Dydd Mercher_Dydd Iau_Dydd Gwener_Dydd Sadwrn'.split(
            '_'
        ),
        weekdaysShort: 'Sul_Llun_Maw_Mer_Iau_Gwe_Sad'.split('_'),
        weekdaysMin: 'Su_Ll_Ma_Me_Ia_Gw_Sa'.split('_'),
        weekdaysParseExact: true,
        // time formats are the same as en-gb
        longDateFormat: {
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            L: 'DD/MM/YYYY',
            LL: 'D MMMM YYYY',
            LLL: 'D MMMM YYYY HH:mm',
            LLLL: 'dddd, D MMMM YYYY HH:mm'
        },
        calendar: {
            sameDay: '[Heddiw am] LT',
            nextDay: '[Yfory am] LT',
            nextWeek: 'dddd [am] LT',
            lastDay: '[Ddoe am] LT',
            lastWeek: 'dddd [diwethaf am] LT',
            sameElse: 'L'
        },
        relativeTime: {
            future: 'mewn %s',
            past: '%s yn ôl',
            s: 'ychydig eiliadau',
            ss: '%d eiliad',
            m: 'munud',
            mm: '%d munud',
            h: 'awr',
            hh: '%d awr',
            d: 'diwrnod',
            dd: '%d diwrnod',
            M: 'mis',
            MM: '%d mis',
            y: 'blwyddyn',
            yy: '%d flynedd',
        },
        dayOfMonthOrdinalParse: /\d{1,2}(fed|ain|af|il|ydd|ed|eg)/,
        // traditional ordinal numbers above 31 are not commonly used in colloquial Welsh
        ordinal: function (number) {
            var b = number,
                output = '',
                lookup = [
                    '',
                    'af',
                    'il',
                    'ydd',
                    'ydd',
                    'ed',
                    'ed',
                    'ed',
                    'fed',
                    'fed',
                    'fed', // 1af to 10fed
                    'eg',
                    'fed',
                    'eg',
                    'eg',
                    'fed',
                    'eg',
                    'eg',
                    'fed',
                    'eg',
                    'fed', // 11eg to 20fed
                ];
            if (b > 20) {
                if (b === 40 || b === 50 || b === 60 || b === 80 || b === 100) {
                    output = 'fed'; // not 30ain, 70ain or 90ain
                } else {
                    output = 'ain';
                }
            } else if (b > 0) {
                output = lookup[b];
            }
            return number + output;
        },
        week: {
            dow: 1, // Monday is the first day of the week.
            doy: 4, // The week that contains Jan 4th is the first week of the year.
        },
        });

           var symbolMap = {
            1: '१',
            2: '२',
            3: '३',
            4: '४',
            5: '५',
            6: '६',
            7: '७',
            8: '८',
            9: '९',
            0: '०',
        },
        numberMap = {
            '१': '1',
            '२': '2',
            '३': '3',
            '४': '4',
            '५': '5',
            '६': '6',
            '७': '7',
            '८': '8',
            '९': '9',
            '०': '0',
        },
        monthsParse = [
            /^जन/i,
            /^फ़र|फर/i,
            /^मार्च/i,
            /^अप्रै/i,
            /^मई/i,
            /^जून/i,
            /^जुल/i,
            /^अग/i,
            /^सितं|सित/i,
            /^अक्टू/i,
            /^नव|नवं/i,
            /^दिसं|दिस/i,
        ],
        shortMonthsParse = [
            /^जन/i,
            /^फ़र/i,
            /^मार्च/i,
            /^अप्रै/i,
            /^मई/i,
            /^जून/i,
            /^जुल/i,
            /^अग/i,
            /^सित/i,
            /^अक्टू/i,
            /^नव/i,
            /^दिस/i,
        ];


        moment.updateLocale('hi',
           {
        months: {
            format: 'जनवरी_फ़रवरी_मार्च_अप्रैल_मई_जून_जुलाई_अगस्त_सितम्बर_अक्टूबर_नवम्बर_दिसम्बर'.split(
                '_'
            ),
            standalone: 'जनवरी_फरवरी_मार्च_अप्रैल_मई_जून_जुलाई_अगस्त_सितंबर_अक्टूबर_नवंबर_दिसंबर'.split(
                '_'
            ),
        },
        monthsShort: 'जन._फ़र._मार्च_अप्रै._मई_जून_जुल._अग._सित._अक्टू._नव._दिस.'.split(
            '_'
        ),
        weekdays: 'रविवार_सोमवार_मंगलवार_बुधवार_गुरूवार_शुक्रवार_शनिवार'.split('_'),
        weekdaysShort: 'रवि_सोम_मंगल_बुध_गुरू_शुक्र_शनि'.split('_'),
        weekdaysMin: 'र_सो_मं_बु_गु_शु_श'.split('_'),
        longDateFormat: {
            LT: 'A h:mm बजे',
            LTS: 'A h:mm:ss बजे',
            L: 'DD/MM/YYYY',
            LL: 'D MMMM YYYY',
            LLL: 'D MMMM YYYY, A h:mm बजे',
            LLLL: 'dddd, D MMMM YYYY, A h:mm बजे',
        },

        monthsParse: monthsParse,
        longMonthsParse: monthsParse,
        shortMonthsParse: shortMonthsParse,

        monthsRegex: /^(जनवरी|जन\.?|फ़रवरी|फरवरी|फ़र\.?|मार्च?|अप्रैल|अप्रै\.?|मई?|जून?|जुलाई|जुल\.?|अगस्त|अग\.?|सितम्बर|सितंबर|सित\.?|अक्टूबर|अक्टू\.?|नवम्बर|नवंबर|नव\.?|दिसम्बर|दिसंबर|दिस\.?)/i,

        monthsShortRegex: /^(जनवरी|जन\.?|फ़रवरी|फरवरी|फ़र\.?|मार्च?|अप्रैल|अप्रै\.?|मई?|जून?|जुलाई|जुल\.?|अगस्त|अग\.?|सितम्बर|सितंबर|सित\.?|अक्टूबर|अक्टू\.?|नवम्बर|नवंबर|नव\.?|दिसम्बर|दिसंबर|दिस\.?)/i,

        monthsStrictRegex: /^(जनवरी?|फ़रवरी|फरवरी?|मार्च?|अप्रैल?|मई?|जून?|जुलाई?|अगस्त?|सितम्बर|सितंबर|सित?\.?|अक्टूबर|अक्टू\.?|नवम्बर|नवंबर?|दिसम्बर|दिसंबर?)/i,

        monthsShortStrictRegex: /^(जन\.?|फ़र\.?|मार्च?|अप्रै\.?|मई?|जून?|जुल\.?|अग\.?|सित\.?|अक्टू\.?|नव\.?|दिस\.?)/i,

        calendar: {
            sameDay: '[आज] LT',
            nextDay: '[कल] LT',
            nextWeek: 'dddd, LT',
            lastDay: '[कल] LT',
            lastWeek: '[पिछले] dddd, LT',
            sameElse: 'L',
        },
        relativeTime: {
            future: '%s में',
            past: '%s पहले',
            s: 'कुछ ही क्षण',
            ss: '%d सेकंड',
            m: 'एक मिनट',
            mm: '%d मिनट',
            h: 'एक घंटा',
            hh: '%d घंटे',
            d: 'एक दिन',
            dd: '%d दिन',
            M: 'एक महीने',
            MM: '%d महीने',
            y: 'एक वर्ष',
            yy: '%d वर्ष',
        },
        preparse: function (string) {
            return string.replace(/[१२३४५६७८९०]/g, function (match) {
                return numberMap[match];
            });
        },
        postformat: function (string) {
            return string.replace(/\d/g, function (match) {
                return symbolMap[match];
            });
        },
        // Hindi notation for meridiems are quite fuzzy in practice. While there exists
        // a rigid notion of a 'Pahar' it is not used as rigidly in modern Hindi.
        meridiemParse: /रात|सुबह|दोपहर|शाम/,
        meridiemHour: function (hour, meridiem) {
            if (hour === 12) {
                hour = 0;
            }
            if (meridiem === 'रात') {
                return hour < 4 ? hour : hour + 12;
            } else if (meridiem === 'सुबह') {
                return hour;
            } else if (meridiem === 'दोपहर') {
                return hour >= 10 ? hour : hour + 12;
            } else if (meridiem === 'शाम') {
                return hour + 12;
            }
        },
        meridiem: function (hour, minute, isLower) {
            if (hour < 4) {
                return 'रात';
            } else if (hour < 10) {
                return 'सुबह';
            } else if (hour < 17) {
                return 'दोपहर';
            } else if (hour < 20) {
                return 'शाम';
            } else {
                return 'रात';
            }
        },
        week: {
            dow: 0, // Sunday is the first day of the week.
            doy: 6, // The week that contains Jan 6th is the first week of the year.
        },
        });

    });

export default chaiApp;
