module.exports = {
    url: '/messages/:filter',
    params: {
        filter: {squash: true, value: null}
    },
    views: {
        main: {
            controller: function ($scope, $window, Pagination, $state, $rootScope) {
                'ngInject';

                $scope.patientId = $scope.$parent.patient.id;
                $scope.patient = $scope.$parent.patient;
                Pagination.reset();

                $window.document.title = 'CHAI ' + $rootScope.patientLabel + ' Notes';

                console.log('type is ' + $state.params.filter);
                $scope.filter = $state.params.filter;

            },
            template: `
  <div layout='row'>
     <channel-notes flex channel="patientId" username="username" filter="filter">
     </channel-notes>
  </div>
  `
        },
        title: {
            template: `
  Notes
    `
        }
    }
};
