angular.module('server', ['ngResource', 'ui.router', 'login'])

    .service('ConfigLoader', function ($http, $q, $location) {
        this.cache = {};
        var cl = this;

        this.load = function (fileName) {
            let deferred = $q.defer();

            if (this.cache[fileName]) {
                deferred.resolve(this.cache[fileName]);
            } else {
                $http.head(`config/${fileName}`).then(
                    function success() {
                        cl.serverConfig(fileName, deferred);
                    },
                    function error() {
                        deferred.reject('Could not load config');
                    }
                );
            }
            return deferred.promise;
        };

        cl.serverConfig = function (fileName, deferred) {
            $http.get(`config/${fileName}`)
                .then(function onSuccess(response) {
                    if (response.data.url.indexOf($location.host()) !== -1) {
                        response.data.cookieCheck = true;
                    }
                    deferred.resolve(response.data);
                    cl.cache[fileName] = response.data;
                }).catch(function onError(response) {
                console.error(`Error loading /config/${fileName}`);
                deferred.reject(response);
            });
        };

    })

    .service('Server', function ($q, $resource, $http, $state, ConfigLoader,
                                 TokenHandler, $rootScope, $location, themeProvider,
                                 $mdTheming, $mdColors) {
        var server = this;
        this.__config__ = null;
        this.__authToken__ = null;

        // this.__authToken__ = TokenHandler.get()
        if (this.__authToken__) {
            //console.log('Found existing token');
        }

        this.deleteAuthToken = function () {
            //console.log('delete auth token');
            this.__authToken__ = null;
            this.getConfig().then(config => {
                $http.post(`${this.createUrl(config)}/auth/logout`).then(function onSuccess() {
                    //console.log('removed cookie');
                }).catch(() => {
                });
            });
        };

        /**
         *
         */
        this.getConfig = function () {
            let deferred = $q.defer();

            if (this.__config__) {
                deferred.resolve(this.__config__);
            } else {
                ConfigLoader.load('config.json')
                    .then(function (config) {
                        config.accountManagementUrl = config.accountManagementUrl || '/keycloak/auth/realms/chai/account/';
                        config.userManagementUrl = config.userManagementUrl || '/management/';
                        this.__config__ = config;
                        $rootScope.cookieCheck = config.cookieCheck;
                        deferred.resolve(config);
                    }.bind(this))
                    .catch(function (err) {
                        deferred.reject(err);
                    });
            }

            return deferred.promise;
        };

        this.getWebSocketUrl = function () {

            let deferred = $q.defer();

            this.getConfig()
                .then(config => {
                    deferred.resolve(`${config.socket}/socket`);
                });

            return deferred.promise;
        };

        this.getTime = function () {

            let deferred = $q.defer();

            this.getConfig().then(config => {
                $http.get(`${config.url}/system/`).then(data => {
                    deferred.resolve(data.data.currentTimeMillis);
                }).catch(() => {
                    deferred.reject();
                })
            });

            return deferred.promise;
        }

        this.ping = function () {

            let deferred = $q.defer();

            this.getConfig().then(config => {
                $http.head(`${config.url}/system/`, {timeout: 2000}).then(data => {
                    deferred.resolve();
                }).catch(() => {
                    deferred.reject();
                })
            });

            return deferred.promise;
        }

        this.getAuthToken = function () {
            let deferred = $q.defer();
            if (this.__config__) {
                this.getConfig()
                    .then(() => {
                        deferred.resolve({
                            token: this.__authToken__,
                            config: this.__config__
                        });
                    });
            } else {
                deferred.reject('User is not authenticated');
            }

            return deferred.promise;
        };

        server.useAuthToken = function (token) {
            this.__authToken__ = token;
            TokenHandler.set(token);
        };

        /**
         *
         */
        this.createAuthToken = function (login) {
            let deferred = $q.defer();

            const grantToken = () => deferred.resolve({
                token: this.__authToken__,
                config: this.__config__
            });

            this.getConfig()
                .then(config => {
                    $http.get(`${this.createUrl(config)}/auth/login`)
                        .then(function onSuccess(response) {
                            console.log('login success handler');
                            var auth = response.data;
                            // this should be fixed at the server side
                            if (auth.token === 'no') {
                                deferred.reject('Invalid username or password <a href=/keycloak/auth/realms/chai/account/>To Reset Password</a>');
                            } else {

                                if ('theme' in auth.prefs) {
                                    let palette = auth.prefs.theme + 'Palette'
                                    themeProvider.theme('default')
                                        .primaryPalette(palette)

                                    // $mdTheming.generateTheme('default');
                                    // themeProvider.setDefaultTheme('default');

                                } else {
                                    themeProvider.theme('default')
                                        .primaryPalette('defaultPalette')
                                }

                                let jss = require('jss').default
                                let preset = require('jss-preset-default').default

                                jss.setup(preset())

                                const style = {
                                    '@global': {

                                        'a, a:hover': {
                                            color: $mdColors.getThemeColor('primary-600')
                                        },

                                        '.create-message__notes i, .create-message__notes span': {
                                            color: $mdColors.getThemeColor('primary-A200')
                                        },

                                        '.create-message__notes i.deactivate': {
                                            color: $mdColors.getThemeColor('primary-A400')
                                        },

                                        '.notes-filters-vertical__filter--selected': {
                                            color: $mdColors.getThemeColor('primary-A200')
                                        },

                                        '.notes-filters-vertical--selected': {
                                            'border-color': $mdColors.getThemeColor('primary-500')
                                        },

                                        '.notes-filters-horizontal__filter--selected': {
                                            'border-color': $mdColors.getThemeColor('primary-500'),
                                            color: $mdColors.getThemeColor('primary-500')
                                        },

                                        '.notes__text-note--self, .notes__text-note--self::before, .notes__text-note--self::after': {
                                            'border-color': $mdColors.getThemeColor('primary-500')
                                        },

                                        '.notes__info__author--self': {
                                            'color': $mdColors.getThemeColor('primary-500')
                                        },

                                        '.notes__info__time': {
                                            'color': $mdColors.getThemeColor('primary-200')
                                        },

                                        'label.focus': {
                                            'color': $mdColors.getThemeColor('primary-200')
                                        },

                                        '.card.static, .card.repeating, .section-bg': {
                                            'background': 'linear-gradient(to right, ' + $mdColors.getThemeColor('primary-200') + ', ' + $mdColors.getThemeColor('primary-1300') + ')'
                                        },

                                        '.card .icon3-radio-checked, .card .icon3-radio-blank': {
                                            'color': $mdColors.getThemeColor('primary-100')
                                        },

                                        '.audio-note md-slider .md-track.md-track-fill': {
                                            'background-color': $mdColors.getThemeColor('primary-500')
                                        },

                                        '.audio-note md-slider .md-disabled-thumb': {
                                            'background-color': $mdColors.getThemeColor('primary-500'),
                                            'border-color': $mdColors.getThemeColor('primary-500')
                                        }
                                    }
                                }

                                const sheet = jss.createStyleSheet(style)
                                sheet.attach()


                                $rootScope.username = auth.username;
                                $rootScope.views = auth.views;
                                $rootScope.trainee = auth.permissions.includes('patient_write') && !auth.permissions.includes('patient_approve');
                                $rootScope.canSubmitForms = auth.permissions.includes('patient_write');
                                $rootScope.canApproveForms = auth.permissions.includes('patient_approve');
                                $rootScope.canSendMessages = auth.permissions.includes('message_write');
                                $rootScope.canAddPatients = auth.permissions.includes('patient_create');
                                $rootScope.canSearchPatients = auth.permissions.includes('patient_write');
                                $rootScope.signoff = false;
                                $rootScope.signoffLater = {value: false};
                                auth.views.forEach(group => {
                                    if (group.data.type == 'community') {
                                        $rootScope.community = group.data.name;
                                    }
                                })

                                if (auth.hasOwnProperty('fullName')) {
                                    $rootScope.fullName = auth.fullName;
                                } else {
                                    $rootScope.fullName = auth.username;
                                }

                                $rootScope.unreadStatus = {'ward': false};
                                auth.unread.forEach(function (channel) {
                                    $rootScope.unreadStatus[channel] = true;
                                });
                                server.useAuthToken(auth.token);
                                grantToken();
                            }
                        }, function onError(response) {
                            console.log('login error handler');
                            if (response.status == -1) {
                                deferred.reject('The server is currently unavailable');
                            } else if (response.status == 500) {
                                deferred.reject('Invalid username or password <a href=/keycloak/auth/realms/chai/account/>To Reset Password</a');
                            } else {
                                deferred.reject('Invalid username or password <a href=/keycloak/auth/realms/chai/account/>To Reset Password</a');
                            }
                        });
                })
                .catch(err => {
                    deferred.reject(err);
                });

            return deferred.promise;
        };
        /**
         *
         */
        this.createUrl = function (config) {
            return `${config.url}`;
        };

        /**
         *
         */
        this.createResource = function (name, actions) {
            let deferred = $q.defer();

            this.getAuthToken()
                .then((auth) => {

                    const {config} = auth;
                    const baseUrl = this.createUrl(config);
                    const url = `${baseUrl}${name}`;
                    const paramDefaults = {id: '@id'};

                    // by default new actions for ngResource will not use
                    // the same base url as the other actions on resource
                    // e.g. /patients ends up mapping to localhost/patients
                    // rather than some ip. We can fix this by throwing
                    // the url from config infront of each property.
                    let mappedActions = {};
                    Object.keys(actions).forEach(name => {
                        let action = actions[name];
                        let url = {url: baseUrl + action.url};

                        mappedActions[name] = angular.extend(action, url);
                    });
                    deferred.resolve($resource(url, paramDefaults, mappedActions));

                })
                .catch(err => {
                    //failed to get auth token, so redirect
                    $rootScope.pastUrl = $location.url();
                    $state.go('login');
                    $rootScope.noExceptionHandler = true;
                    deferred.reject(err);

                });

            return deferred.promise;

        };

        /**
         * THIS IS NEVER CALLED
         this.createRequest = function(requestConfig) {
    let deferred = $q.defer();

    if (needsAuth) {
      this.getAuthToken()
        .then((auth) => {
          const { config } = auth;

          requestConfig.url = this.createUrl(config);

          deferred.resolve($http(requestConfig));
        });
    } else {
      this.getConfig()
        .then((config) => {
          requestConfig.url = this.createUrl(config);
          deferred.resolve($http(requestConfig));
        });
    }

    return deferred.promise;
  };
         */

    });
