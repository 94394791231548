angular.module('ws', [])

    .factory('ChaiWebSocket', function ($websocket, $rootScope, $state, $q, Pagination, signOffInfo) {

        let deferred = $q.defer();
        var ws;

        function stopWS() {
            if (ws !== undefined) {
                ws.socket.close(1000, 'Client closed socket');
            }
        }

        function startWS(url) {

            ws = $websocket(url, null,
                {reconnectIfNotNormalClose: true});

            ws.onOpen(function () {
                console.log('websocket stream open');
            });

            ws.onMessage(function (message) {
                if (message.data == 'X') {
                    return;
                }

                var content = JSON.parse(message.data);
                if (content.channel == $rootScope.community) {
                    content.channel = 'ward';
                }
                if (signOffInfo.capture) {
                    signOffInfo.note = content;
                    signOffInfo.capture = false;
                }
                Pagination.addLatestNote(content.channel);

            });

            ws.onError(function () {
                console.log('error');
            });

            ws.onClose(function () {
                console.log('websocket closed');
            });

            deferred.resolve(ws);


        }

        return {
            stream: deferred.promise,
            start: startWS,
            stop: stopWS
        };
    });
