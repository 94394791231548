/**
 * @name actions
 * @ngdoc module
 * @description
 * Functions for executing different actions
 */

angular.module('actions', [])

    .service('Actions', function (Messages, ConcernStore, $rootScope, cleverCards,
                                  problemIdentified, CardStore, PatientStore, CarePlansStore,
                                  RepeatingFormStore, PatientAlerts, CardsToReset,
                                  ProgressAggregator) {

        this.transform = function (field, info) {
            let carePlansLabel = 'Health Diary';
            let assessmentToolLabel = 'Daily Assistance';

            if ($rootScope.type === 'pro') {
                carePlansLabel = 'Care Plans';
                assessmentToolLabel = 'Assessment Tool';
            }

            if (!(field.action instanceof Array)) {
                field.action = [field.action];
            }

            for (let single_action of field.action) {
                if (single_action.on.includes('value_changed')) {
                    if (single_action.on.split('"').length > 4) {
                        return;
                    }
                    let regex = /value_changed\((\w*)\)/;
                    var arg = single_action.on.match(regex)[1];
                    if (!arg) {
                        arg = field.name;
                    }
                    let formattedArgs = 'value_changed(' + '"' + info.form + '"' + ',' +
                        '"' + info.card + '"' + ',' + '"' + arg + '"' +
                        ',' + arg + ')';
                    single_action.on = single_action.on.replace(regex, formattedArgs);
                }

                if (!single_action.note) {

                    if (single_action.name == 'create_task') {
                        single_action.note = single_action.parameter.description;

                    } else if (single_action.name == 'activate_care_plan') {
                        let careplanName = single_action.parameter;
                        $rootScope.carePlans.find(careplan => {
                            if (careplan.name == careplanName) {
                                careplanName = careplan.label;
                                return true;
                            }
                        })
                        single_action.note = '"' + careplanName + ' ' + carePlansLabel + ' will be activated' + '"';


                    } else if (single_action.name == 'attach_concern') {
                        single_action.note = single_action.parameter;


                    } else if (single_action.name == 'set_due') {
                        let formName = single_action.parameter;
                        let formType = assessmentToolLabel;
                        $rootScope.carePlans.find(form => {
                            if (form.name == formName) {
                                formName = form.label;
                                formType = carePlansLabel;
                                return true;
                            }
                        });

                        $rootScope.repeatingForms.find(form => {
                            if (form.name == formName) {
                                formName = form.label;
                                return true;
                            }
                        });

                        single_action.note = '"' + formName + ' ' + formType + ' will be set as due now' + '"';

                    } else if (single_action.name == 'set_repeat') {
                        let formName = single_action.parameter.form;
                        let formType = assessmentToolLabel;
                        $rootScope.carePlans.find(form => {
                            if (form.name == formName) {
                                formName = form.label;
                                formType = carePlansLabel;
                                return true;
                            }
                        });

                        $rootScope.repeatingForms.find(form => {
                            if (form.name == formName) {
                                formName = form.label;
                                return true;
                            }
                        });

                        let period = this.getRepeatPeriod(single_action.parameter);

                        single_action.note = '"' + formName + ' ' + formType + ' will be set as due in' + period + '"';

                    } else if (single_action.name == 'clear_progress') {
                        var allCards = [];
                        Object.keys(single_action.parameter).forEach(form => {
                            Object.keys(single_action.parameter[form]).forEach(card => {
                                if (single_action.parameter[form][card]) {
                                    $rootScope.admissionsForm.groups.find(adGroup => {
                                        adGroup.cards.find(adCard => {
                                            if (card == adCard.name) {
                                                allCards.push(adCard.label);

                                            }
                                        });
                                    });
                                }
                            });
                        });
                        single_action.note = '"' + 'Progress will be reset on following cards: ' + allCards.join(', ') + '"';
                    }

                }

            }
        };

        this.shouldEvalAction = function (name, condition, expression, localScope, globalScope) {

            let result;
            let message;

            if ((name == 'attach_concern') && (globalScope.hasOwnProperty('cards'))) {

                for (var index in globalScope.cards) {
                    result = cleverCards.evalExpression(condition,
                        localScope,
                        globalScope.cards[index]);
                    if (result) {
                        return {
                            condition: true,
                            message: cleverCards.evalExpression(expression,
                                localScope,
                                globalScope.cards[index])
                        };
                    }
                }

                return {condition: false};

            } else {

                result = cleverCards.evalExpression(condition, localScope, globalScope);

                if (name == 'create_task') {
                    message = cleverCards.evalExpression(expression.description, localScope, globalScope);
                } else {
                    message = cleverCards.evalExpression(expression, localScope, globalScope);
                }
                if (result) {
                    return {
                        condition: true,
                        message: message
                    };
                } else {
                    return {condition: false};
                }


            }

        };


        this.evalAction = function (action, field, specifics, evaledAction) {

            function updateConcern(field, message) {
                ConcernStore.load().then(Concern => {
                    Concern.save({
                            id: $rootScope.patientId,
                            key: field
                        },
                        {
                            message: message,
                            type: specifics.type,
                            info: specifics.info
                        },
                        function () {
                            problemIdentified[field] = {
                                message: message,
                                type: specifics.type,
                                info: specifics.info
                            };
                        }, err => {
                        });
                });
            }

            function removeConcern(field) {
                if (!problemIdentified.hasOwnProperty(field)) {
                    return;
                }
                ConcernStore.load().then(Concern => {
                    Concern.remove({
                        id: $rootScope.patientId,
                        key: field
                    }, function () {
                        delete problemIdentified[field];
                    }, err => {
                    });
                });
            }

            if (action.name === 'send_patient_note' || action.name === 'create_note') {
                if (evaledAction.condition) {
                    let messageContent = {};
                    messageContent.data = evaledAction.message;
                    messageContent.type = 'generated';
                    if (specifics.countersigned) {
                        messageContent.data = 'Countersigned: ' + messageContent.data
                    }
                    Messages.send($rootScope.patientId, messageContent);
                }
            }

            if (action.name === 'create_task') {
                if (evaledAction.condition) {
                    let messageContent = {};
                    messageContent.data = evaledAction.message;
                    messageContent.type = 'task';
                    if (action.parameter.due) {
                        messageContent.due = action.parameter.due;
                    }
                    Messages.send($rootScope.patientId, messageContent);
                }
            }

            if (action.name === 'clear_progress') {
                if (evaledAction.condition) {
                    /*jshint loopfunc:true */
                    CardStore.load().then(Card => {
                        Card.reset({id: $rootScope.patientId}, action.parameter, data => {
                            CardsToReset.value = action.parameter;
                            Object.keys(action.parameter).forEach(form => {
                                ProgressAggregator.update(form, data.progress);
                            })
                        }, err => {
                        });
                    });
                }
            }

            if (action.name === 'set_due') {
                if (evaledAction.condition) {
                    /*jshint loopfunc:true */
                    RepeatingFormStore.load().then(Form => {
                        Form.setDue({id: $rootScope.patientId, form: action.parameter}, {}, (p) => {
                            PatientAlerts.refresh(p.alerts);
                        }, err => {
                        });
                    });

                    let messageContent = {};
                    let message = this.getFormLabel(action.parameter) + ' set due (triggered by ' + specifics.info.formLabel + ' form)';
                    messageContent.data = message;
                    messageContent.type = 'generated';
                    Messages.send($rootScope.patientId, messageContent);
                }
            }

            if (action.name === 'set_repeat') {
                if (evaledAction.condition) {
                    /*jshint loopfunc:true */
                    RepeatingFormStore.load().then(Form => {
                        Form.setRepeat({
                            id: $rootScope.patientId,
                            form: action.parameter.form
                        }, action.parameter.repeat_in, (p) => {
                            PatientAlerts.refresh(p.alerts);
                        }, err => {
                        });
                    });

                    let period = this.getRepeatPeriod(action.parameter);

                    let messageContent = {};
                    let message = this.getFormLabel(action.parameter.form) + ' set to repeat in ' + period + ' (triggered by ' + specifics.info.formLabel + ' form)';
                    messageContent.data = message;
                    messageContent.type = 'generated';
                    Messages.send($rootScope.patientId, messageContent);
                }
            }

            if (action.name === 'activate_care_plan') {
                if (evaledAction.condition) {
                    PatientStore.load().then(Patient => {
                        Patient.get({id: $rootScope.patientId}, function (patient) {
                            CarePlansStore.load().then(CarePlan => {
                                let carePlansLabel = 'Health Diary';                    
                                if ($rootScope.type === 'pro') {
                                    carePlansLabel = 'Care Plans';
                                }
                                CarePlan.update({id: $rootScope.patientId, form: action.parameter},
                                    {value: true});
                                let careplanLabel;
                                $rootScope.carePlans.find(careplan => {
                                    if (careplan.name == action.parameter) {
                                        careplanLabel = careplan.label;
                                        return true;
                                    }
                                });
                                let messageContent = {};
                                let message = careplanLabel + ' ' + carePlansLabel + ' Activated. Reason: ' + specifics.info.formLabel + ': ' + specifics.info.cardLabel;
                                messageContent.data = message;
                                messageContent.type = 'generated';
                                Messages.send($rootScope.patientId, messageContent);

                            });
                        }, err => {
                        });
                    });
                }
            }

            if (action.name === 'attach_concern') {
                let key = specifics.info.form + '--' + specifics.info.card + '--' + field.name;
                if (evaledAction.condition) {
                    let note = evaledAction.message;
                    updateConcern(key, note);
                } else {
                    removeConcern(key);
                }
            }

        };

        this.getRepeatPeriod = function (parameter) {
            let period = '';

            if (parameter.repeat_in.days) {
                period += ' ' + parameter.repeat_in.days + ' days';
            }

            if (parameter.repeat_in.hours) {
                period += ' ' + parameter.repeat_in.hours + ' hours';
            }

            if (parameter.repeat_in.minutes) {
                period += ' ' + parameter.repeat_in.minutes + ' minutes';
            }

            return period;
        };

        this.getFormLabel = function (formName) {

            let formLabel = formName + ' Daily Assistance';
            let carePlansLabel = 'Health Diary';
            let assessmentToolLabel = 'Daily Assistance';

            if ($rootScope.type === 'pro') {
                carePlansLabel = 'Care Plans';
                assessmentToolLabel = 'Assessment Tool';
                $rootScope.carePlans.find(form => {
                    if (form.name == formName) {
                        formLabel = form.label + ' Care Plan';
                        return true;
                    }
                });
                $rootScope.repeatingForms.find(form => {
                    if (form.name == formName) {
                        formLabel = form.label + ' Assessment Tool';
                        return true;
                    }
                });
            }

            $rootScope.carePlans.find(form => {
                if (form.name == formName) {
                    formLabel = form.label + ' Health Diary';
                    return true;
                }
            });

            $rootScope.repeatingForms.find(form => {
                if (form.name == formName) {
                    formLabel = form.label + ' Daily Assistance';
                    return true;
                }
            });

            return formLabel;
        };


        this.perform = function (field, specifics, localScope, globalScope) {

            if (!(field.action instanceof Array)) {
                field.action = [field.action];
            }

            let actionInfo;

            field.action.forEach(action => {

                if (globalScope.hasOwnProperty('cards')) {
                    for (let index in globalScope.cards) {

                        if (action.name == 'attach_concern') {

                            actionInfo = this.shouldEvalAction(action.name,
                                action.on,
                                action.parameter,
                                localScope,
                                globalScope);

                            this.evalAction(action, field, specifics, actionInfo);
                            return;

                        }

                        actionInfo = this.shouldEvalAction(action.name,
                            action.on,
                            action.parameter,
                            localScope,
                            globalScope.cards[index]);
                        this.evalAction(action, field, specifics, actionInfo);
                    }
                } else {
                    actionInfo = this.shouldEvalAction(action.name,
                        action.on,
                        action.parameter,
                        localScope,
                        globalScope);
                    this.evalAction(action, field, specifics, actionInfo);
                }

            });
        };

    });
