/**
 * @name supervision
 * @ngdoc module
 * @description
 * Supervision Module
 */


angular.module('supervision', [])

    .service('supervisionStore', function (Server) {
        this.load = function () {
            return Server.createResource('/auth/secret', {});
        };

        this.repeatingUnapproved = function () {
            return Server.createResource('/patient/:id/repeating/:formName/approvals', {});
        };

        this.batchRepeating = function () {
            return Server.createResource('/batch/repeating', {
                get: {
                    method: 'GET',
                    url: '/batch/:id/repeating'
                }
            });
        };

        this.batchMessage = function () {
            return Server.createResource('/batch/message', {
                get: {
                    method: 'GET',
                    url: '/batch/:id/message',
                    isArray: true
                }
            });
        };

        this.batchStatic = function () {
            return Server.createResource('/batch/static', {});
        };

    })


    .value('staticCardGroupMapping', {})

    .service('CountersignStatus', function (Cards, staticCardGroupMapping, $q, Messages) {

        this.currentStatus = {
            assessment: {overall: false},
            static: {overall: false},
            careplans: {overall: false},
            notes: {overall: false},
            overall: false
        };

        this.getStatus = function () {
            return this.currentStatus;
        };

        function createGroupMapping(formName) {
            let deferred = $q.defer();

            if (formName in staticCardGroupMapping) {
                deferred.resolve();
            } else {
                Cards.get(formName).then(form => {
                    for (let group of form.groups) {
                        if (!(formName in staticCardGroupMapping)) {
                            staticCardGroupMapping[formName] = {};
                        }
                        for (let card of group.cards) {
                            staticCardGroupMapping[formName][card.name] = group.name;
                        }
                    }
                    deferred.resolve();
                });
            }
            return deferred.promise;
        }

        function addGroupStatus(formName, cardName, currentStatus) {
            var groupName = staticCardGroupMapping[formName][cardName];
            if (!currentStatus.static[formName]) {
                currentStatus.static[formName] = {overall: true, groups: {}};
            }
            currentStatus.static[formName].groups[groupName] = true;
            currentStatus.static[formName][cardName] = true;
        }

        this.update = function (currentStatus, newStatus, overallUpdateOnly) {

            let deferred = $q.defer();

            currentStatus.assessment = {overall: false};
            currentStatus.static = {overall: false};
            currentStatus.careplans = {overall: false};
            currentStatus.notes = {overall: false};
            currentStatus.overall = false;

            var staticForms = {};

            for (var cards in newStatus.static) {
                if (newStatus.static[cards]) {
                    currentStatus.overall = true;
                }
                var formName = cards.split('-')[0];
                staticForms[formName] = true;
            }

            for (var assessmentForm in newStatus.assessment) {
                if (newStatus.assessment[assessmentForm]) {
                    currentStatus.overall = true;
                    currentStatus.assessment.overall = true;
                    currentStatus.assessment[assessmentForm] = true;
                }
            }

            for (var careplanForm in newStatus.careplans) {
                if (newStatus.careplans[careplanForm]) {
                    currentStatus.overall = true;
                    currentStatus.careplans.overall = true;
                    currentStatus.careplans[careplanForm] = true;
                }
            }

            for (var noteType in newStatus.message) {
                if (newStatus.message[noteType]) {
                    currentStatus.overall = true;
                    currentStatus.notes.overall = true;

                    for (let noteCategory in Messages.filters) {
                        if (noteCategory == 'all') {
                            continue;
                        }
                        if (Messages.filters[noteCategory].includes(noteType)) {
                            currentStatus.notes[noteCategory] = true;
                        }
                    }
                }
            }

            if (!overallUpdateOnly && Object.keys(newStatus.static).length > 0) {

                var groupMappingForms = Object.keys(staticForms).map(staticForm => {
                    return createGroupMapping(staticForm);
                });

                $q.all(groupMappingForms).then(() => {
                    for (var cards in newStatus.static) {
                        var [formName, cardName] = cards.split('-');
                        if (newStatus.static[cards]) {
                            currentStatus.overall = true;
                            currentStatus.static.overall = true;
                            addGroupStatus(formName, cardName, currentStatus);
                        }
                    }
                    deferred.resolve();
                });
            } else {
                deferred.resolve();
            }

            return deferred.promise;
        };

        this.getStatusForForm = function (type, formName) {
            if (type === 'care-plan') {
                return this.currentStatus.careplans[formName];
            } else if (type === 'assessment' || type === 'repeating') {
                return this.currentStatus.assessment[formName];
            } else if (type === 'static') {
                return this.currentStatus.static[formName] && this.currentStatus.static[formName].overall;
            }
        };

        this.refresh = function (newStatus) {
            this.update(this.currentStatus, newStatus);
        };
    })

    .directive('formsOverview', function () {
        return {
            restrict: 'E',
            scope: {
                details: '=',
                patient: '=',
                showDetails: '=',
                allSelected: '='
            },
            controller: function ($scope, CountersignStatus, $rootScope,
                                  supervisionStore, Utils, Cards, $state,
                                  signOffInfo, matchmedia, PatientStore, PatientDecorator,
                                  PastPatientObject) {

                matchmedia.onPhone(function (mql) {
                    $scope.mobile = mql.matches;
                });

                $scope.details.total = 0;
                $scope.status = $scope.details.status;
                $scope.selected = $scope.details.values;
                $scope.staticForms = {};
                $scope.unapprovedRepForms = [];
                $scope.unapprovedNotes = [];
                $scope.notesFetched = false;

                $scope.repeatingForms = angular.copy($rootScope.repeatingForms.concat($rootScope.carePlans));

                $scope.toggleSelection = function (type, id) {
                    $scope.selected[type][id] = !$scope.selected[type][id];
                    $scope.details.selected = $scope.selected[type][id] ?
                        $scope.details.selected + 1 :
                        $scope.details.selected - 1;

                    $scope.allSelected.value = $scope.selected[type][id] ?
                        $scope.allSelected.value + 1 :
                        $scope.allSelected.value - 1;
                };

                supervisionStore.batchRepeating().then(UnapprovedForms => {
                    UnapprovedForms.get({id: $scope.patient.id}, function (resp) {
                        $scope.repeatingForms.forEach(form => {
                            if (resp[form.name] && resp[form.name].length > 0) {
                                form.unapprovals = resp[form.name];
                                $scope.details.total += resp[form.name].length;
                                $scope.unapprovedRepForms.push(form);
                                form.unapprovals.forEach(unapprovedForm => {
                                    unapprovedForm.datetime = Utils.formatBatchTime(unapprovedForm.time);
                                });
                            }
                        });
                    });
                });

                supervisionStore.batchMessage().then(UnapprovedNotes => {
                    UnapprovedNotes.get({id: $scope.patient.id}, function (resp) {
                        $scope.details.total += resp.length;
                        resp.forEach(unapprovedNote => {
                            unapprovedNote.datetime = Utils.formatBatchTime(unapprovedNote.time);
                            $scope.unapprovedNotes.push(unapprovedNote);
                        });
                        $scope.notesFetched = true;
                    });
                });

                $scope.addToTotalCards = function () {
                    $scope.details.total += 1;
                };

                /*jshint loopfunc:true */
                for (var staticFormName in $scope.status.static) {
                    if (staticFormName !== 'overall') {
                        Cards.get(staticFormName).then(staticForm => {
                            $scope.staticForms[staticForm.name] = staticForm;
                        });
                    }
                }

                $scope.accessRepForm = function (unapprovedForm, formName, formType) {
                    signOffInfo.formVals = unapprovedForm.data[formName];
                    signOffInfo.submissionDateTime = Utils.formatBatchTime(unapprovedForm.time);
                    $rootScope.signoffLater.value = true;
                    signOffInfo.repId = unapprovedForm.id;
                    signOffInfo.form = formName;
                    signOffInfo.username = unapprovedForm.user;

                    PatientStore.load().then(Patient => {
                        Patient.history({id: $scope.patient.id, timestamp: unapprovedForm.time},
                            function (patient) {

                                PatientDecorator.addCardFunctions(patient);
                                PastPatientObject.value = patient.data;

                                if (formType == 'repeating') {
                                    signOffInfo.assessment = true;
                                    $state.go('patient.assessment', {id: $scope.patient.id, card: formName});
                                } else {
                                    signOffInfo.careplan = true;
                                    $state.go('patient.careplans', {id: $scope.patient.id, card: formName});
                                }

                            }, err => {
                            });
                    });
                };

                $scope.accessStaticCard = function (formName, cardName) {
                    $state.go('patient.card', {
                        id: $scope.patient.id,
                        form: formName,
                        card: cardName
                    });
                };

                $scope.accessHistory = function (formName) {
                    $state.go('patient.formhistory', {
                        id: $scope.patient.id,
                        name: formName
                    });
                };

                $scope.accessNote = function (note) {
                    signOffInfo.note = note;
                    signOffInfo.channel = $scope.patient.id;
                    signOffInfo.next = 'home';
                    // $rootScope.signoffLater.value = true;
                    $state.go('countersignnote');
                };


            },
            template: `

    <div style='text-align:center;height:0'>
      <span ng-click='showDetails[patient.id] = false' 
            class="icon1-expand-arrow-up-singlebatch-top batch-up-white-arrow">
        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
      </span>
    </div>

    <div class='batch-signoff'>
      <div ng-repeat='form in unapprovedRepForms'>
        <div class='batch-signoff__form-title'>
          <span>
            <i ng-class='form.icon'></i>
            {{ form.label }}
          </span>
          <span class='trainee-light italicize' ng-click='accessHistory(form.name)'>
            <i class='fas fa-hourglass-end trainee-light left-padding'></i>
            History
          </span>
        </div>
        <div class='row batch-signoff__form' ng-repeat='unapprovedForm in form.unapprovals'>
          <div class='small-9 columns batch-signoff__form-details'
               ng-click='toggleSelection("repeating", form.name + ":" + unapprovedForm.id)'>
            <div class='row'>
              <div class='small-1 columns'>
              <i ng-class='["far",
  {"fa-square batch-signoff__form-icon": !selected.repeating[form.name + ":" + unapprovedForm.id],
  "fa-check-square trainee-colored": selected.repeating[form.name + ":" + unapprovedForm.id]}]'>
              </i>
              </div>
              <div class='small-3 large-2 columns'>
                <span class='italicize' ng-bind='unapprovedForm.datetime.time'></span>
              </div>
              <div class='small-4 large-2 columns'>
                <span class='italicize' ng-bind='unapprovedForm.datetime.date'></span>
              </div>
              <div class='small-3 large-7 columns end'>
                <span ng-bind='unapprovedForm.user'></span>
              </div>
            </div>
          </div>
          <div class='small-3 columns batch-signoff__form-nav'>
            <span ng-click='accessRepForm(unapprovedForm, form.name, form.type)'>
              Go to Form
              <i class='fas fa-caret-right'></i>
            </span>
          </div>
        </div>
      </div>
      <div ng-repeat='(form, value) in status.static'
           ng-if='form !== "overall"'>

            <div class='batch-signoff__form-title'>
              <span>
                <i ng-class='staticForms[form].icon'></i>
                {{ staticForms[form].label }}
              </span>
            </div>

           <div ng-repeat='group in staticForms[form].groups'>
             <div class='row batch-signoff__form'
                  ng-repeat='card in group.cards'
                  ng-init='addToTotalCards()'
                  ng-if='status.static[form][card.name]'>
              <div class='small-9 columns batch-signoff__form-details'
                   ng-click='toggleSelection("static", form + ":" + card.name)'>
                <div class='row'> 
                  <div class='small-1 columns'>
                    <i ng-class='["far",
                        {"fa-square batch-signoff__form-icon": !selected.static[form + ":" + card.name],
                         "fa-check-square trainee-colored": selected.static[form + ":" + card.name]}]'>
                    </i>
                  </div>
                  <div class='small-10 columns end'>
                    <div style='height:1.5em'> {{ card.label }} </div>
                    <div> {{ patient.data[form][card.name]._submittedBy_ }} </div>
                  </div>
                </div>
              </div>
              <div class='small-3 columns batch-signoff__form-nav'>
                <span ng-click='accessStaticCard(form, card.name)'>
                  Go to Card
                  <i class='fas fa-caret-right'></i>
                </span>
              </div>
             </div>
           </div>
      </div>



      <div ng-if='notesFetched && unapprovedNotes.length > 0'
           class='batch-signoff__form-title'>
        <span>
          <i class='fas fa-sticky-note'></i>
          Notes
        </span>
      </div>

      <div class='row batch-signoff__form' ng-repeat='note in unapprovedNotes'>
        <div class='small-9 columns batch-signoff__form-details'
             ng-click='toggleSelection("notes", note.id)'>
          <div class='row'>

            <div class='small-1 columns'>
              <i ng-class='["far",
                  {"fa-square batch-signoff__form-icon": !selected.notes[note.id],
                   "fa-check-square trainee-colored": selected.notes[note.id]}]'>
              </i>
            </div>

            <div class='small-3 large-2 columns'>
              <span class='italicize' ng-bind='note.datetime.time'></span>
            </div>

            <div class='small-4 large-2 columns'>
              <span class='italicize' ng-bind='note.datetime.date'></span>
            </div>

            <div class='small-3 large-7 columns end'>
              <span ng-bind='note.username'></span>
            </div>

          </div>
        </div>
        <div class='small-3 columns batch-signoff__form-nav'>
          <span ng-click='accessNote(note)'>
            Go to Note
            <i class='fas fa-caret-right'></i>
          </span>
        </div>
      </div>

    </div>

    <div style='text-align:center;height:0'>
      <span ng-click='showDetails[patient.id] = false'
            class="icon1-expand-arrow-up-singlebatch-bottom batch-up-purple-arrow">
        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
      </span>
    </div>


    `

        };
    })

    .value('signOffInfo', {});
