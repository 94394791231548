module.exports = {
    url: '/managecodes',
    views: {
        main: {
            controller: function ($scope) {
                'ngInject';
                $scope.patient = $scope.$parent.patient;
            },
            template: `
                <manage-access-codes patient="patient"></manage-access-codes>
          `
        },
        title: {
            controller: function () {
            },
            template: `
                Sharing Arrangements
      `
        }
    }
};
