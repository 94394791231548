/**
 * @name nhs
 * @ngdoc module
 * @description
 * A collection of NHS specific components.
 */
angular.module('nhs', [])

    /**
     * Directive which adds validation for nhs numbers,
     * using https://www.npmjs.com/package/nhs-number-validator
     *
     * Usage: <input ng-model="model.testNhsNumber"
     *               name="testNhsNumber" nhs-number />
     */
    .directive('nhsNumber', function () {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function (scope, elm, attrs, ctrl) {
                ctrl.$validators.nhsNumber = function (modelValue, viewValue) {
                    if (ctrl.$isEmpty(modelValue)) {
                        return true;
                    }
                    // Added this as used in the nhs-number-validator
                    // but not available in EMCA5
                    Number.isInteger = function (x) {
                        return x % 1 === 0;
                    };

                    var nhsValidator = require('nhs-number-validator');

                    if (nhsValidator.validate(viewValue)) {
                        return true;
                    } else {
                        return false;
                    }
                };
            }
        };
    });
