module.exports = {
    url: '/history/:name',
    views: {
        main: {
            controller: function ($scope, $state, $window, $rootScope) {
                'ngInject';


                $window.document.title = 'CHAI ' + $rootScope.patientLabel + ' History';

                $scope.formName = $state.params.name;
                $scope.patientId = $state.params.id;
                $scope.patient = $scope.$parent.patient;


            },
            template: `
      <history-form patient-id='patientId' patient='patient' form-name='formName'></history-form>
    `
        },
        title: {
            controller: function ($scope, $state, Cards) {
                'ngInject';
                $scope.form = {};
                Cards.get($state.params.name, $scope.patientId).then(form => {
                    $scope.form = form;
                });
            },
            template: `
<span ng-bind='form.label'></span>  
    `
        }
    }
};
