module.exports = {
    url: '/staticforms',
    views: {
        main: {
            controller: function ($scope, $rootScope, $window, CountersignStatus) {
                'ngInject';

                $window.document.title = 'CHAI Static Forms';
                $scope.patient = $scope.$parent.patient;
                $scope.forms = $rootScope.staticForms;
                $scope.accessCodeForm = $rootScope.accessCodeForm;
                $scope.customForms = $rootScope.customForms;
                $scope.trainee = $rootScope.trainee;
                $scope.countersignStatus = CountersignStatus.getStatus();
            },
            template: `
      <div class='row group-title'>
        <label chai-markdown='"Forms"'></label>
      </div>
      
      <div class='fill'>
      <div class='patient data'>        
      <div class='third' ng-repeat='form in forms'>
        <a ui-sref='patient.form({id: patient.id, name: form.name})'>
        <div class='item'>
            <radial-progress progress-id=form.name>
              <span>
                <i ng-class='[form.icon, "fa-2x adjustright icon"]'>
                </i>
                <i class='icon2-unapproved fa-stack-1x trainee-light'
                   style='top: 2em; left: 2.7em; font-size: 1.2em'
                   ng-show='countersignStatus.static[form.name]'>
                </i>
              </span>
            </radial-progress>
            <center>
              <h6>{{form.label}}</h6>
            </center>
        </div>
        </a>        
      </div>
      
      <div class='third' ng-repeat='form in accessCodeForm'>
        <a ui-sref='patient.managecodes({id: patient.id})'>
        <div class='item'>
        <radial-progress progress-id=form.name>
          <span>
            <i ng-class='["far fa-file-export", "fa-2x adjustright icon"]'>
            </i>
          </span>
        </radial-progress>
            <center>
              <h6>{{form.label}}</h6>
            </center>
        </div>
        </a>        
      </div>
      
      
      </div>
      
      <div class='row group-title'>
        <label chai-markdown='"Additional PDFs"'></label>
      </div>
      
      <div class='patient data'>        
      <div class='third' ng-repeat='form in customForms'>
        <print name='form.name' patient-id='patient.id' type='"custom"'>
        <div class='item'>            
              <span>
                <i ng-class='[form.icon, "fa-2x adjustright icon"]'>
                </i>                
              </span>            
            <center>
              <h6>{{form.label}}</h6>
            </center>
        </div>
        </print>   
      </div>
      </div>
      
      </div>
      `
        },
        title: {
            template: `
        Forms'
      `
        }
    }
};
