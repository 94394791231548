module.exports = {
    url: '/repopulate',
    controller($scope, $rootScope, $state, $mdDialog) {
        'ngInject';

        $scope.patient = $rootScope.repopulatePatient;

        $scope.selected = {static: {}, formCount: 0};

        $scope.staticForms = $rootScope.staticForms.filter(staticForm => {
            return staticForm.name in $scope.patient.data;
        });

        $scope.totalStaticForms = $scope.staticForms.length;

        $scope.toggleSelection = function (formName, formLabel, formType) {
            if (formType === 'static') {
                if (!$scope.selected.static[formName]) {
                    $scope.selected.static[formName] = {'label': formLabel};
                }
                $scope.selected.static[formName].value = !$scope.selected.static[formName].value;
                $scope.selected.formCount = $scope.selected.static[formName].value ?
                    $scope.selected.formCount + 1 :
                    $scope.selected.formCount - 1;
            }
        };

        $scope.showConfirmation = function (ev) {
            $mdDialog.show({
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                locals: {selected: $scope.selected, patientId: $scope.patient.id},
                template: `
          <md-dialog>
            <md-dialog-content>
              <div class='readmit' layout-margin layout='column' >
                <div layout='row' class='heading'>
                    Repopulate {{ selected.formCount }} forms
                </div>
                <div class='jet-black'>
                  Click OK to repopulate selected forms for this patient
                </div>
                <div ng-if='staticForm.value' ng-repeat='staticForm in selected.static'>
                  {{ staticForm.label }}
                </div>
                <div layout layout-align='end'>
                  <md-button flex-offset='50'
                             flex-offset-xs='0'
                             flex
                             ng-click='repopulateForms()'
                             class="ok">
                    OK
                  </md-button>
                  <md-button flex ng-click='closeDialog()'
                             class="no-right-margin cancel">
                    Cancel
                  </md-button>
                </div>
              </div>
            </md-dialog-content>
          </md-dialog>
        `,
                controller: function DialogController($scope, $rootScope, $mdDialog, $state, PatientStore,
                                                      selected, patientId) {
                    $scope.selected = selected;
                    $scope.patientId = patientId;
                    $scope.canSearchPatients = $rootScope.canSearchPatients;
                    $scope.patientLabel = $rootScope.patientLabel;

                    $scope.closeDialog = function () {
                        $mdDialog.hide();
                    };

                    $scope.repopulateForms = function () {

                        let selectedForms = Object.keys($scope.selected.static).filter(form => {
                            return $scope.selected.static[form].value;
                        });

                        PatientStore.load().then(Patient => {
                            Patient.readmit({id: $scope.patientId}, selectedForms, function () {
                                $mdDialog.hide();
                                $state.go('patient.dash', {id: $scope.patientId}, {reload: true});
                            }, err => {
                            });
                        });
                    };
                }
            });
        };
    },
    template: `

    <navigation-bar>
      <div class='left'>
        <a ui-sref='home'>
          <i class='fas fa-th-list'></i>
        </a>

        <a ui-sref='messages'>
          <i ng-hide='unreadStatus.ward' class='far fa-comment silver-grey'></i>
          <i ng-show='unreadStatus.ward' class='fas fa-comment-lines'></i>
          <i ng-show='unreadStatus.ward' class='fas fa-circle notification-dot'></i>
        </a>
        
        <a ui-sref='search' ng-if='canSearchPatients'>
          <i class='fas fa-search'></i>
        </a>

        <a ui-sref='import'>
          <i class='fas icon1-user-arrow-in-right'></i>
        </a>

        <a ng-if= '!trainee' ui-sref='batchsignoff'>
          <i class='icon2-countersign'></i>
        </a>

      </div>
      <div class='right'>
        <a ui-sref='logout'>
          <i class='fas fa-sign-out-alt'></i>
        </a>
      </div>

      <center>
      {{fullName}}
      </center>

    </navigation-bar>

    <title-bar>
      <center>
        {{ patientLabel }} Readmit
      </center>
    </title-bar>

    <main>
      <div style='margin: auto' class='readmit white' layout-margin layout='column'>
        <div layout='row'>
          <div class='heading' flex='90'>
            Choose Forms for Readmission
          </div>
        </div>

       <readmit-patient-preview patient='patient'></readmit-patient-preview>

        <md-card class='forms'>
          <md-card-content>
            <div layout='row'>
              <div flex='85' flex-xs='80'>
                Select form data to be carried forward
              </div>
              <div flex flex-offset='5' class='brilliant-sky align-right'>
                {{ selected.formCount }} / {{ totalStaticForms }}
              </div>
            </div>
            <div ng-click='toggleSelection(staticForm.name, staticForm.label, "static")'
                 layout='row'
                 class='form'
                 ng-repeat='staticForm in staticForms'>
                <div flex='10'>
                  <i ng-class='["far", {"fa-square": !selected.static[staticForm.name].value,
                                        "fa-check-square": selected.static[staticForm.name].value}]'>
                  </i>
                </div>
                <div flex>
                  <i ng-class='staticForm.icon'></i>
                  {{ staticForm.label }}
                </div>
            </div>
            <div layout layout-align='center'>
              <md-button ng-click='showConfirmation($event)'
                         class="md-raised repopulate">
                Repopulate Forms
              </md-button>
            </div>
          </md-card-content>
        </md-card>

      </div>
    </main>
  `
};
