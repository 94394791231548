/**
 * @name task
 * @ngdoc module
 * @description
 * Components handling of tasks and task screens
 */

angular.module('task', ['ngMaterial', 'materialComponentsTable'])
    .config(function ($mdDialogProvider) {
        $mdDialogProvider.addPreset('taskDetails', {
            options: function () {
                return {
                    template: `
                    <md-dialog aria-label='Edit / Close Task Dialog' flex-xs="100" flex-sm="80" flex-gt-sm="70" style="max-height: 650px">
    <md-dialog-content class="notes-scrollbar">
        <div layout="row" class="notes__task-container" layout-align="space-between">
            <div flex layout="column" layout-align="space-between" style="margin: 1em">
                <div flex="90">
                    <h5 class="teal-velvet"> Edit / Close Task </h5>
                    <edit-task-note task="task" navigate-to-patient="openPatient(channel)"
                                    channel="channel" update="update"></edit-task-note>
                </div>

                <div flex layout='row' layout-xs='column' layout-padding="1em"
                     layout-fill layout-align='center'>
                    <md-button style="margin-top: 0.25em"
                               class="md-no-focus note-action__button note-action__button-cancel"
                               ng-click='closeDialog()'>
                        Cancel
                    </md-button>
                    <md-button class="md-no-focus note-action__button note-action__button-send"
                               md-colors="{background: 'primary-500', color: 'primary-50'}"
                               style="margin-top: 0.25em" ng-click='updateTask($event)'>
                        Save Changes
                    </md-button>

                </div>
            </div>
            <div ng-show="showHistory" hide-xs flex="40" md-colors="{background: 'primary-A100'}">
                <div style="line-height: 40px; margin: 1em">
                    <h6 class="teal-velvet bold">History<a class="right" style="font-weight: lighter"
                                                           ng-click="clickClose()"><i class="fas fa-xmark"></i>
                        Close</a></h6>
                </div>
                <md-content class="notes-scrollbar"
                            style="overflow-y: auto; background: transparent; max-height: calc( 650px - 40px - 1em)">
                    <task-history task-history="history"></task-history>
                </md-content>
            </div>
        </div>
    </md-dialog-content>
</md-dialog>`,
                    controller: 'TaskDetailsDialogCtrl',
                    escapeToClose: true,
                    clickOutsideToClose: true
                };
            }
        });
        $mdDialogProvider.addPreset('progress', {
            options: function () {
                return {
                    clickOutsideToClose: false,
                    escapeToClose: false,
                    template: `
                    <md-dialog aria-label='Progress Indicator' class="progress-indicator">
                        <div layout="row" layout-sm="column" layout-align="center center">
                          <md-progress-circular md-mode="indeterminate">
                          </md-progress-circular>
                        </div>
                      </md-dialog>
                    `
                };
            }
        });
    })
    .controller('TaskDetailsDialogCtrl', function ($scope, $rootScope, $mdDialog, $location, $state, PatientStore, selectedTask, channel, taskHistory, TaskStore) {
        $scope.task = selectedTask;
        $scope.channel = channel;
        $scope.update = {};
        $scope.history = taskHistory;
        $scope.showHistory = true;

        $scope.closeDialog = function () {
            $mdDialog.hide(false);
        };

        $scope.clickClose = function() {
            $scope.closeDialog();
        };

        $scope.openPatient = function (channel) {
            $mdDialog.hide(false);
            $location.path('/patient/' + channel + '/dash');
        };

        $scope.updateTask = function (ev) {
            if ($scope.update.status === "Open") {
                delete $scope.update.status;
            }
            TaskStore.loadTaskResource($scope.channel).then(TaskStore => {
                TaskStore.update({id: selectedTask.id}, $scope.update, data => {
                    $mdDialog.hide(true);

                    $mdDialog.show(
                        $mdDialog.alert()
                            .parent(angular.element(document.querySelector('#popupContainer')))
                            .clickOutsideToClose(true)
                            .title('Task updated')
                            .ariaLabel('Task Updated')
                            .ok('Ok')
                            .targetEvent(ev)
                    );
                });
            });
        };
    })
    .service('TaskStore', function ($http, $q, Server) {

        this.loadAssignees = function () {
            return Server.createResource(`/users/:username`, {
                assignees: {
                    method: 'GET',
                    url: `/users/:username/assignees`,
                    isArray: true
                },
                assignedToMe: {
                    method: 'GET',
                    url: `/users/:username/tasks`,
                    isArray: true
                },
            });
        };

        this.loadTaskTypes = function () {
            return Server.createResource(`/v2/config/task-types`, {
                taskTypes: {
                    method: 'GET',
                    url: `/v2/config/task-types`,
                    isArray: true
                },
            });
        };

        this.loadTaskResource = function (channel) {

            return Server.createResource(`/tasks/${channel}`, {

                get: {
                    method: 'GET',
                    url: `/tasks/${channel}/task/:id`
                },
                create: {
                    method: 'POST',
                    url: `/tasks/${channel}/task`
                },
                update: {
                    method: 'PUT',
                    url: `/tasks/${channel}/task/:id`
                },
                history: {
                    method: 'GET',
                    url: `/tasks/${channel}/task/:id/history`,
                    isArray: true
                }
            });
        };

    }).controller('TaskViewController', function ($scope, $location, Utils, TaskStore, $mdDialog, $rootScope, $window) {
    $scope.loaded = false;
    $scope.windowWidth = $window.innerWidth;

    $scope.formatDate = Utils.taskDueFormat;
    $scope.loadTasks = function () {
        $scope.contents = [];
        TaskStore.loadAssignees().then(Task => {
            Task.assignedToMe({username: $rootScope.username}, function (assignedTasks) {
                $scope.assignedTasks = assignedTasks;
                if (assignedTasks != null && assignedTasks.length > 0) {
                    $scope.contents = assignedTasks.map(assignedTask => {
                        var warnIcon = `
                                 <i class='fas fa-bell warn'></i>`;
                        var completedIcon = `
                                 <i class='fas fa-check-circle success'></i>`;
                        var overdueIcon = `
                                 <i style='position: relative; top: 1px; right: 3px;'
                                class='icon3-alarm-clock overdue'</i>`;
                        var taskWarn = moment().diff(assignedTask.message.warn) > 0 &&
                            moment().diff(assignedTask.message.due) < 0;
                        var taskOverdue = moment().diff(assignedTask.message.due) > 0;

                        var tableRow = {
                            channel: assignedTask.channel,
                            task: assignedTask,
                            taskWarn: taskWarn,
                            taskOverdue: taskOverdue,
                            due: moment(assignedTask.message.due),
                            assignedTo: assignedTask.assignedTo.preferredName,
                            taskDescription: assignedTask.message.data,
                            taskType: assignedTask.taskType,
                            client: assignedTask.patient.firstName + ' ' + assignedTask.patient.lastName,
                        };
                        if (assignedTask.completed) {
                            tableRow.dueIcon = completedIcon;
                        } else {
                            if (taskWarn) {
                                tableRow.dueIcon = warnIcon;
                            }
                            if (taskOverdue) {
                                tableRow.dueIcon = overdueIcon;
                            }
                        }
                        return tableRow;
                    });
                }

                $scope.loaded = true;
            });
        });

    };

    $scope.selectedContent = '';

    $scope.headerClass = {
        dueIcon: 'task-table-dueIcon-content',
        due: 'task-table-due-content',
        client: 'task-table-date-content',
        taskDescription: 'fill-content'
    };
    $scope.contentClass = {
        dueIcon: 'grey'
    };

    $scope.headers = initHeaders();

    $scope.loadTasks();
    $scope.navOperation = function (selectedContent, $event) {

        TaskStore.loadTaskResource(selectedContent.channel).then(TaskStore => {
            TaskStore.history({id: selectedContent.task.id},
                function (taskHistory) {
                    $mdDialog.show(
                        $mdDialog.taskDetails({
                            locals: {
                                selectedTask: selectedContent.task,
                                taskHistory: taskHistory,
                                channel: selectedContent.channel
                            },
                        })).then(function (data) {
                            if (data) {
                                $scope.loadTasks();
                            }
                    }, function () {
                    });
                });
        });

    };
    $scope.selectOperation = function (selectedContent) {
        $scope.navOperation(selectedContent);
    };

    angular.element($window).bind('resize', function () {
        var wasMobile = $scope.windowWidth < 768;
        var isNowMobile = $window.innerWidth < 768;
        if (wasMobile !== isNowMobile) {
            $scope.windowWidth = $window.innerWidth;
            $scope.$digest();
        }
    });

    function initHeaders() {
        return [
            {
                contentField: 'dueIcon',
                contentType: 'html',
                sortableField: false
            },
            {
                contentField: 'due',
                contentFilter: {
                    filter: 'date',
                    pattern: 'HH:mm DD/MM/YYYY'
                },
                contentType: 'date',
                label: 'Due',
                sortableField: true
            }, {
                contentField: 'client',
                contentFilter: {
                    filter: 'uppercase'
                },
                contentType: 'text',
                label: 'Client',
                sortableField: true
            },
            {
                contentField: 'taskType',
                contentType: 'text',
                label: 'Type',
                sortableField: true
            }, {
                contentField: 'taskDescription',
                contentType: 'markdown',
                label: 'Description',
                sortableField: true
            }
        ];
    }

}).directive('userTaskView', function () {
    return {
        restrict: 'E',
        scope: {},
        controller: 'TaskViewController',
        template: `
         <md-card layout-margin="1em">
         <md-content>
          <div ng-if="loaded && contents.length === 0">
          <h2 style="text-align: center" class="md-headline">No tasks assigned</h2>
           </div>
          <div ng-if="loaded && contents.length >0">
          <md-list ng-if="windowWidth <768" class="task-list" flex>
            <md-list-item class="md-3-line" ng-repeat="task in contents" ng-click="navOperation(task)">
              <i style="z-index: 1;position: relative" ng-if="task.taskWarn && !task.taskOverdue" class="task-avatar-border md-avatar-icon fas fa-bell warn" alt="warning icon" >
              <md-tooltip md-direction="bottom">Due soon</md-tooltip>
              </i>
                <i style="z-index: 1;position: relative" ng-if="task.taskOverdue" class="task-avatar-border md-avatar-icon icon3-alarm-clock overdue" alt="overdue icon" >
                <md-tooltip md-direction="bottom">Overdue</md-tooltip>
                </i>
              <i ng-if="!task.taskOverdue && !task.taskWarn" class="task-avatar-normal task-avatar-border md-avatar-icon fa-regular fa-ballot-check"  alt="task icon" ></i>
              <div class="md-list-item-text" layout="column">
                <h3><strong>Client</strong>: <em>{{ task.client }}</em></h3>
                <h4><strong>Due</strong>: {{ formatDate(task.due) }}</h4>
                <span chai-markdown='"**Task**: " + task.taskDescription'></span>
              </div>
              <md-divider ></md-divider>
            </md-list-item>
          </md-list>
           <md-table ng-if="windowWidth >=768" headers-class="headerClass" contents-class="contentClass"
          headers="headers" contents="contents"
          pagination="false" 
          action="true" on-action="navOperation(selectedContent, $event)"
          selection="false" on-select="selectOperation(selectedContent,selectValue)"
          filter="filterValue">
            </md-table>
            </div>
</div>
    </md-content>
</md-card>
       <div >
        <md-card layout-margin="1em">
       
</md-card>
</div>
       `
    };
})
    .directive('taskAssignDropdown', function () {
        return {
            restrict: 'E',
            scope: {
                selectedItem: '=',
                searchText: '=',
                self: '=',
            },
            controller: function ($scope, TaskStore, $rootScope) {
                $scope.username = $rootScope.username;

                $scope.users = [];

                TaskStore.loadAssignees().then(Assignees => {
                    Assignees.assignees({username: $scope.username},
                        results => {
                            $scope.users = results;
                        });
                });

                $scope.formatName = function (user) {
                    var name = user.preferredName;
                    if (!angular.isDefined(name) || name.length === 0) {
                        name = user.firstName + ' ' + user.lastName;
                    }

                    if ($scope.username === user.username) {
                        name = name + " (me)";
                    }
                    return name;
                };

                $scope.querySearch = function (query) {
                    var users = $scope.users;
                    if (angular.isDefined(query) && query != null) {
                        const lowercaseQuery = query.toLowerCase();
                        users = $scope.users.filter(user => user.preferredName.toLowerCase().includes(lowercaseQuery));
                    }
                    return users;
                };
            },
            template: `
        <md-autocomplete md-colors="{'border-color': 'primary-A700'}"
                         md-min-length="0"
                         md-selected-item="selectedItem"
                         md-search-text="searchText"
                         md-input-id="userSelectInput"
                         md-items="item in querySearch(searchText)"
                         placeholder="Start typing to assign task to someone"
                         input-aria-labelledby="assignedToLabel"
                         md-item-text="item.preferredName">
            <md-item-template>
                <span md-highlight-text="searchText" md-highlight-flags="^i">{{formatName(item)}}</span>
            </md-item-template>
            <md-not-found>
                No users found matching "{{searchText}}"
            </md-not-found>
        </md-autocomplete>
  `
        };
    })
    .directive('taskTypeDropdown', function () {
        return {
            restrict: 'E',
            scope: {
                selectedItem: '=',
                searchText: '=',
                self: '=',
            },
            controller: function ($scope, TaskStore, $rootScope) {
                $scope.username = $rootScope.username;

                $scope.taskTypes = [];

                TaskStore.loadTaskTypes().then(TaskTypes => {
                    TaskTypes.taskTypes({},
                        results => {
                            $scope.taskTypes = [{name: 'No Task Type', description: ''}].concat(results);
                            $scope.selectedItem = $scope.taskTypes[0];
                        });
                });
            },
            template: `
        
            <md-select
                    md-colors="{'border-color': 'primary-500'}" 
                    ng-model="selectedItem" placeholder="Select Task Type" style="background-color: white; font-size: 14px; padding-left: 14px;" class="md-no-underline">
                <md-option ng-repeat="tasktype in taskTypes" ng-value="tasktype">{{tasktype.name}}</md-option>
            </md-select>
        
  `
        };
    })
    .directive('taskHistory', function () {
        return {
            restrict: 'E',
            scope: {
                taskHistory: '=',
            },
            controller: function ($scope, Utils) {
                $scope.formatDate = Utils.taskDueFormat;

                $scope.formatName = function (user) {
                    if (!user) {
                        return 'Unassigned';
                    }
                    var name = user.preferredName;
                    if (!angular.isDefined(name) || name.length === 0) {
                        name = user.firstName + ' ' + user.lastName;
                    }

                    if ($scope.username === user.username) {
                        name = name + " (me)";
                    }
                    return name;
                };

                $scope.formatNoteDate = function (time) {
                    return moment(time).format('HH:mm, MMM D');
                };
            },
            template: `
          <md-list class="task-history-list">
            <md-list-item class="md-3-line md-no-proxy" ng-repeat="note in taskHistory" >
              <div class="md-list-item-text" layout="column">
              <div ng-if="note.message.type=='task'">
                <h4><strong>Task Created</strong></h4>
                <p><strong>Assigned To: </strong> <em>{{formatName(note.message.assignedTo)}}</em></p>
                <p ng-if="note.message.taskType">
                    <strong>Type: </strong> <em>{{note.message.taskType}}</em>
                </p>
                <h4><strong>Due</strong>: {{ formatDate(note.message.due) }}</h4>
                <span chai-markdown='note.message.data'></span>
              </div>
              <div ng-if="note.message.type=='task_assigned'">
                <h4><strong>Task Assigned</strong></h4>
                <p><strong>Assigned To: </strong> <em>{{formatName(note.message.assignedTo)}}</em></p>
                <h4 ng-if="note.message.comment"><strong>Comment</strong>: {{ note.message.comment }}</h4>
              </div>
              <div ng-if="note.message.type=='task_commented'">
                <h4><strong>Task Commented</strong></h4>
                <p ng-if="note.message.comment"><strong>Comment</strong>: {{ note.message.comment }}</p>
              </div>
              <div ng-if="note.message.type=='task_completed'">
                <h4><strong>Status Updated</strong> {{note.message.data}}</h4>
                <p ng-if="note.message.comment"><strong>Comment</strong>: {{ note.message.comment }}</p>
              </div>
              <div>
                <p>{{note.message.authorName}} <span style="float: right">{{formatNoteDate(note.time)}}</span></p>
              </div>
              </div>
              <md-divider ></md-divider>
            </md-list-item>
          </md-list>            `
        };
    })
    .directive('editTaskNote', function () {
        return {
            restrict: 'E',
            scope: {
                task: '=',
                self: '=',
                update: '=',
                username: '=',
                channel: '=',
                navigateToPatient: '&'
            },
            controller: function ($scope, Utils) {
                $scope.states = ['Open', 'Complete', "Can't complete", 'No Longer Needed'];

                if (angular.isDefined($scope.task.patient)) {
                    $scope.name = $scope.task.patient.firstName + ' ' + $scope.task.patient.lastName;
                }
                $scope.taskDueDate = Utils.taskDueFormat($scope.task.message.due);

                $scope.taskWarn = moment().diff($scope.task.message.warn) > 0 &&
                    moment().diff($scope.task.message.due) < 0;
                $scope.taskOverdue = moment().diff($scope.task.message.due) > 0;

                $scope.update.assignedTo = $scope.task.assignedTo;
                $scope.update.status = $scope.states[0];

                $scope.clearSelected = function () {
                    $scope.update.assignedTo = null;
                    $scope.searchText = null;
                };

            },
            template: `
     <div class="notes__task-container" style='margin:.75em' layout-margin>
    <div layout="column">
        <table>
            <tr ng-if="name">
                <td class="bold">Client</td>
                <td>{{name}} <md-button ng-click="navigateToPatient({channel:channel})">View</md-button></td>
            </tr>
            <tr ng-if="task.message.taskType">
                <td class="bold">Type</td>
                <td>{{task.message.taskType}} </td>
            </tr>
            <tr>
                <td class="bold">Due</td>
                <td>
                    <div ng-class='{"warn": taskWarn, "overdue": taskOverdue}'>
                        <span class='far fa-bell' ng-show='taskWarn'></span>
                        <span style='position: relative; top: 1px;'
                              class='icon3-alarm-clock'
                              ng-show='taskOverdue'>
                          </span>
                        <span ng-show="task.message.due !== undefined && !completed"
                              ng-bind='"Due: " + taskDueDate'>
                          </span>
                        <span style='color: #999999;'
                              ng-show="task.message.due !== undefined && completed"
                              ng-bind='"Was Due: " + taskDueDate'>
                          </span>
                    </div>
                </td>
            </tr>
        </table>

        <div>
            <h6 class="bold" style="margin-bottom: 1em"> Task Description </h6>
            <div class="text-ellipsis--3" chai-markdown="task.message.data">
            </div>
        </div>
    </div>

    <h5 class="teal-velvet"> Update </h5>
    <div layout-gt-sm="row" layout-lt-md="column" layout-align="start center">
        <label id="assignedToLabel" class="bold" flex="20" flex-grow
               style="margin-bottom: 0.5em; padding-right: 0.25em">Assigned</label>
        <div flex="60">
            <task-assign-dropdown search-text="searchText" selected-item="update.assignedTo"></task-assign-dropdown>
        </div>
<!--        <md-chips hide-sm flex ng-if="update.assignedTo" md-removable="true" readonly="true">-->
<!--            <md-chip ng-click="clearSelected()" style="padding-left:1em; cursor: pointer" class="theme-bg white-bg">-->
<!--                {{update.assignedTo.preferredName}}-->
<!--                <i class='fa-solid fa-circle-xmark'></i>-->
<!--            </md-chip>-->
<!--        </md-chips>-->
    </div>
    <div layout-gt-sm="row" layout-lt-md="column" layout-align="start center">
        <label id="taskStatusLabel" flex="20" class="bold">Status</label>
        <md-select
                md-colors="{'border-color': 'primary-500'}" input-aria-labelledby="taskStatusLabel" flex="60"
                ng-model="update.status" placeholder="Select Status" style="padding-left: 14px;" class="task-status md-no-underline">
            <md-option ng-repeat="state in states" ng-value="state">{{state}}</md-option>
        </md-select>
    </div>
    <div>
        <div layout="column" layout-align="start">
            <label id="taskCommentLabel" flex="20" class="bold">Comment</label>
            <textarea ng-class='[{"expand": expand}]'
                      ng-model='update.comment'
                      ng-focus='expand = true'
                      style="box-shadow: none; border-style: solid; border-width: 1px;"
                      md-colors="{'border-color': 'primary-A700'}"
                      placeholder='Add a comment'>
                  </textarea>
        </div>
        <div>
    </div>
  `
        };
    });
