module.exports = {
    url: '/form/:name',
    views: {
        main: {
            controller: function ($scope, $state, Cards, $window, $rootScope, CountersignStatus) {
                'ngInject';
                $scope.formName = $state.params.name;
                $scope.patient = $scope.$parent.patient;
                $scope.form = {};
                $scope.countersignStatus = CountersignStatus.getStatus();

                $scope.trainee = $rootScope.trainee;
                $scope.$on('IdleTimeout', function () {
                  $rootScope.logout();
              });      
                $window.document.title = 'CHAI ' + $rootScope.patientLabel + ' Form';

                Cards
                    .get($scope.formName)
                    .then(form => {
                        if ($scope.formName == 'admissions') {
                            $rootScope.admissionsForm = form;
                        }
                        $scope.form = form;
                    });
            },
            template: `  
    <div class='row group-title'>
        <label chai-markdown='form.label'></label>
    </div> 
    <div class='row back-to-form'>
    <a ng-class='{"nodisplay" : signoff}'
       ui-sref='patient.staticforms'>
      <span translate>back</span>
    </a>
  </div>
    <div class='patient data'>       
      <div class='fill'>
        <div class='third' ng-repeat='group in form.groups'>
          <a ui-sref='^.group({ form: formName, group: $index, card: "" })'>
            <div class='item'>
              <radial-progress progress-id='group.name'>
                <span>
                  <i md-colors="{color: 'primary-700'}"
                     ng-class='["fa-2x", group.icon]'>
                  </i>
                  <i class='icon2-unapproved fa-stack-1x trainee-light'
                     style='top: 2em; left: 2.7em; font-size: 1.2em'
                     ng-show='countersignStatus.static[formName].groups[group.name]'>
                  </i>
                </span>
              </radial-progress>
              <label md-colors="{color: 'primary-100'}" ng-bind='group.label'></label>
            </div>
          </a>
        </div>
      </div>  
    </div>
    <div class="center">
    <print name='form.name' patient-id='patient.id' type='form.type'>
      <div class='panel floating blank'>
        <i class='fas fa-2x fa-print'></i>
        <h5 translate>print</h5>
      </div>
    </print>
     </div>
      
  `
        },
        title: {
            controller: function ($scope, $state, Cards) {
                'ngInject';
                $scope.form = {};
                //ok to call Cards.get because it caches form definitions
                Cards.get($state.params.name)
                    .then(form => {
                        $scope.form = form;
                    });
            },
            template: `
   <span ng-bind='form.label'></span>
  `
        }
    }
};
