module.exports = {
    url: '/',
    controller: function ($scope, PatientStore, $rootScope, $window,
                          Cards, signOffInfo, $http, $translate) {
        'ngInject';
        $scope.patients = [];

        $scope.$on('IdleStart', function () {
            $rootScope.updateToken();
        });
        $scope.$on('IdleEnd', function () {
            $rootScope.updateToken();
        });
        $scope.$on('IdleTimeout', function () {
            $rootScope.logout();
        });

        $rootScope.patientId = undefined;

        if ($rootScope.signoffLater) {
            $rootScope.signoffLater.value = false;
        }

        $translate.use($rootScope.locale);
        moment.locale($rootScope.locale);

        $scope.trainee = $rootScope.trainee;

        $window.document.title = 'CHAI - My List';

        PatientStore.load()
            .then(Patient => {
                $scope.patients = [];
                Patient.all(function (patients) {
                    $scope.patients = patients;
                    setTimeout(window.scrollTo(0, 0), 0);
                }, err => {
                });
            })
            .catch(err => {
                console.error('Could not load patients:', err);
            });
    },
    template: `
    <navigation-bar></navigation-bar>
    <title-bar>
      <div class='left'>
      </div>
      <div class='right'>
      </div>
    <div translate class="center">
        header_2
    </div>
    </title-bar>
    <main>
      <div class='patient-list'>
        <div class='patient-summary'
             ng-repeat='patient in patients'>
          <patient-preview patient='patient' type='"normal"'>
          </patient-preview>
        </div>
      </div>
    </main>
  `
};
