module.exports = {
    url: '/assessment/:card',
    views: {
        main: {
            controller: function ($scope, $interval, $rootScope, $state, expandCards,
                                  $window, PatientAlerts, FormStore, expandedEntities, CachedPatientForms) {
                'ngInject';
                CachedPatientForms[$scope.$parent.patient.id] = {};
                $scope.loaded = false;
                if ($rootScope.type == 'pro') {
                    $scope.dailyAssistanceLabel = 'Assessment Tools';
                } else {
                    $scope.dailyAssistanceLabel = 'Daily Assistance';
                }

                $window.document.title = $scope.dailyAssistanceLabel;
                $scope.$on('IdleTimeout', function () {
                    $rootScope.logout();
                });
                $scope.formState = {};
                $scope.patient = $scope.$parent.patient;
                $scope.sortedRepForms = [];
                var noStateRepForms = [];

                expandedEntities.static = {};
                expandedEntities.repeating = {};

                function filterAndSortRepForms(forms, value) {
                    var filteredForms = [];
                    for (var form in forms) {
                        if (forms[form].state.state == value) {
                            filteredForms.push(forms[form]);
                        }
                    }
                    filteredForms.sort(function (a, b) {
                        return a.state.date > b.state.date ? 1 : -1;
                    });
                    return filteredForms;
                }
                function prepForms(repeatingForms){
                    if ($rootScope.signoff || $rootScope.signoffLater.value) {
                        repeatingForms.forEach(form => {
                            if (form.name === $state.params.card) {
                                $scope.sortedRepForms.push(form);
                            }
                        });
                    } else {

                        repeatingForms.forEach(form => {
                            if (form.name !== 'access_code') {
                                $scope.formState[form.name] = form;
                                $scope.formState[form.name].state = {};
                                if ($scope.patient.alerts && $scope.patient.alerts.assessment &&
                                    $scope.patient.alerts.assessment[form.name]) {
                                    PatientAlerts.setRepFormState($scope.patient.alerts.assessment[form.name],
                                        $scope.formState[form.name].state);
                                }
                                if ($scope.formState[form.name].state.state === undefined) {
                                    noStateRepForms.push(form);
                                }
                            }

                        });


                        var OverdueRepForms = filterAndSortRepForms($scope.formState, 'overdue');
                        var WarnRepForms = filterAndSortRepForms($scope.formState, 'warn');
                        var DueRepForms = filterAndSortRepForms($scope.formState, 'due');
                        var CompletedRepForms = filterAndSortRepForms($scope.formState, 'completed');

                        $scope.sortedRepForms.push(...OverdueRepForms,
                            ...WarnRepForms,
                            ...DueRepForms,
                            ...CompletedRepForms,
                            ...noStateRepForms);


                        expandCards[$state.params.card] = true;
                    }
                }

                FormStore.patientRepeatingForms($scope.patient.id).then( function (forms) {
                    prepForms(forms);
                    $scope.loaded = true;
                });

            },
            template: `
      <div class="assessment" ng-if="loaded">
        <repeating-form
           ng-repeat='form in sortedRepForms'
           form='form'
           patient='patient'>
        </repeating-form>
      </div>
      `
        },
        title: {
            controller: function ($scope, $rootScope) {
                'ngInject';

                if ($rootScope.type == 'pro') {
                    $scope.dailyAssistanceLabel = 'Assessment Tools';
                } else {
                    $scope.dailyAssistanceLabel = 'Assistance';
                }
            },
            template: `
      {{ dailyAssistanceLabel }}
      `
        }
    }
};
