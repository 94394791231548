angular.module('print', ['server', 'repeatingForms'])

    .service('Printer', function ($q, $http, Server, $rootScope) {
        this.requestURL = function (info) {
            let deferred = $q.defer();

            Server.getConfig()
                .then(function (config) {
                    const httpConfig = {
                        method: 'POST',
                        url: `${config.print.url}`,
                        headers: config.print.headers,
                        withCredentials: false,
                        data: info
                    };

                    httpConfig.data.token = $rootScope.token;

                    $http(httpConfig).then(
                        function success(response) {
                            deferred.resolve({'httpConfig': httpConfig, 'response': response.data});
                        },
                        function error() {

                        }
                    );
                });

            return deferred.promise;
        };
    })

    .directive('print', function (Printer, $mdToast, $rootScope) {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                patientId: '=',
                name: '=',
                type: '='
            },
            controller($scope, $mdDialog) {
                $scope.print = function () {
                    var info = {
                        form: $scope.name,
                        id: $scope.patientId,
                        type: $scope.type,
                        ewstype: $rootScope.ewsType,
                        username: $rootScope.username
                    };

                    $mdDialog.show({
                        parent: angular.element(document.body),
                        template: `
            <md-dialog aria-label='Progress Indicator' class="progress-indicator">
              <div layout="row" layout-sm="column" layout-align="center center">
                <md-progress-circular md-mode="indeterminate">
                </md-progress-circular>
              </div>
            </md-dialog>
          `
                    });

                    Printer.requestURL(info)
                        .then(function (data) {
                            if (data.response.success) {
                                $mdDialog.hide();
                                const url = `${data.httpConfig.url}/${data.response.url}`;
                                window.open(url);
                            } else {
                                $mdDialog.hide();
                                $mdToast.show({
                                    template: '<md-toast>' + data.response.url + '</md-toast>',
                                    hideDelay: 2000,
                                    position: 'bottom center'
                                });
                            }
                        })
                        .catch(function (err) {
                            $mdDialog.hide();
                            console.log(err);
                        });
                };

            },
            template: `
      <a ng-click='print()'>
        <ng-transclude></ng-transclude>
      </a>
    `
        };
    });
