

    angular.module('materialComponentsTable', ['material.core']).controller('mdTableController', [
        '$scope', '$filter', mdTableController
    ]).directive('mdTable', mdTableDirective)
        .filter('trustHtml',function($sce){
            return function(html){
                return $sce.trustAsHtml(html);
            };
        })
        .filter('pageFilter', mdTablePageFilter).filter('contentFilter', [
        '$filter', mdTableContentFilter
    ]);

    /**
     * Create md-table Directive
     */
    function mdTableDirective() {
        return {
            restrict   : 'E',
            scope      : {
                action          : '&onAction',
                contentFilter   : '=filter',
                contents        : '=',
                contentsClass   : '=',
                enableAction    : '=action',
                enablePagination: '=pagination',
                enableSelection : '=selection',
                headers         : '=',
                headersClass    : '=',
                pageCount       : '=?',
                select          : '&onSelect'
            },
            link       : mdTableLink,
            controller : mdTableController,
            template: `
            <div>
<table class="md-table">
  <!-- Header -->
  <thead>
    <tr class="md-table-headers-row">
      <!-- Multiple selection -->
      <th class="md-table-header" ng-show="enableSelection">
        
          <md-checkbox aria-label="Select all" on-change="selectAll(checked)"></md-checkbox>
          
      </th>
      <!-- Content -->
      <th class="md-table-header" ng-class="headersClass[header.contentField]"
        ng-repeat="header in headers">
        <!-- Sortable Field -->
        <a href ng-click="sort(header.contentField, reverse);"
          ng-if="header.sortableField">
          {{header.label}}
          <span class="md-table-caret"
            ng-show="reverse && header.contentField == predicate">
            <i class="fa-solid fa-angle-up"></i>
          </span>
          <span class="md-table-caret"
            ng-show="!reverse && header.contentField == predicate">
            <i class="fa-solid fa-angle-down"></i>
          </span>
          <span class="unsorted" ng-show="!(header.contentField == predicate)"></span>
        </a>
        <!-- Unsortable Field -->
        <span ng-if="!header.sortableField">
          {{header.label}}
        </span>
      </th>
      <!-- Actions -->
      <th class="md-table-header" ng-show="enableAction"></tr>
    </thead>
    <!-- Body -->
    <tbody>
      <tr class="md-table-content-row"
        ng-repeat="content in contents | filter: contentFilter | pageFilter: currentPage
        * pageCount | limitTo: pageCount">
        <!-- Selection -->
        <td class="md-table-td-check" ng-show="enableSelection">
          <md-checkbox aria-label="Select content"
            ng-change="select({selectedContent: content,checked: checkedValue})" ng-model="checkedValue"></md-checkbox>
        </td>
        <!-- Content -->
        <td ng-click="select({selectedContent: content, checked: true})" ng-model="header" ng-repeat="header in headers">
          <div ng-switch="header.contentType">
            <!-- Thumb -->
            <div class="md-table-thumbs" ng-switch-when="image">
              <div style="background-image:url({{content[header.contentField]}})"></div>
            </div>
            <!-- Input -->
            <div class="md-table-content" ng-switch-when="input">
              <md-input-container flex>
                <input aria-label="input" ng-model="content[header.contentField]" type="text" style="color:#000"/>
              </md-input-container>
            </div>
            <!-- Date -->
             <div class="md-table-content" ng-class="contentsClass[header.contentField]"
              ng-switch-when="date">
              {{formatDate(content[header.contentField],header.contentFilter.pattern)}}
            </div>
            <!-- Text -->
            <div class="md-table-content" ng-class="contentsClass[header.contentField]"
              ng-switch-when="text">
              {{content[header.contentField] | contentFilter : header.contentFilter}}
            </div>
            <!-- Html -->
            <div class="md-table-content" ng-class="contentsClass[header.contentField]"
              ng-switch-when="html" ng-bind-html="content[header.contentField] | trustHtml">
            </div>
            <div class="md-table-content" ng-class="contentsClass[header.contentField]"
              ng-switch-when="markdown">
              <span chai-markdown='content[header.contentField]'></span>
            </div>
            
          </div>
        </td>
        <!-- Actions -->
        <td class="md-table-td-more" ng-show="enableAction">
          <md-button aria-label="Action" ng-click="action({selectedContent: content})">
               <i class="fa-solid fa-angle-right"></i>
          </md-button>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- Footer / Pagination -->
  <div class="md-table-footer" ng-show="enablePagination" layout="row">
    <span class="md-table-count-info">Rows per page : <a href="#" ng-click="goToPage(0); pageItems=10">10</a> <a href="#" ng-click="goToPage(0); pageItems=25">25</a> <a href="#" ng-click="goToPage(0); pageItems=50">50</a> <a href="#" ng-click="goToPage(0); pageItems=100">100</a>(current is {{pageItems}})</span><span flex="flex"></span>
    <span flex></span>
    <span ng-show="enablePagination">
      <!-- Previous page -->
      <md-button aria-label="Previous" class="md-table-footer-item" ng-click="previous()"
        ng-disabled="previousDisabled();">
        <i class="material-icons md-18">keyboard_arrow_left</i>
      </md-button>
      <!-- Current page selection -->
      <a class="md-table-page-link" href ng-repeat="page in pages">
        <md-button aria-label="Index" class="md-primary md-table-footer-item"
          ng-click="selectPage(page.index)">
          <span ng-class="{'md-table-active-page': currentPage == page.index}">{{page.index}}</span>
        </md-button>
      </a>
      <!-- Next page -->
      <md-button aria-label="Next" class="md-table-footer-item" ng-click="next()"
        ng-disabled="nextDisabled();">
        <i class="material-icons md-18">keyboard_arrow_right</i>
      </md-button>
    </span>
  </div>
</div>`
        };
    }

    /**
     * Create mdTableController
     *
     * @param {type} $scope
     * @param {type} $filter
     * @returns {undefined}
     */
    function mdTableController($scope, $filter) {

        initializeControllerDatas($scope);
        initializePagination($scope);

        $scope.$watchCollection(function () {
            return $scope.contents;
        }, function () {
            initializePagination($scope);
        });
        /**
         * Sorting content
         */
        $scope.sort             = function (predicate, reverse) {
            $scope.reverse   = !reverse;
            $scope.contents  = $filter('orderBy')($scope.contents, predicate, $scope.reverse);
            $scope.predicate = predicate;
        };

        // standard angular date filter was not working so this will do for now.
        $scope.formatDate = function (content, format){
            return moment(content).format(format);
        };

        /**
         * Display next page
         */
        $scope.next             = function () {
            $scope.currentPage = $scope.currentPage + 1;
        };

        /**
         * Display previous page
         */
        $scope.previous         = function () {
            $scope.currentPage = $scope.currentPage - 1;
        };

        /**
         * Determine if  "Previous" button is enable
         */
        $scope.previousDisabled = function () {
            return $scope.currentPage === 0;
        };

        /**
         * Determine if  "Next" button is enable
         */
        $scope.nextDisabled     = function () {
            return $scope.pages.length === 0 || $scope.currentPage === ($scope.pages.length - 1);
        };

        /**
         * Select the page
         */
        $scope.selectPage       = function (pageIndex) {
            $scope.currentPage = pageIndex;
        };


        $scope.nbOfPages = function () {
            return Math.ceil($scope.content.length / $scope.pageItems);
        };
    }

    /**
     * Create mdTableLink
     */
    function mdTableLink($scope, $element, $attr) {
        $element.attr({
            'role': 'table'
        });
    }

    /**
     * @return The filter use to paginate
     */
    function mdTablePageFilter() {
        return function (contents, selectedPage) {
            selectedPage = +selectedPage;
            if (contents) {
                return contents.slice(selectedPage);
            } else {
                return '';
            }
        };
    }

    /**
     * @return The filter use to paginate
     */
    function mdTableContentFilter($filter) {
        var result;
        return function (value, contentFilter) {
            if (contentFilter) {
                if (contentFilter.pattern) {
                    result = $filter(contentFilter.filter)(value, contentFilter.pattern);
                    return result;
                } else {
                    result = $filter(contentFilter.filter)(value);
                    return result;
                }
            } else {
                return value;
            }
        };
    }

    /**
     * Initialization of controller's data
     */
    function initializeControllerDatas($scope) {
        // Sorting
        $scope.reverse     = true;
        $scope.predicate   = '';
        // Pagination
        $scope.currentPage = 0;
        initializePagination($scope);
    }

    /**
     * Determine the number of pages
     *
     * @return Pages representing by an array
     */
    function initializePagination($scope) {
        var contents = $scope.contents;
        var pages = [];
        if (contents) {
            if (!$scope.pageCount) {
                $scope.pageCount = contents.length;
            }
            var numberOfPages = Math.ceil(contents.length / $scope.pageCount);
            for (var i = 0; i < numberOfPages; i++) {
                pages.push({
                    index: i,
                    name : 'page' + i
                });
            }
        }
        $scope.pages = pages;
    }

    /**
     * This method help to create an header object
     *
     * @param label
     *            Label of the header (Mandatory)
     * @param contentField
     *            Link with the content's field (Mantadory)
     * @param contentType
     *            Nature of content's field (Default => 'text' :
     *            - 'input'
     *            - 'text'
     *            - 'image'
     * @param sortableField
     *            Flag to indicate if the field is sortable
     */
    function mdTableHeader(label, contentField, contentType, sortableField) {
        this.label         = label;
        this.contentField  = contentField;
        this.contentType   = contentType || 'text';
        this.sortableField = sortableField || false;
    }

    /**
     * This method help to create a content object
     *
     * @param data
     *            Content data's (Mandatory)
     */
    function mdTableContent(data) {
        this.data = data;
    }

