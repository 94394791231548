/**
 * @name messages
 * @ngdoc module
 * @description
 * Components to deal with messages
 */

angular.module('messages', [])
    .service('Messages', function (Server, $rootScope, $q, signOffInfo, $mdDialog) {
        this.load = function (channel) {
            if (channel === 'ward') {
                channel = $rootScope.community;
            }
            return Server.createResource(`/message/${channel}`, {
                post: {
                    method: 'POST',
                    url: `/message/${channel}`
                },

                count: {
                    method: 'GET',
                    url: `/message/${channel}/count`
                }
            });
        };

        this.countersign = function () {
            return Server.createResource('/message', {
                approve: {
                    method: 'POST',
                    url: '/message/approve/:messageId'
                },

                reject: {
                    method: 'POST',
                    url: '/message/reject/:messageId'
                }
            });
        };

        this.send = function (channel, messageContent) {

            let deferred = $q.defer();
            let params = {};

            if ($rootScope.signoff) {
                params.secret = signOffInfo.secret;
            }

            this.load(channel).then(MessageStore => {
                MessageStore.post(params, messageContent, function () {
                    deferred.resolve();
                }, err => {
                });
            });

            return deferred.promise;
        };


        this.filters = {
            'all': ['text', 'generated', 'task', 'task_completed', "task_assigned", "task_commented", 'audio', 'image', 'document'],
            'text': ['text'],
            'generated': ['generated'],
            'attachments': ['audio', 'image', 'document'],
            'tasks': ['task', 'task_completed', "task_assigned", "task_commented"]
        };

        this.showCountersignDialog = function (note, username) {
            if ($rootScope.trainee) {
                return;
            }
            $mdDialog.show({
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                locals: {note: note, username: username},
                template: `
          <md-dialog style='max-width: 40em;'>
            <md-dialog-content>
              <countersign-note-view note='note' username='username'
                                     on-dialog='true' on-completion='closeDialog()'>
              </countersign-note-view>
            </md-dialog-content>
          </md-dialog>
          `,
                controller: function DialogController($scope, $mdDialog, Messages, note, username) {

                    $scope.note = note;
                    $scope.username = username;

                    $scope.closeDialog = function () {
                        $mdDialog.hide();
                    };
                }
            });
        };
    })

    /*
     * Note Countersign UI
     */

    .directive('countersignNoteView', function () {
        return {
            scope: {
                note: '=',
                username: '=',
                onDialog: '=',
                onCompletion: '&'
            },
            controller: function ($scope, Messages, signOffInfo, $rootScope, CountersignStatus, $translate) {
                $scope.reason = '';

                $translate($rootScope.patientLabel).then(function (translation) {
                    $scope.patientLabel = translation;
                });

                const params = {messageId: $scope.note.id};
                if ($rootScope.signoff) {
                    params.secret = signOffInfo.secret;
                }

                $scope.countersignNote = function () {
                    Messages.countersign().then(function (MessageStore) {
                        MessageStore.approve(params, {}, function (resp) {
                            let currentStatus = CountersignStatus.getStatus();
                            CountersignStatus.update(currentStatus, resp.approvals);
                            $scope.onCompletion();
                        });
                    });
                };

                $scope.rejectNote = function () {
                    Messages.countersign().then(function (MessageStore) {
                        MessageStore.reject(params, {reason: $scope.reason}, function (resp) {
                            let currentStatus = CountersignStatus.getStatus();
                            CountersignStatus.update(currentStatus, resp.approvals);
                            $scope.onCompletion();
                        });
                    });
                };
            },
            template: `
      <div class='countersign-note'>

        <div layout='row' layout-align='space-between'>
          <div class='teal-velvet' style='font-size: 1.5em;'>
            Countersign {{ patientLabel }} Note
          </div>
          <div ng-if='onDialog'>
            <i class='fas fa-times' ng-click='onCompletion()'></i>
          </div>
        </div>

        <div style='margin: .5em 0;'>
          Please review the note below and countersign it
        </div>

        <div class='countersign-note__note'>
          <note data='note'
                username='username'
                hide-countersign-link='true'>
          </note>
        </div>

        <div>
          <textarea ng-class='[{"expand": expand}]'
                    ng-model='reason'
                    ng-focus='expand = true'
                    placeholder='Reason for rejection'>
          </textarea>
        </div>

        <div layout='row' layout-xs='column' layout-align='space-between'
             layout-align-xs='space-between center'>
          <div>
            <md-button ng-class='["md-raised countersign-note__action-button countersign-note__action-button--countersign",
                                 {"countersign-note__action-button--disabled": reason}]'
                       ng-click='countersignNote()'>
              <i class="icon2-countersign"></i><span> Countersign</span>
            </md-button>
          </div>
          <div>
            <md-button ng-click='rejectNote()'
   ng-class='["md-raised countersign-note__action-button countersign-note__action-button--reject",
                                 {"countersign-note__action-button--disabled": reason.length < 5}]'>
              <i class="far fa-trash-alt"></i><span> Reject</span>
            </md-button>
          </div>
        </div>

      </div>
    `
        };
    })
    .directive('channelNotes', function () {
        return {
            restrict: 'E',
            scope: {
                username: '=',
                channel: '=',
                filter: '='
            },
            controller: function ($scope, $rootScope, PatientAlerts, Pagination) {
                $scope.trainee = $rootScope.trainee;
                $scope.showNav = {value: false};
                $scope.selected = 0;

                $scope.pageDetails = Pagination.details;


                $scope.changePage = function (page, relative) {
                    $scope.pageDetails.currentPage = relative ? $scope.pageDetails.currentPage + page : page;
                    $scope.selected = $scope.pageDetails.currentPage;
                    Pagination.updateNotes($scope.channel, Pagination.details.currentView, true);
                };

                this.$onInit = function () {
                    if ($scope.filter === null || $scope.filter === undefined) {
                        Pagination.updateNotes($scope.channel, 'all', true);
                    } else {
                        Pagination.updateNotes($scope.channel, $scope.filter, false);
                    }
                };

            },
            template:
                `

    <div layout="row" layout-align='stretch' style='min-height: 100vh'>
      <div flex="25" flex-xs='0' hide-xs layout='column' layout-align='stretch'>
        <notes-filter flex layout='column' layout-align='stretch' channel='channel' initial-view='filter'
                      vertical='true'>
        </notes-filter>
      </div>

      <md-sidenav md-disable-scroll-target='body' hide-gt-xs md-component-id='filter-sidenav'
                  md-is-open='showNav.value' class="filters-sidenav">
        <notes-filter flex layout='column' layout-align='stretch' channel='channel' initial-view='filter'
                      vertical='true'>
        </notes-filter>
      </md-sidenav>

      <div flex='75' flex-xs='100'>
        <div class='group-title' ng-if='channel !== "ward"'>
          <div class='print'>
            <print name='"default"'
             type='"notes"'
                   patient-id='channel'>
                <i md-colors="{color: 'primary-200'}" class='fas fa-print'></i>
                <span md-colors="{color: 'primary-200'}">Print</span>
            </print>
          </div>
        </div>

      <create-message channel='channel'></create-message>
      <div hide-gt-xs md-colors="{color: 'primary-200'}"
           ng-click='showNav.value = true'
           style='margin-left:.5em; cursor: pointer'>
        <i class="fas fa-filter"></i>
        <span>Toggle Filters</span>
      </div>

        <div layout class='page-numbers' layout-align='space-between'>

        <div layout>
          <div ng-if='pageDetails.currentPage < pageDetails.maxPage'
               ng-click='changePage(1, true)'>
            <i class="fas fa-caret-left"></i>
            <span translate>prev</span>
          </div>

          <div ng-if='pageDetails.currentPage == pageDetails.maxPage'
               class='page-numbers__prev'>
            <i class="fas fa-caret-left"></i>
            <span translate>prev</span>
          </div>

          <div layout ng-if='$first ||
                      $last ||
                      selected == (pageDetails.maxPage - $index) ||
                      (selected + 1) == (pageDetails.maxPage - $index) ||
                      (selected - 1) == (pageDetails.maxPage - $index)'
               ng-repeat='page in [].constructor(pageDetails.maxPage + 1) track by $index'>

            <div ng-if='$last && selected > 2'>...</div>

            <div ng-class='{"selected": selected === (pageDetails.maxPage - $index), "page-number": true}'
             ng-click='changePage(pageDetails.maxPage - $index)'>
             <span ng-bind='$index + 1'></span>
            </div>

            <div ng-if='$first && pageDetails.maxPage - selected > 2'>...</div>


          </div>

          <div ng-click='changePage(-1, true)'
               ng-if='pageDetails.currentPage > 0'>
            <span translate>next</span>
            <i class="fas fa-caret-right"></i>
          </div>
          <div ng-if='pageDetails.currentPage == 0'  class='page-numbers__next'>
            <span translate>next</span>
            <i class="fas fa-caret-right"></i>
          </div>

        </div>

          <div>
            <md-input-container md-no-float class='page-numbers__page-dropdown'>
              <md-select aria-label='Skip To Page'
                         md-no-underline
                         md-no-asterisk
                         ng-model='selected'
                         ng-change='changePage(selected)'>
                <md-option ng-repeat='page in [].constructor(pageDetails.maxPage + 1) track by $index'
                           ng-value='$index'>
                  {{ pageDetails.maxPage + 1 - $index }}
                </md-option>
              </md-select>
            </md-input-container>
          </div>

        </div>

        <notes-page channel='channel'
                    username='username'>
        </notes-page>

      </div>
    </div>
      `
        };
    })

    /*
     * Directive to show an overview of patient
     * notes. Used at patient dashboard and search
     */

    .directive('channelNotesOverview', function (PatientAlerts, Pagination) {
        return {
            restrict: 'E',
            scope: {
                channel: '=',
                username: '=',
                showFilter: '=',
                showCreateMessage: '=',
                showNotePageLink: '='
            },
            controller: function ($scope, $rootScope, $translate) {
                $scope.trainee = $rootScope.trainee;

                $translate($rootScope.patientLabel).then(function (translation) {
                    $scope.patientLabel = translation;
                });

                Pagination.updateNotes($scope.channel, 'all');

            },
            template: `
      <div md-colors="{background: 'primary-300', color: 'primary-50'}"
           class='messagetitle'>
        <i class='fas fa-sticky-note'></i>
        <span translate translate-value-label="{{ patientLabel }}">notes_9</span>
        <a style='color: white' class='right' ui-sref='patient.messages({id: channel})'>
        <span translate>notes_1</span>
        <i class="fas fa-caret-right"></i>
        </a>
      </div>

      <notes-page username='username'
                  channel='channel'
                  show-filter='showFilter'
                  show-create-message='showCreateMessage'>
      </notes-page>

      <div class='center' ng-if='showNotePageLink'>
        <a ui-sref='patient.messages({id: channel})'>
          <i class="fas fa-sticky-note"></i>
          <span translate translate-value-label="{{patientLabel}}">notes_10</span>
        </a>
      </div>

    `
        };
    })


    .directive('notesFilter', function (PatientAlerts, Messages, Pagination,
                                        CountersignStatus) {
        return {
            scope: {
                channel: '=',
                vertical: '=',
                initialView: '='
            },
            controller: function ($scope, $rootScope, $mdSidenav, $mdMedia, $timeout) {

                $scope.details = Pagination.details;
                $scope.countersignStatus = CountersignStatus.getStatus();

                $scope.trainee = $rootScope.trainee;
                $scope.allAlerts = PatientAlerts.getAlerts();


                $scope.changeView = function (view) {
                    if ($scope.view === view) {
                        //set back to all if was already this view.
                        view = 'all';
                    }

                    $scope.view = view;
                    Pagination.updateNotes($scope.channel, view);
                    if ($mdMedia('xs') && $scope.vertical) {
                        $timeout(function () {
                            $mdSidenav('filter-sidenav').close();
                        }, 500);
                    }
                };

                this.$onInit = function () {
                    if ($scope.initialView === undefined) {
                        $scope.view = 'all';
                    } else {
                        $scope.view = $scope.initialView;
                    }
                };


            },
            template: `

      <div flex ng-if='vertical' layout='column'
           ng-class='["notes-filters-vertical",
                      {"notes-filters-vertical--selected": details.currentView !== "all"}]'>

        <div md-colors="{color: 'primary-500'}"
             style='cursor:default'
             class='notes-filters-vertical__title'>
          <i class="fas fa-filter"></i>
          <span>Note Filters</span>
        </div>

        <div ng-class='["notes-filters-vertical__filter",
                       {"notes-filters-vertical__filter--selected" : details.currentView == "text"}]'
             ng-click='changeView("text")'>
          <i class='far fa-sticky-note'></i>
          <span translate>notes_4</span>
          <i ng-if='countersignStatus.notes.text'
             class='icon2-unapproved trainee-light'>
          </i>
        </div>

        <div ng-class='["notes-filters-vertical__filter",
                       {"notes-filters-vertical__filter--selected" : details.currentView == "generated"}]'
             ng-click='changeView("generated")'>
          <i class='fas icon3-System-Notes'></i>
          <span translate>notes_5</span>
          <i ng-if='countersignStatus.notes.generated'
             class='icon2-unapproved trainee-light'>
          </i>
        </div>

        <div ng-class='["notes-filters-vertical__filter",
                       {"notes-filters-vertical__filter--selected" : details.currentView == "attachments" }]'
             ng-click='changeView("attachments")'>
          <i class='fas fa-paperclip fa-flip-vertical'></i>
          <span translate>notes_6</span>
          <i ng-if='countersignStatus.notes.attachments'
             class='icon2-unapproved trainee-light'>
          </i>

        </div>

        <div ng-class='["notes-filters-vertical__filter",
                       {"notes-filters-vertical__filter--selected" : details.currentView == "tasks" }]'
             ng-click='changeView("tasks")'>

              <i class='far fa-check-square'></i>
              <span translate>notes_7</span>

              <i ng-if='countersignStatus.notes.tasks'
                 class='icon2-unapproved trainee-light'>
              </i>

              <i style='position: relative; top: 1px; right: 3px;'
                 class='icon3-alarm-clock overdue'
                 ng-if='allAlerts.taskOverdue'>
              </i>

              <i class='fas fa-bell warn'
                 ng-if='allAlerts.taskWarn'>
              </i>
        </div>

        <div md-colors="{color: 'primary-500'}"
             class='notes-filters-vertical__reset'
             ng-if='details.currentView !== "all"'
             ng-click='changeView("all")'>
          <i class="fas fa-times"></i>
          <span translate>notes_8</span>
        </div>

      </div>

      <div ng-if='!vertical' class='notes-filters-horizontal'>

      <div layout='row'>

        <div flex='5' md-colors="{color: 'primary-500'}"
             class='notes-filters-horizontal__initial-icon'>
          <i class="fas fa-filter"></i>
        </div>

        <div flex='95' layout-wrap layout>

        <div ng-class='["notes-filters-horizontal__filter",
                       {"notes-filters-horizontal__filter--selected" : details.currentView == "text"}]'
             ng-click='changeView("text")'>
          <i class='far fa-sticky-note'></i>
          <span translate>notes_4</span>

          <i ng-if='countersignStatus.notes.text'
             class='icon2-unapproved trainee-light'>
          </i>

        </div>

        <div ng-class='["notes-filters-horizontal__filter",
                       {"notes-filters-horizontal__filter--selected" : details.currentView == "generated"}]'
             ng-click='changeView("generated")'>
          <i class='fas icon3-System-Notes'></i>
          <span translate>notes_5</span>
          <i ng-if='countersignStatus.notes.generated'
             class='icon2-unapproved trainee-light'>
          </i>
        </div>

        <div ng-class='["notes-filters-horizontal__filter",
                       {"notes-filters-horizontal__filter--selected" : details.currentView == "attachments"}]'
             ng-click='changeView("attachments")'>
          <i class='fas fa-paperclip fa-flip-vertical'></i>
          <span translate>notes_6</span>
          <i ng-if='countersignStatus.notes.attachments'
             class='icon2-unapproved trainee-light'>
          </i>
        </div>

        <div ng-class='["notes-filters-horizontal__filter",
                       {"notes-filters-horizontal__filter--selected" : details.currentView == "tasks"}]'
             ng-click='changeView("tasks")'>

              <i class='far fa-check-square'></i>
              <span translate>notes_7</span>
              <i ng-if='countersignStatus.notes.tasks'
                 class='icon2-unapproved trainee-light'>
              </i>

               <i style='position: relative; top: 1px; right: 3px;'
                  class='icon3-alarm-clock overdue'
                 ng-if='allAlerts.taskOverdue'>
              </i>

              <i class='fas fa-bell warn'
                 ng-if='allAlerts.taskWarn'>
              </i>

        </div>

        <div md-colors="{color: 'primary-500'}"
             class='center notes-filters-horizontal__filter notes-filters-horizontal__filter--selected no-border'
             ng-if='details.currentView !== "all"'
             ng-click='changeView("all")'>
          <i class="fas fa-times"></i>
          <span>Reset</span>
        </div>

      </div>


      </div>


    </div>


    `
        };
    })


    .directive('notesPage', function () {
        return {
            restrict: 'E',
            scope: {
                username: '=',
                channel: '=',
                showFilter: '=',
                showCreateMessage: '='
            },
            controller: function ($scope, Pagination, Utils) {
                $scope.notes = Pagination.currentNotes;
            },
            template: `

      <create-message ng-if='showCreateMessage'
                      channel='channel'>
      </create-message>

      <notes-filter ng-if='showFilter'
                    channel='channel'>
      </notes-filter>

      <div class='notes'>
        <div ng-repeat='note in notes'>
          <div class='notes__separator'
               ng-if='($index == 0) || (note.date != notes[$index - 1].date)'>
            {{ note.date }}
          </div>

          <div class='notes__separator notes__separator--nodate'
               ng-if='($index != 0) && (note.date == notes[$index - 1].date)'>
          </div>


        <div style='padding: .5em' layout>
          <note flex='80' flex-xs='100'
                ng-class='{"flex-offset-gt-xs-20": note.username === username}'
                data=note
                channel=channel
                username=username>
          </note>
        </div>

      </div>

      <!-- <div ng-if='notes.length === 0'>
        <div layout='column'>
          <div layout='row'>
            <div flex='30'>
              <i class="fas fa-info-circle"></i>
            </div>
            <div flex='70'>
              Nothing to see here
            </div>
          </div>
          <div flex-offset='30' flex='70'>
            No applicable notes to show you
          </div>
        </div>
      </div> -->
    </div>


  `
        };
    })


    .directive('createMessage', function (maxUpload) {
        return {
            restrict: 'E',
            scope: {
                channel: '='
            },
            controller: function ($scope, $rootScope, $timeout, $mdMedia, $translate) {

                $scope.trainee = $rootScope.trainee;
                $scope.canSendMessages = $rootScope.canSendMessages;
                $scope.ward = $scope.channel === 'ward';

                $scope.$watch(function () {
                    return $mdMedia('gt-sm');
                }, function (big) {
                    if ($scope.ward) {
                        if (big) {
                            $scope.textareaStyle = {
                                'min-height': '4em',
                                'max-height': '4em'
                            };
                        } else {
                            $scope.textareaStyle = {
                                'min-height': '5em',
                                'max-height': '5em'
                            };
                        }
                    } else {
                        $scope.textareaStyle = {
                            'min-height': '2.2em',
                            'max-height': '2.2em'
                        };
                    }
                });


                $translate('notes_11').then(function (translation) {
                    $scope.placeholder = translation;
                });

                if ($scope.ward) {
                    $scope.placeholder = 'Write open Group Chat here. Use Personal Notes on individual’s dashboard for private matters.';
                }

                $scope.maxUpload = maxUpload;

                $scope.fileUpload = function () {
                    $scope.fileError = false;
                    if ($scope.note.data) {
                        $scope.note.name = $scope.note.data.name;
                    } else {
                        $scope.fileError = true;
                    }
                };

                $scope.recalculateInterval = function(){

                    var now = moment();
                    var due = moment($scope.note.due.date);
                    var duration = moment.duration(due.diff(now));
                    $scope.note.due.days = duration.days();
                    $scope.note.due.hours = duration.hours();
                    $scope.note.due.minutes = duration.minutes();
                };

                $scope.recalculateDate = function(){
                    var now = moment();

                    if ($scope.note.due.days) {
                        now.add($scope.note.due.days,'days');
                    }

                    if ($scope.note.due.hours) {
                        now.add($scope.note.due.hours,'hours');
                    }

                    if ($scope.note.due.minutes) {
                        now.add($scope.note.due.minutes,'minutes');
                    }
                    $scope.note.due.date = new Date(now.format('YYYY-MM-DDTHH:mm'));
                };

                $scope.clearFile = function () {
                    delete $scope.note.data;
                    delete $scope.note.name;
                };

                $scope.minDate = moment().format('YYYY-MM-DDTHH:MM');

                $scope.note = {due: {}};

                $scope.selectNoteType = function (type) {
                    $scope.fileError = false;
                    if (type === 'text' && $scope.note.type) {
                        return;
                    }

                    if (type !== $scope.note.type) {
                        delete $scope.note.data;
                        delete $scope.note.name;
                        $scope.note.due = {};
                        $scope.note.type = type;


                        if (type === 'document') {
                            $timeout(function () {
                                angular.element(document.querySelector('#file-data')).triggerHandler('click');
                            });
                        } else if (type === 'image') {
                            $timeout(function () {
                                angular.element(document.querySelector('#image-data')).triggerHandler('click');
                            });
                        }
                    }

                };
            },
            template: `

    <div ng-if='canSendMessages'
         md-colors="{background: 'primary-A100'}"
         class='create-message'>

      <div>
        <textarea md-colors="{'border-color': !!note.type ? 'primary-500' : 'primary-200'}"
                  ng-class='[{"expand": !!note.type}]'
                  ng-style=textareaStyle
                  ng-model='note.caption'
                  ng-attr-placeholder='{{ placeholder }}'
                  ng-focus='selectNoteType("text")'>
        </textarea>

      </div>

      <div layout="row" layout-xs='column'
           ng-if='!!note.type'
           layout-align='space-between'>

        <div class='create-message__notes' layout='row'>

          <div ng-click='selectNoteType("document")'>
            <i ng-class='["fas fa-paperclip fa-flip-vertical",
               {"deactivate" : note.type !== "document" && note.type !== "text"}]'>
            </i>
            <span translate ng-if='note.type === "document"'>notes_15</span>
          </div>

          <div ng-click='selectNoteType("audio")'>
            <i ng-class='["fas fa-microphone",
                         {"deactivate": note.type !== "audio" && note.type !== "text"}]'>
            </i>
            <span translate ng-if='note.type === "audio"'>notes_16</span>
          </div>

          <div ng-click='selectNoteType("image")'>
            <i ng-class='["far fa-image",
                         {"deactivate": note.type !== "image" && note.type !== "text"}]'>
            </i>
            <span translate ng-if='note.type === "image"'>notes_17</span>
          </div>

          <div ng-click='selectNoteType("task")'>
            <i ng-class='["far fa-check-square",
                         {"deactivate": note.type !== "task" && note.type !== "text"}]'>
            </i>
            <span translate ng-if='note.type === "task"'>notes_18</span>
          </div>

        </div>

        <div ng-if='note.type === "text"'>
          <send-note note='note' channel='channel'></send-note>
        </div>
      </div>

      <div ng-if='note.type === "document"'
           layout='row' layout-wrap
           layout-align='space-between'>

        <div flex-xs='100' flex>
          <div translate ng-if='!note.name' id="file-data"
               class='create-message__file-selection'
               ngf-select='fileUpload()'
               ng-model='note.data' ngf-max-size="{{maxUpload}}">
               notes_2
          </div>

          <div md-colors="{background: 'primary-1000', color: 'primary-1100'}"
               class='create-message__selected-document' ng-if='note.name'>
            <span ng-bind='note.name' style="overflow-wrap: break-word; "></span>
            <i ng-click='clearFile()'
               class='far fa-trash-alt right'>
            </i>
          </div>

          <div class='warn' ng-if="fileError">
            <span> File too big </span>
          </div>

        </div>

        <div flex-xs='100' flex-sm='50' flex-gt-sm='45'>
          <send-note channel='channel' note='note'></send-note>
        </div>
      </div>

      <div ng-if='note.type === "audio"'>
        <div class='audio-container'>
          <audio-input src='note' ward='ward'></audio-input>
        </div>

        <div style='margin-top: 1.5em;' layout layout-align='end'>
          <send-note channel='channel' note='note'></send-note>
        </div>
      </div>

      <div ng-if='note.type === "image"'
           layout='row' layout-wrap
           layout-align='space-between'>

        <div flex-xs='100'>

          <div translate ng-if='!note.name' id="image-data"
               ngf-select='fileUpload()'
               accept='image/*'
               class='create-message__file-selection'
               ng-model='note.data' ngf-max-size="{{maxUpload}}">
               notes_3
          </div>

          <div ng-if='note.name'>
            <img alt="Note Image" ngf-src='note.data' />
          </div>

          <div class='warn' ng-if="fileError">
            <span> File too big </span>
          </div>

        </div>

        <div flex-xs>
          <send-note channel='channel' note='note'></send-note>
        </div>

      </div>

      <div ng-if='note.type === "task"' >
      <div layout="column" style="padding-top: 0.5em" class = "notes__task-container">
    <div layout-gt-xs="row" layout-xs="column" layout-align-gt-xs="space-between center" style="padding-bottom: 0.5em;">
        <label id="assignedToLabel" flex="10" style="margin-bottom: 0.5em">Assign</label>
        <div flex="50"><task-assign-dropdown search-text="searchText" selected-item="note.assignedTo"></task-assign-dropdown></div>
        <span class="italicize" style="color: #666" flex-offset-gt-sm="10" flex-offset-sm="0" flex="30">
        {{note.assignedTo? note.assignedTo.preferredName : 'Unassigned'}}</span>
    </div>
    <div layout-gt-xs="row" layout-xs="column" layout-align-gt-xs="space-between center" style="padding-bottom: 0.5em;">
        <label id="assignedToLabel" flex="10" style="margin-bottom: 0.5em">Type</label>
          <div flex="50">
            <task-type-dropdown search-text="taskTypeSearchText" selected-item="note.taskType"></task-type-dropdown>
          </div>
        <span class="italicize" style="color: #666" flex-offset-gt-sm="10" flex-offset-sm="0" flex="30">
        </span>
    </div>

    <div layout-gt-xs="row" layout-xs="column" layout-align-xs="start start" layout-align-gt-sm="space-between center">
        <div flex="50" layout="column">
            <div style='margin: .5em 0;'>
                <span translate>notes_14</span>
            </div>

            <div layout='row' layout-xs='column' >

                <div  class='create-message__task-duration' layout='row'>
                    <div>
                        <input md-colors="{'border-color': 'primary-A700'}"
                               flex type='number' ng-change="recalculateDate()" ng-model='note.due.days'/>
                        <span translate>days</span>
                    </div>
                    <div>
                        <input md-colors="{'border-color': 'primary-A700'}"
                               flex type='number' ng-change="recalculateDate()" ng-model='note.due.hours'/>
                        <span translate>hours</span>
                    </div>
                    <div>
                        <input md-colors="{'border-color': 'primary-A700'}"
                               flex type='number' ng-change="recalculateDate()" ng-model='note.due.minutes'/>
                        <span translate>minutes</span>
                    </div>
                </div>
            </div>
        </div>
        <span flex="shrink" style="color: #666">or</span>
        <div flex="40" layout="column" class="" >
            <div style="margin: .5em 0;"><span id="taskdate-description" translate>This task is due on</span></div>
            <div class="create-message__task-duration date-time-wrapper">
            <input md-colors="{'border-color': 'primary-A700'}" style="border-style: solid;border-width: 1px" type="datetime-local" ng-change="recalculateInterval()" ng-model="note.due.date"  id="duedate-time"  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}" 
       name="due-date"
       min="{{minDate}}">
       
                     </div>
           <div >
                <span style="color: #666">Select Date</span>
          </div>
        </div>


    </div>
    <div style="padding-top: 0.5em">
        <send-note channel='channel' note='note'></send-note>
    </div>
    </div>
</div>

      </div>

    </div>

  `
        };
    })

    .directive('sendNote', function (MediaStore, Messages, signOffInfo, matchmedia, TaskStore) {
        return {
            scope: {
                note: '=',
                channel: '='
            },
            controller: function ($scope, $rootScope, $mdDialog, $state) {

                $scope.ward = $scope.channel === 'ward';
                $scope.trainee = $rootScope.trainee;

                matchmedia.onPhone(function (mql) {
                    $scope.phone = mql.matches;
                });

                $scope.shouldDisable = function () {
                    if ($scope.note.type === 'text') {
                        if ($scope.note.caption) {
                            return false;
                        }
                    }

                    if ($scope.note.type === 'task') {
                        if ($scope.note.caption &&
                            ($scope.note.due.days ||
                                $scope.note.due.hours ||
                                $scope.note.due.minutes || angular.isDefined($scope.note.due.date))) {
                            return false;
                        }
                    }

                    return !($scope.note.caption && $scope.note.data);


                };

                $scope.reset = function () {
                    delete $scope.note.data;
                    delete $scope.note.caption;
                    delete $scope.note.name;
                    delete $scope.note.type;
                    $scope.note.taskType = {name: 'No Task Type', description: ''};
                    $scope.note.assignedTo = null;
                    $scope.searchText = "";
                    $scope.note.due = {};
                };

                $scope.send = function () {

                    $mdDialog.show({
                        parent: angular.element(document.body),
                        template: `
          <md-dialog aria-label='Progress Indicator' class="progress-indicator">
            <div layout="row" layout-sm="column" layout-align="center center">
              <md-progress-circular md-mode="indeterminate">
              </md-progress-circular>
            </div>
          </md-dialog>
        `
                    });

                    if ($scope.note.type === 'image' || $scope.note.type === 'document') {
                        delete $scope.note.due;
                        delete $scope.note.assignedTo;
                        delete $scope.note.taskType;

                        MediaStore.postMedia($scope.note.data.type, !$scope.ward).then(Media => {
                            Media.send($scope.note.data, function (resp) {
                                $scope.note.data = resp.path;
                                Messages.send($scope.channel, $scope.note).then(function () {
                                    $mdDialog.hide();
                                    $scope.reset();
                                });
                            });
                        });
                    } else {
                        if ($scope.note.type === 'text') {
                            $scope.note.data = $scope.note.caption;
                            delete $scope.note.due;
                            delete $scope.note.caption;
                            delete $scope.note.assignedTo;
                            delete $scope.note.taskType;

                        } else if ($scope.note.type === 'task') {
                            $scope.note.data = $scope.note.caption;
                            if ($scope.note.due.date) {
                                var now = moment();
                                var due = moment($scope.note.due.date);
                                var duration = moment.duration(due.diff(now));
                                $scope.note.due.days = duration.days();
                                $scope.note.due.hours = duration.hours();
                                $scope.note.due.minutes = duration.minutes();
                                if($scope.note.taskType && angular.isDefined($scope.note.taskType) && $scope.note.taskType.name !== 'No Task Type') {
                                    $scope.note.taskType = $scope.note.taskType.name;
                                }else {
                                    delete $scope.note.taskType;
                                }
                                delete $scope.note.due.date;
                            }
                            delete $scope.note.caption;
                        } else if ($scope.note.type === 'audio') {
                            delete $scope.note.due;
                            delete $scope.note.assignedTo;

                        }

                        if ($scope.note.type === 'task') {
                            TaskStore.loadTaskResource($scope.channel).then(Task => {
                                Task.create($scope.note, function (resp) {
                                    $mdDialog.hide();
                                    $scope.reset();
                                });
                            });
                        } else {

                            Messages.send($scope.channel, $scope.note).then(function () {
                                $mdDialog.hide();
                                $scope.reset();
                            });
                        }
                    }
                };

                $scope.countersignNow = function () {
                    $scope.send();
                    signOffInfo.capture = true;
                    signOffInfo.next = 'signofflogout';
                    signOffInfo.patientId = $rootScope.patientId;
                    signOffInfo.notes = true;
                    $state.go('signofflogin');
                };

            },
            template: `
    <div layout class='note-action right'>

      <button ng-if='trainee && !ward'
              class='md-no-focus note-action__button note-action__button-send'
              md-colors="{background: 'primary-500', color: 'primary-50'}"
                 ng-click='countersignNow()'
                 ng-disabled='shouldDisable()'>
        <i class="far fa-handshake"></i>
        <span>Now</span>
      </button>

      <button class='md-no-focus note-action__button note-action__button-send'
                 md-colors="{background: 'primary-500', color: 'primary-50'}"
                 ng-click='send()'
                 ng-disabled='shouldDisable()'>
        <i class="fas fa-paper-plane"></i>
        <span translate ng-if='!trainee || (trainee && ward)'>submit</span>
        <span ng-if='trainee && !ward'>Later</span>
      </button>

      <button class='md-no-focus note-action__button note-action__button-cancel'
                 ng-click='reset()'>
        <i class="fas fa-times"></i>
        <span translate>cancel</span>
      </button>

    </div>
  `
        };
    })


    .directive('textNote', function () {
        return {
            restrict: 'E',
            scope: {
                message: '=',
                self: '=',
                hideCountersignLink: '=',
                trainee: '=',
                username: '='
            },
            controller: function ($scope, Messages) {
                $scope.countersign = $scope.message.message.countersign;
                $scope.showCountersignDialog = function () {
                    if (!$scope.countersign || ($scope.countersign && $scope.countersign.state !== 'REQUIRED') || $scope.hideCountersignLink) {
                        return;
                    }
                    Messages.showCountersignDialog($scope.message, $scope.username);
                };

            },
            template: `
    <div ng-if='countersign.state != "REJECTED_COPY"' ng-class='["notes__text-note",
                   {"notes__text-note--self": self,
                    "notes__text-note--other": !self,
                    "notes__text-note--countersign-reqd": countersign.state == "REQUIRED" ,
                    "notes__text-note--countersign-view": hideCountersignLink ,
                    "notes__text-note--reject": countersign.state == "REJECTED"}]'
         ng-click='showCountersignDialog()'>
      <span chai-markdown='message.message.data'></span>
      <span class='notes__review-link'
            ng-if='!hideCountersignLink && (countersign.state == "REQUIRED") && !trainee'>
        Review & Countersign
      </span>
      <span class='notes__review-required-link'
            ng-if='!hideCountersignLink && (countersign.state == "REQUIRED") && trainee'>
        To Be Countersigned
      </span>
    </div>
    <div ng-if='countersign.state == "REJECTED"' style='color: #666'>
    <div>Rejected By: {{countersign._user_ }}</div>
    <div class='reason'>Reason:  {{ countersign.reason }}</div>
    </div>

    <div class='topmargin' layout ng-if='countersign.state == "REJECTED_COPY"'>
      <div>
        <i md-colors="{'color': self ? 'primary-200' : 'primary-A200'}" style='font-size: 2em' class='fas icon3-countersign-rejected'></i>
      </div>
      <div style='margin-left: .5em; line-height: 1em;'>
        <span style='vertical-align: 38%'>
          Rejected a note written by {{ countersign.parent_author }}
        </span><br>
        <span style='vertical-align: 38%'>
          Reason: {{ countersign.reason }}
        </span><br>
        <span class='notes__rejected-text' style='vertical-align: 38%;'
              chai-markdown='message.message.data'></span>
      </div>
    </div>

  `
        };
    })


    .directive('taskNote', function () {
        return {
            restrict: 'E',
            scope: {
                message: '=',
                self: '=',
                hideCountersignLink: '=',
                trainee: '=',
                username: '=',
                channel: '=',
                taskDisabled: '='
            },
            controller: function ($scope, Messages, Utils, $rootScope, $mdDialog, $q, TaskStore) {
                $scope.countersign = $scope.message.message.countersign;
                $scope.showCountersignDialog = function () {
                    if (!$scope.countersign || ($scope.countersign && $scope.countersign.state !== 'REQUIRED')) {
                        return;
                    }
                    Messages.showCountersignDialog($scope.message, $scope.username);
                };

                $scope.taskDueDate = Utils.taskDueFormat($scope.message.message.due);
                $scope.taskWarn = false;
                $scope.taskOverdue = false;

                if ($scope.message.message.assignedTo) {
                    $scope.isAssigned = true;
                }

                if (!$scope.message.message.complete) {
                    $scope.taskWarn = moment().diff($scope.message.message.warn) > 0 &&
                        moment().diff($scope.message.message.due) < 0;
                    $scope.taskOverdue = moment().diff($scope.message.message.due) > 0;
                } else {
                    $scope.disabled = true;
                    $scope.completed = true;
                }

                $scope.$watch('message.message.complete', function () {
                    if ($scope.message.message.complete === true) {
                        $scope.disabled = true;
                        $scope.completed = true;
                        $scope.taskWarn = false;
                        $scope.taskOverdue = false;
                    }
                    if ($scope.message.message.complete === false) {
                        $scope.completed = false;
                        $scope.disabled = false;
                    }
                });

                if ($scope.message.message.restrict !== undefined && $scope.message.message.restrict.indexOf('owner') > -1) {
                    //we can't edit this one.
                    $scope.disabled = true;
                }

                if (!$rootScope.canSendMessages) {
                    $scope.disabled = true;
                }

                $scope.disable = function (ev) {
                    if ($scope.disabled || ($scope.countersign && $scope.countersign.state === 'REQUIRED') ||
                        ($scope.countersign && $scope.countersign.state === 'REJECTED')) {
                        return;
                    }

                    TaskStore.loadTaskResource($scope.channel).then(TaskStore => {
                        var historyPromise = TaskStore.history({id: $scope.message.id}).$promise;
                        var taskPromise = TaskStore.get({id: $scope.message.id}).$promise;

                        return $q.all([historyPromise,taskPromise]).then(function([taskHistory,task]){
                            $mdDialog.show(
                                $mdDialog.taskDetails({
                                    locals: {
                                        selectedTask: task,
                                        taskHistory: taskHistory,
                                        channel: $scope.channel
                                    },
                                })).then(function () {
                            }, function () {
                            });
                        });

                    });

                };
                if (angular.isDefined($scope.taskDisabled) && $scope.taskDisabled) {
                    $scope.disabled = true;
                } else {
                    $scope.taskDisabled = false;
                }
            },
            template: `
      <div layout ng-if='countersign.state != "REJECTED_COPY"'>
        <div>
        <i ng-hide="taskDisabled" md-colors="{'color': 'primary-A200'}"
           ng-class='{"far fa-square": !completed && (countersign.state != "REQUIRED" && countersign.state != "REJECTED"),
                      "far fa-check-square notes__task-completed": completed && (countersign.state != "REQUIRED" && countersign.state != "REJECTED"),
                      "far fa-minus-square pale-wisteria": (countersign.state == "REQUIRED"),
                      "far fa-minus-square notes__task-completed": (countersign.state == "REJECTED")}'
           ng-click='disable($event)'>
        </i>
        </div>
        <div style='margin-left: .5em;' ng-click='showCountersignDialog()'>
        <span ng-class='{"notes__rejected-text": countersign.state == "REJECTED"}'
              chai-markdown='"**Open Task**: " + message.message.data' ng-if='!completed || countersign.state === "REJECTED"'></span>
        <span chai-markdown='"**Task Completed**: " + message.message.data' ng-if='completed && countersign.state != "REJECTED"' class='completed'></span>
        <span chai-markdown='"**Task Assigned**: " + message.message.assignedTo.preferredName' ng-if='isAssigned && countersign.state != "REJECTED"' class='completed'></span>
        <span chai-markdown='"*Task Unassigned*"' ng-if='!isAssigned && countersign.state != "REJECTED"' class='completed'></span>
        <span class='notes__review-link'
              ng-if='!hideCountersignLink && (countersign.state == "REQUIRED") && !trainee'>
          Review & Countersign
        </span>
        <span class='notes__review-link'
              ng-if='!hideCountersignLink && (countersign.state == "REQUIRED") && trainee'>
          To Be Countersigned
        </span>
        <span ng-show="message.message.taskType"
                chai-markdown='"**Type**: " + message.message.taskType'>
          </span>
        <div ng-class='{"warn": taskWarn, "overdue": taskOverdue}'>
          <span class='far fa-bell' ng-show='taskWarn'></span>
          <span style='position: relative; top: 1px;'
                class='icon3-alarm-clock'
                ng-show='taskOverdue'>
          </span>
          <span ng-show="message.message.due !== undefined && !completed"
                ng-bind='"Due: " + taskDueDate'>
          </span>
          <span style='color: #999999;'
                ng-show="message.message.due !== undefined && completed"
                ng-bind='"Was Due: " + taskDueDate'>
          </span>
        </div>
        </div>
      </div>

    <div ng-if='countersign.state == "REJECTED"' style='color: #666'>
      <div>Rejected By: {{countersign._user_ }}</div>
      <div class='reason'>Reason:  {{ countersign.reason }}</div>
    </div>

    <div class='topmargin' layout ng-if='countersign.state == "REJECTED_COPY"'>
      <div>
        <i md-colors="{'color': 'primary-200'}"
           style='font-size: 2em' class='fas icon3-countersign-rejected'></i>
      </div>
      <div style='margin-left: .5em; line-height: 1em;'>
        <span style='vertical-align: 38%'>
          Rejected a task created by {{ countersign.parent_author }}
        </span><br>
        <span style='vertical-align: 38%'>
          Reason: {{ countersign.reason }}
        </span><br>
        <span class='notes__rejected-text' style='vertical-align: 38%;'
              chai-markdown='message.message.data'></span>
      </div>
    </div>

  `
        };
    })


    .directive('taskAssignedNote', function () {
        return {
            restrict: 'E',
            scope: {
                message: '=',
                self: '=',
                hideCountersignLink: '=',
                trainee: '=',
                username: '=',
                channel: '='
            },
            controller: function ($scope) {

                if ($scope.message.message.comment) {
                    $scope.hasComment = true;
                }

            },
            template: `
      <div layout="column">
        <span chai-markdown='"**Task Assigned**: " + message.message.task.message.data' ng-if='message.message.assignedTo' class='completed'></span>
        <span chai-markdown='"**Assignee**: " + message.message.assignedTo.preferredName' ng-if='message.message.assignedTo' class='completed'></span>
        <span chai-markdown='"**Task Unassigned**: " + message.message.task.message.data' ng-if='!message.message.assignedTo' class='completed'></span>
        <span ng-if='hasComment' chai-markdown='"**Comment**: " + message.message.comment' class='completed'></span>
      </div>
  `
        };
    })

    .directive('taskCommentedNote', function () {
        return {
            restrict: 'E',
            scope: {
                message: '=',
                self: '=',
                hideCountersignLink: '=',
                trainee: '=',
                username: '=',
                channel: '='
            },
            controller: function ($scope) {

                if ($scope.message.message.data) {
                    $scope.hasComment = true;
                }

            },
            template: `
      <div layout="column">
        <span chai-markdown='"**Task Updated**: " + message.message.task.message.data' class='completed'></span>
        <span ng-if='hasComment' chai-markdown='"**Comment**: " + message.message.data' class='completed'></span>
      </div>
  `
        };
    })


    .directive('taskCompletedNote', function () {
        return {
            restrict: 'E',
            scope: {
                message: '=',
                hideCountersignLink: '=',
                trainee: '=',
                username: '='
            },
            controller: function ($scope, Messages) {
                $scope.countersign = $scope.message.message.countersign;
                $scope.showCountersignDialog = function () {
                    if (!$scope.countersign || ($scope.countersign && $scope.countersign.state !== 'REQUIRED')) {
                        return;
                    }
                    Messages.showCountersignDialog($scope.message, $scope.username);
                };
            },
            template: `
    <div ng-if='countersign.state != "REJECTED_COPY"' class='topmargin' ng-click='showCountersignDialog()'>
      <span ng-class='{"notes__rejected-text": countersign.state == "REJECTED"}'
            chai-markdown='"**Task Closed:** " + message.message.task.message.data'></span>
      <span ng-class='{"notes__rejected-text": countersign.state == "REJECTED"}'
            chai-markdown='"**Status** : *" + message.message.data + "*"'></span>
      <span ng-if='message.message.comment' chai-markdown='"**Comment**: " + message.message.comment' class='completed'></span>
      <span class='notes__review-link'
            ng-if='!hideCountersignLink && (countersign.state == "REQUIRED") && !trainee'>
        Review & Countersign
      </span>
      <span class='notes__review-link'
            ng-if='!hideCountersignLink && (countersign.state == "REQUIRED") && trainee'>
        To Be Countersigned
      </span>

    </div>

    <div ng-if='countersign.state == "REJECTED"' style='color: #666'>
      <div>Rejected By: {{countersign._user_ }}</div>
      <div class='reason'>Reason:  {{ countersign.reason }}</div>
    </div>

    <div class='topmargin' layout ng-if='countersign.state == "REJECTED_COPY"'>
      <div>
        <i md-colors="{'color': 'primary-200'}"
           style='font-size: 2em' class='fas icon3-countersign-rejected'></i>
      </div>
      <div style='margin-left: .5em; line-height: 1em;'>
        <span style='vertical-align: 38%'>
          Rejected a task completed by {{ countersign.parent_author }}
        </span><br>
        <span style='vertical-align: 38%'>
          Reason: {{ countersign.reason }}
        </span><br>
        <span class='notes__rejected-text' style='vertical-align: 38%;'
              chai-markdown='message.message.data'></span>
         <span class='notes__rejected-text' style='vertical-align: 38%;'
            chai-markdown='message.message.task.message.data'></span>
      </div>
    </div>

  `
        };
    })

    .directive('generatedNote', function () {
        return {
            restrict: 'E',
            scope: {
                message: '=',
                self: '=',
                username: '=',
                trainee: '=',
                hideCountersignLink: '='
            },
            controller: function ($scope, Messages) {
                $scope.countersign = $scope.message.message.countersign;
                $scope.showCountersignDialog = function () {
                    if (!$scope.countersign || ($scope.countersign && $scope.countersign.state !== 'REQUIRED')) {
                        return;
                    }
                    Messages.showCountersignDialog($scope.message, $scope.username);
                };
            },
            template: `
    <div ng-if='countersign.state != "REJECTED_COPY"' class='topmargin' layout>
      <div>
        <i md-colors="{'color': self ? 'primary-200' : 'primary-A200'}"
           style='font-size: 2em'
           ng-class='["fas icon3-System-Notes",
                     {"pale-wisteria": countersign.state == "REQUIRED"}]'>
        </i>
      </div>
      <div ng-click='showCountersignDialog()' style='margin-left: .5em; line-height: 1em;'>
        <span ng-class='{"notes__rejected-text": countersign.state == "REJECTED"}'
              style='vertical-align: 38%'
              ng-bind='message.message.data'>
        </span>
        <span style='vertical-align: 38%' class='notes__review-link'
              ng-if='!hideCountersignLink && (countersign.state == "REQUIRED") && !trainee'>
          Review & Countersign
        </span>
        <span style='vertical-align: 38%' class='notes__review-link'
              ng-if='!hideCountersignLink && (countersign.state == "REQUIRED") && trainee'>
          To Be Countersigned
        </span>
      </div>
    </div>

    <div ng-if='countersign.state == "REJECTED"' style='color: #666'>
      <div>Rejected By: {{countersign._user_ }}</div>
      <div class='reason'>Reason:  {{ countersign.reason }}</div>
    </div>

    <div class='topmargin' layout ng-if='countersign.state == "REJECTED_COPY"'>
      <div>
        <i md-colors="{'color': 'primary-200'}"
           style='font-size: 2em' class='fas icon3-countersign-rejected'></i>
      </div>
      <div style='margin-left: .5em; line-height: 1em;'>
        <span style='vertical-align: 38%'>
          Rejected a note generated by {{ countersign.parent_author }}
        </span><br>
        <span style='vertical-align: 38%'>
          Reason: {{ countersign.reason }}
        </span><br>
        <span class='notes__rejected-text' style='vertical-align: 38%;'
              ng-bind='message.message.data'></span>
      </div>
    </div>

  `
        };
    })

    .directive('imageNote', function () {
        return {
            restrict: 'E',
            scope: {
                message: '=',
                serverUrl: '=',
                username: '=',
                trainee: '=',
                hideCountersignLink: '='
            },
            controller: function ($scope, Messages) {
                $scope.countersign = $scope.message.message.countersign;
                $scope.showCountersignDialog = function () {
                    if (!$scope.countersign || ($scope.countersign && $scope.countersign.state !== 'REQUIRED')) {
                        return;
                    }
                    Messages.showCountersignDialog($scope.message, $scope.username);
                };
            },
            template: `
    <img ng-if='countersign.state != "REJECTED" && countersign.state != "REJECTED_COPY"'
         http-src='{{serverUrl + message.message.data}}' />
    <div ng-if='countersign.state != "REJECTED_COPY"' ng-click='showCountersignDialog()' class='topmargin'>
      <div class='notes__rejected-image' ng-if='countersign.state == "REJECTED"'>
        <div>
          <i class='fas fa-times'></i>
        </div>
      </div>
      <span ng-class='{"italicize": true, "notes__rejected-text": countersign.state == "REJECTED"}'>
        {{message.message.caption}}
      </span>
      <span class='notes__review-link' ng-if='!hideCountersignLink && (countersign.state == "REQUIRED") && !trainee'>
        Review & Countersign
      </span>
      <span class='notes__review-link' ng-if='!hideCountersignLink && (countersign.state == "REQUIRED") && trainee'>
        To Be Countersigned
      </span>
    </div>

    <div ng-if='countersign.state == "REJECTED"' style='color: #666'>
      <div>Rejected By: {{countersign._user_ }}</div>
      <div class='reason'>Reason:  {{ countersign.reason }}</div>
    </div>

    <div class='topmargin' layout ng-if='countersign.state == "REJECTED_COPY"'>
      <div>
        <i md-colors="{'color': 'primary-200'}"
           style='font-size: 2em' class='fas icon3-countersign-rejected'></i>
      </div>
      <div style='margin-left: .5em; line-height: 1em;'>
        <span style='vertical-align: 38%'>
          Rejected an image uploaded by {{ countersign.parent_author }}
        </span><br>
        <span style='vertical-align: 38%'>
          Reason: {{ countersign.reason }}
        </span><br>
        <span class='notes__rejected-text' style='vertical-align: 38%;'
              ng-bind='message.message.caption'></span>
      </div>
    </div>

  `
        };
    })

    .directive('audioContent', function () {
        return {
            restrict: 'E',
            scope: {
                message: '=',
                self: '=',
                trainee: '=',
                username: '=',
                hideCountersignLink: '='
            },
            controller: function ($scope, $timeout, Utils, $rootScope, $mdDialog, Messages) {

                $scope.countersign = $scope.message.message.countersign;
                $scope.showCountersignDialog = function () {
                    if (!$scope.countersign || ($scope.countersign && $scope.countersign.state !== 'REQUIRED')) {
                        return;
                    }
                    Messages.showCountersignDialog($scope.message, $scope.username);
                };

                $scope.time = Utils.standardFormatting($scope.message.time);

                $scope.audioPlayback = function (ev) {
                    $mdDialog.show({
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: false,
                        locals: {message: $scope.message, time: $scope.time},
                        template: `
        <md-dialog style='min-width: 50%'>
          <md-dialog-content class='audio-dialog'>

            <div class='audio-player'>
              <div style='color: #6aafbf'>
                <span>Audio Recording</span>
                <i class='fas fa-times right' ng-click='closeDialog()'></i>
              </div>
              <div class='audio-container'>
                <audio-player source='message.message'
                              show-stop='true'
                              open='open'>
                </audio-player>
              </div>
            </div>

            <div style='padding: .5em'>
              <div><span ng-bind='message.message.caption'></span></div>
              <div style='color: #6aafbf'>
                <span ng-bind='message.username'></span>
                <span class='right italicize' ng-bind='time'>
                </span>
              </div>
            </div>

          </md-dialog-content>
        </md-dialog>
        `,
                        controller: function DialogController($scope, $mdDialog, message, time) {

                            $scope.message = message;
                            $scope.time = time;
                            $scope.open = {value: true};

                            $scope.closeDialog = function () {
                                $scope.open.value = false;
                                $mdDialog.hide();
                            };
                        }
                    });
                };

            },
            template: `

    <div ng-if='countersign.state != "REJECTED_COPY"'>
      <i md-colors="{'color': self ? 'primary-200' : 'primary-A200'}"
         ng-class='["fas fa-play-circle fa-2x",
         {"pale-wisteria": countersign.state == "REQUIRED"}]'
         ng-click='audioPlayback()'>
      </i>

    <div ng-click='showCountersignDialog()'>
      <span ng-class='{"notes__rejected-text": countersign.state == "REJECTED"}'
            ng-bind='message.message.caption'
            style='vertical-align: 30%'>
      </span>
      <span style='vertical-align: 30%'
            class='notes__review-link' ng-if='!hideCountersignLink && (countersign.state == "REQUIRED") && !trainee'>
        Review & Countersign
      </span>
      <span style='vertical-align: 30%'
            class='notes__review-link' ng-if='!hideCountersignLink && (countersign.state == "REQUIRED") && trainee'>
        To Be Countersigned
      </span>
    </div>
    </div>

    <div ng-if='countersign.state == "REJECTED"' style='color: #666'>
      <div>Rejected By: {{countersign._user_ }}</div>
      <div class='reason'>Reason:  {{ countersign.reason }}</div>
    </div>

    <div class='topmargin' layout ng-if='countersign.state == "REJECTED_COPY"'>
      <div>
        <i md-colors="{'color': 'primary-200'}"
           style='font-size: 2em' class='fas icon3-countersign-rejected'></i>
      </div>
      <div style='margin-left: .5em; line-height: 1em;'>
        <span style='vertical-align: 38%'>
          Rejected an audio uploaded by {{ countersign.parent_author }}
        </span><br>
        <span style='vertical-align: 38%'>
          Reason: {{ countersign.reason }}
        </span><br>
        <span class='notes__rejected-text' style='vertical-align: 38%;'
              ng-bind='message.message.caption'></span>
      </div>
    </div>


  `
        };
    })

    .directive('documentNote', function () {
        return {
            restrict: 'E',
            scope: {
                self: '=',
                message: '=',
                trainee: '=',
                serverUrl: '=',
                username: '=',
                hideCountersignLink: '='
            },
            controller: function ($scope, Messages, $http) {

                $scope.downloadDocument = function ($event) {
                    $event.preventDefault();
                    $http({
                        url: $scope.serverUrl + $scope.message.message.data,
                        method: 'GET',
                        responseType: 'blob'
                    })
                        .then(function (response) {
                            const fileURL = URL.createObjectURL(response.data);
                            window.open(fileURL);
                        });
                };

                $scope.countersign = $scope.message.message.countersign;
                $scope.showCountersignDialog = function () {
                    if (!$scope.countersign || ($scope.countersign && $scope.countersign.state !== 'REQUIRED')) {
                        return;
                    }
                    Messages.showCountersignDialog($scope.message, $scope.username);
                };
            },
            template: `
    <div layout='column' ng-if='countersign.state != "REJECTED_COPY"'>
      <div>
        <i md-colors="{'color': self ? 'primary-200' : 'primary-A200'}"
           ng-class='["fas fa-paperclip fa-2x fa-flip-vertical",
           {"pale-wisteria": countersign.state == "REQUIRED" }]'>
        </i>
        <a ng-class='{ "medium-wisteria": countersign.state == "REQUIRED",
                      "notes__rejected-text": countersign.state == "REJECTED"}'
           target='_blank'
           style='vertical-align: 38%'
           ng-bind='message.message.name'
           ng-click='downloadDocument($event)'>
        </a>
      </div>
      <div ng-click='showCountersignDialog()'>
        <span ng-bind='message.message.caption' ng-class='{"notes__rejected-text": countersign.state == "REJECTED"}'></span>
        <span class='notes__review-link' ng-if='!hideCountersignLink && (countersign.state == "REQUIRED") && !trainee'>
          Review & Countersign
        </span>
        <span class='notes__review-link' ng-if='!hideCountersignLink && (countersign.state == "REQUIRED") && trainee'>
          To Be Countersigned
        </span>
      </div>
    </div>


    <div ng-if='countersign.state == "REJECTED"' style='color: #666'>
      <div>Rejected By: {{countersign._user_ }}</div>
      <div class='reason'>Reason:  {{ countersign.reason }}</div>
    </div>

    <div class='topmargin' layout ng-if='countersign.state == "REJECTED_COPY"'>
      <div>
        <i md-colors="{'color': 'primary-200'}"
           style='font-size: 2em' class='fas icon3-countersign-rejected'></i>
      </div>
      <div style='margin-left: .5em; line-height: 1em;'>
        <span style='vertical-align: 38%'>
          Rejected an attachment uploaded by {{ countersign.parent_author }}
        </span><br>
        <span style='vertical-align: 38%'>
          Reason: {{ countersign.reason }}
        </span><br>
        <span class='notes__rejected-text' style='vertical-align: 38%;'
              ng-bind='message.message.caption'></span>
      </div>
    </div>

  `
        };
    })

    .directive('note', function () {
        return {
            restrict: 'E',
            scope: {
                data: '=',
                username: '=',
                channel: '=?',
                taskDisabled: '=',
                hideCountersignLink: '='
            },
            controller: function ($scope, $rootScope) {

                $scope.serverUrl = $rootScope.serverUrl;
                $scope.self = $scope.data.username === $scope.username;
                $scope.trainee = $rootScope.trainee;

            },
            template: `
      <div ng-if='data.message.type == "text"'>
        <text-note message='data' self='self' trainee='trainee'
                   username='username' hide-countersign-link='hideCountersignLink'>
        </text-note>
      </div>

      <div ng-if='data.message.type == "generated"'>
        <generated-note message='data' self='self' trainee='trainee'
                        username='username' hide-countersign-link='hideCountersignLink'>
        </generated-note>
      </div>

      <div ng-if='data.message.type == "image"'>
        <image-note message='data' server-url='serverUrl' trainee='trainee'
                    username='username' hide-countersign-link='hideCountersignLink'>
        </image-note>
      </div>

      <div ng-if='data.message.type == "audio"'>
        <audio-content message='data' self='self' trainee='trainee'
                       username='username' hide-countersign-link='hideCountersignLink'>
        </audio-content>
      </div>

      <div ng-if='data.message.type == "document"'>
        <document-note self='self' message='data' trainee='trainee'
                       server-url='serverUrl'
                       username='username' hide-countersign-link='hideCountersignLink'>
        </document-note>
      </div>

      <div ng-if='data.message.type == "task"' class='topmargin'>
        <task-note self='self' message='data' trainee='trainee'
                   username='username' channel='channel' task-disabled="taskDisabled"
                   hide-countersign-link='hideCountersignLink'>
        </task-note>
      </div>

      <div ng-if='data.message.type == "task_completed"'>
        <task-completed-note message='data' trainee='trainee'
                             username='username' hide-countersign-link='hideCountersignLink'>
        </task-completed-note>
      </div>
      
      <div ng-if='data.message.type == "task_assigned"'>
        <task-assigned-note message='data' trainee='trainee'
                             username='username' hide-countersign-link='hideCountersignLink'>
        </task-assigned-note>
      </div>
      <div ng-if='data.message.type == "task_commented"'>
        <task-commented-note message='data' trainee='trainee'
                             username='username' hide-countersign-link='hideCountersignLink'>
        </task-commented-note>
      </div>

      <note-info message='data'
                 username='username'
                 self='self'>
      </note-info>

    `
        };
    })

    .directive('noteInfo', function (Utils) {
        return {
            scope: {
                message: '=',
                username: '=',
                self: '='
            },
            controller: function ($scope, $rootScope) {

                $scope.trainee = $rootScope.trainee;
                $scope.formattedTime = Utils.noteTimeFormatting($scope.message.time);
                $scope.countersign = $scope.message.message.countersign;

                $scope.authorName = $scope.message.message.hasOwnProperty('authorName') ?
                    $scope.message.message.authorName : $scope.message.username;

                $scope.showOrigin=($rootScope.origin != $scope.message.origin)
                $scope.origin = $scope.message.origin;
            },
            template: `
    <div class='notes__info'>
      <i ng-if='countersign.state == "REQUIRED"' class='icon2-unapproved pale-wisteria'></i>
      <i ng-if='countersign.state == "REJECTED"' class='icon3-countersign-rejected aluminium-grey'></i>      
      <span ng-class='{"notes__info__author--self": self,
                       "aluminium-grey": countersign.state == "REJECTED" ,
                       "medium-wisteria": countersign.state == "REQUIRED"}'
            ng-bind='authorName'></span>             
      <span ng-class='["notes__info__time",
                      {"bold": self}]'
            ng-bind='formattedTime.timestamp'>
          <md-tooltip ng-if='formattedTime.showTooltip'
                      style='height:auto'
                      md-direction='top'>
            <span ng-bind='formattedTime.date'></span>
            <span class='center' ng-bind='", " + formattedTime.time'></span>
          </md-tooltip>
      </span>
      <div ng-if='showOrigin' class="message-origin">
        <span class="chip-wrapper"><i class="fa-solid fa-arrow-right-to-bracket fa-lg"></i> <span ng-bind='origin'> <span>:</span>        
      </div>
    </div>
    `
        };
    });
