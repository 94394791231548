/**
 * @name concerns
 * @ngdoc module
 * @description
 * Components dealing with Problem Identified
 */

angular.module('concerns', [])

    .service('ConcernStore', function (Server) {
        this.load = function () {
            return Server.createResource('/patient/:id/concerns/:key', {});
        };
    });
