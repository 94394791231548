/**
 * @name readmit
 * @ngdoc module
 * @description
 * Readmissions Module
 */


angular.module('readmit', [])

    .directive('readmitPatientPreview', function () {
        return {
            restrict: 'E',
            scope: {
                patient: '='
            },
            template: `
<div class='patient-info' layout='column'>
  <div>
    <div class='jet-black' style='font-size: 2em;'>
      <span ng-bind="patient.data.admissions.patient_details.first_name || 'New'">
      </span>
      <span ng-bind="patient.data.admissions.patient_details.last_name || 'Patient'">
      </span>
    </div>
    <div style='margin-top: -.25em' layout='row'>
      <patient-age flex='5' flex-xs='10'
          dob="patient.data.admissions.patient_details.date_of_birth">
      </patient-age>
      <span class='center' flex='5' flex-xs='10' ng-bind='patient.data.admissions.patient_details.gender.value
                  || "-"'>
      </span>
       <span class='center' flex='15' flex-xs='25' ng-bind=
            'patient.data.admissions.patient_details.hospital_number
                      || "-"'>
      </span>
      <span flex ng-bind=
            'patient.data.admissions.patient_details.preferred_name || "-"'}'>
      </span>
    </div>
  </div>
  <div style='margin-top: 1em'>
    <div style='margin-bottom:-.75em'>
      <span flex='40' class='jet-black'>Last Admission Date </span>
      <span flex='60' flex ng-bind='patient.data.admissions.reason_for_admission.date_of_admission'></span>
    </div>
    <div>
      <span flex='40' class='jet-black'>Last Discharge Date </span>
      <span flex='60'
  ng-bind='patient.data.discharge.discharge_list.cards \
     [patient.data.discharge.discharge_list.cards.length - 1].date_of_discharge'>
      </span>
    </div>
  </div>
  <div style='margin-top: 1em; line-height: 1.25em'>
    <div class='jet-black'>Previous reason for Admission</div>
    <div ng-bind='patient.data.admissions.reason_for_admission.presenting_complaint'>
    </div>
  </div>
</div>
    `
        };
    })


    .service('Readmissions', function ($mdDialog, PatientStore, $rootScope) {

        this.readmit = function (patientId) {

            PatientStore.load().then(Patient => {
                Patient.get({id: patientId}, function (patient) {
                    if (!$rootScope.canApproveForms) {
                        $mdDialog.show({
                            parent: angular.element(document.body),
                            clickOutsideToClose: true,
                            template: `
<md-dialog aria-label='Trainee Readmit Dialog'>
  <md-dialog-content>
    <div style='margin:.75em' layout-margin class='readmit' layout='column' >
      <div layout='row'>
        <div flex='95' class='heading trainee-light'>
          Patient Readmission
        </div>
        <div layout-align='end' flex='5'>
          <i class='fas fa-times close-dialog' ng-click='closeDialog()'>
          </i>
        </div>
      </div>

      <div>
        <p style='margin-bottom: .75em'>This patient has an existing record on Chai.</p>
        <p>A standard Chai user account is required to
            initially review patient readmissions before they can be
            accessed.
        </p>
      </div>

    </div>
  </md-dialog-content>
</md-dialog>
            `,
                            controller: function DialogController($scope, $mdDialog, $rootScope) {
                                $scope.patientLabel = $rootScope.patientLabel;
                                $scope.closeDialog = function () {
                                    $mdDialog.hide();
                                };
                            }
                        });
                    } else {

                        $mdDialog.show({
                            parent: angular.element(document.body),
                            clickOutsideToClose: true,
                            locals: {patient: patient},
                            template: `
<md-dialog aria-label='Readmit Dialog'>
  <md-dialog-content>
    <div style='margin: 1.5em' class='readmit' layout-margin layout='column' >
      <div layout='row'>
        <div flex='95' class='heading'>
          {{ patientLabel }} Readmission
        </div>
        <div layout-align='end' flex='5'>
          <i class='fas fa-times close-dialog' ng-click='closeDialog()'>
          </i>
        </div>
      </div>
      <div>
        This {{ patientLabel }} has an existing record on CHAI:
      </div>
      <readmit-patient-preview patient='patient'></readmit-patient-preview>      
      <div layout='row' layout-xs='column'>
        <div flex='50' ng-click='repopulateWithBlankForms()'>
          <md-card>
            <md-card-title>
              <div style='height:2.5em' layout='row' layout-align='start center'>
                <div flex='35'>
                  <i style='font-size: 2.5em' class="fas fa-circle teal-velvet"></i>
                  <i class="far fa-file adjust"></i>
                </div>
                <div flex class='jet-black'>
                  Blank Forms
                </div>
              </div>
            </md-card-title>

            <md-card-content>
              <div>
                <p class='content'>Start with fresh forms. PAS data is auto-populated and allergies
                are carried forward.</p>
              </div>
              <div style='margin-top: 1.25em' class='align-right jet-black'>
                Create blank forms
              </div>
            </md-card-content>

          </md-card>
        </div>
        <div ng-click='showReadmitForms()' flex='50'>
          <md-card>
            <md-card-title>
              <div style='height:2.5em' layout='row' layout-align='start center'>
                <div flex='35'>
                  <i style='font-size: 2.5em; color: #f57900' class="fas fa-circle"></i>
                  <i class="far fa-file-alt adjust"></i>
                </div>
                <div flex class='jet-black'>
                  Repopulate
                </div>
              </div>
            </md-card-title>
            <md-card-content>
              <div>                
                <p class='content'>Select which forms will be carried forward
                to the current admission</p>
              </div>
              <div style='margin-top: 2.4em' class='align-right jet-black'>
                Choose forms
              </div>
            </md-card-content>
          </md-card>
        </div>
      </div>
    </div>
  </md-dialog-content>
</md-dialog>
            `,
                            controller: function DialogController($scope, $mdDialog, $state, patient) {
                                $scope.patient = patient;

                                $scope.closeDialog = function () {
                                    $mdDialog.hide();
                                };

                                $scope.repopulateWithBlankForms = function () {
                                    PatientStore.load().then(Patient => {
                                        Patient.readmit({id: $scope.patient.id}, [], function () {
                                            $mdDialog.hide();
                                            $state.go('patient.dash', {id: $scope.patient.id});
                                        });
                                    });
                                };

                                $scope.showReadmitForms = function () {
                                    $rootScope.repopulatePatient = $scope.patient;
                                    $scope.closeDialog();
                                    $state.go('repopulate');
                                };
                            }
                        });
                    }

                }, err => {
                });
            });


        };
    });
