/**
 * @name alerts
 * @ngdoc module
 * @description
 * Patient alerts for task/assessment warn and overdue.
 */


angular.module('alerts', [])

    .service('PatientAlerts', function (PatientStore) {

        this.alerts = {
            taskWarn: false,
            taskOverdue: false,
            assessWarn: false,
            assessOverdue: false,
            obsWarn: false,
            obsOverdue: false,
            careplansWarn: false,
            careplansOverdue: false
        };

        this.getAlerts = function () {
            return this.alerts;
        };

        this.isWarn = function (item) {
            return moment().diff(item.warn) > 0 &&
                moment().diff(item.due) <= 0;
        };


        this.isOverdue = function (item) {
            return moment().diff(item.due) > 0;
        };

        this.setRepFormState = function (item, state) {
            if (state !== undefined) {

                if (this.isWarn(item)) {
                    state.state = 'warn';
                    state.date = item.due;
                } else if (this.isOverdue(item)) {
                    state.state = 'overdue';
                    state.date = item.due;
                } else if (item.hasOwnProperty('due')) {
                    state.state = 'due';
                    state.date = item.due;
                } else if (item.hasOwnProperty('last_entry')) {
                    state.state = 'completed';
                    state.date = item.last_entry;
                }
            }
        };


        this.update = function (newAlerts, oldAlerts) {
            if (newAlerts && newAlerts.assessment) {
                oldAlerts.assessWarn = this.isWarn(newAlerts.assessment.overall);
                oldAlerts.assessOverdue = this.isOverdue(newAlerts.assessment.overall);

                if (newAlerts.assessment.clinical_obs) {
                    oldAlerts.obsWarn = this.isWarn(newAlerts.assessment.clinical_obs);
                    oldAlerts.obsOverdue = this.isOverdue(newAlerts.assessment.clinical_obs);
                } else {
                    oldAlerts.obsWarn = false;
                    oldAlerts.obsOverdue = false;
                }
            } else {
                oldAlerts.assessWarn = false;
                oldAlerts.assessOverdue = false;
                oldAlerts.obsWarn = false;
                oldAlerts.obsOverdue = false;
            }


            if (newAlerts && newAlerts.careplans) {
                oldAlerts.careplansWarn = this.isWarn(newAlerts.careplans.overall);
                oldAlerts.careplansOverdue = this.isOverdue(newAlerts.careplans.overall);
            } else {
                oldAlerts.careplansWarn = false;
                oldAlerts.careplansOverdue = false;
            }


            if (newAlerts && newAlerts.task) {
                oldAlerts.taskWarn = this.isWarn(newAlerts.task.overall);
                oldAlerts.taskOverdue = this.isOverdue(newAlerts.task.overall);
            } else {
                oldAlerts.taskWarn = false;
                oldAlerts.taskOverdue = false;
            }
        };

        this.refresh = function (alerts) {
            this.update(alerts, this.alerts);
        };

        this.refreshFromSummary = function (patientId) {
            var me = this;
            PatientStore.load().then(Patient => {
                Patient.summary({id: patientId}, function (patient) {
                    me.refresh(patient.alerts);
                }, err => {
                });
            });
        };

    });
